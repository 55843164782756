import { useState } from 'react';
import { format } from 'date-fns';
import { AlertCircle, X, RotateCcw, PenTool, ExternalLink, ChevronDown } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible';
import { Input } from '@/components/ui/input';
import type { TradeSearchResult } from '@/hooks/use-trade';
import type { OFACCheck, VerificationItem } from '../types';

interface OFACCheckProps {
  item: OFACCheck;
  onVerify: (id: string) => void;
  onSign: (id: string, role: 'creator' | 'reviewer') => void;
  isSearching: boolean;
  items: VerificationItem[];
  setItems: (items: VerificationItem[]) => void;
  userId: string | undefined;
}

export function OFACCheckComponent({
  item,
  onVerify,
  isSearching,
  items,
  setItems,
  onSign,
  userId,
}: OFACCheckProps) {
  const [openSections, setOpenSections] = useState<{ [key: number]: boolean }>(() => ({ 0: true }));

  const toggleSection = (index: number) => {
    setOpenSections((prev: { [key: number]: boolean }) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        <div className="flex-1">
          <Input
            value={item.entityName}
            onChange={(e) =>
              setItems(
                items.map((i) =>
                  i.id === item.id
                    ? { ...i, entityName: e.target.value }
                    : i,
                ),
              )}
            placeholder="Enter entity name"
          />
        </div>
        <Button
          variant={item.verified ? 'outline' : 'default'}
          className="gap-2"
          onClick={() => onVerify(item.id)}
          disabled={!item.entityName || isSearching}
        >
          {item.verified
            ? (
              <>
                <RotateCcw className="size-4" />
                Re-verify
              </>
            )
            : (
              <>
                <AlertCircle className="size-4" />
                Verify
              </>
            )}
        </Button>
      </div>
      {item.searchResults && (
        <div className="space-y-4">
          {typeof item.searchResults.results === 'string'
            ? (
              <div className="rounded-lg border bg-muted p-4 text-sm">
                {item.searchResults.results}
              </div>
            )
            : (
              <>
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <div>
                    Found
                    {' '}
                    {item.searchResults.results.total}
                    {' '}
                    results
                  </div>
                </div>
                {item.searchResults.results.results.map((result: TradeSearchResult, index: number) => (
                  <Collapsible key={index} open={openSections[index]} onOpenChange={() => toggleSection(index)}>
                    <div className="rounded-lg border-2 border-red-500 bg-red-50 p-4">
                      <div className="flex items-center justify-between">
                        <div className="font-medium">{result.name}</div>
                        <CollapsibleTrigger asChild>
                          <Button variant="ghost" size="sm" className="gap-2">
                            <span>{openSections[index] ? 'Hide Details' : 'Show Details'}</span>
                            <ChevronDown className={`size-4 transition-transform ${openSections[index] ? 'rotate-180' : ''}`} />
                          </Button>
                        </CollapsibleTrigger>
                      </div>
                      <CollapsibleContent className="mt-4 space-y-3">
                        {/* Entity Info */}
                        <div className="space-y-1">
                          <div className="text-sm font-medium">Also known as:</div>
                          <div className="text-sm text-muted-foreground">
                            {result.alt_names?.length > 0 ? result.alt_names.join(', ') : 'None'}
                          </div>
                          <div className="text-sm font-medium">Type:</div>
                          <div className="text-sm text-muted-foreground">
                            {result.type || 'None'}
                          </div>
                        </div>

                        {/* Programs & Details */}
                        <div className="space-y-1">
                          <div className="text-sm font-medium">Programs:</div>
                          {result.programs?.length > 0
                            ? (
                              <div className="flex flex-wrap gap-2">
                                {result.programs.map((program: string, idx: number) => (
                                  <div
                                    key={idx}
                                    className="rounded-md bg-muted px-2 py-1 text-xs"
                                  >
                                    {program}
                                  </div>
                                ))}
                              </div>
                            )
                            : <div className="text-sm text-muted-foreground">None</div>}
                        </div>

                        {/* Addresses */}
                        <div className="space-y-1">
                          <div className="text-sm font-medium">Addresses:</div>
                          {result.addresses?.length > 0
                            ? (
                              <div className="space-y-2">
                                {result.addresses.map((address: { address: string; city: string; state: string; postal_code: string; country: string }, idx: number) => (
                                  <div key={idx} className="text-sm text-muted-foreground">
                                    {address.address}
                                    {[
                                      address.city,
                                      address.state,
                                      address.postal_code,
                                      address.country,
                                    ]
                                      .filter(Boolean)
                                      .join(', ')}
                                  </div>
                                ))}
                              </div>
                            )
                            : <div className="text-sm text-muted-foreground">None</div>}
                        </div>

                        {/* Additional Details */}
                        <div className="space-y-2 text-sm">
                          <div>
                            <div className="font-medium">Nationalities:</div>
                            <div className="text-muted-foreground">
                              {result.nationalities?.length > 0 ? result.nationalities.join(', ') : 'None'}
                            </div>
                          </div>
                          <div>
                            <div className="font-medium">Citizenships:</div>
                            <div className="text-muted-foreground">
                              {result.citizenships?.length > 0 ? result.citizenships.join(', ') : 'None'}
                            </div>
                          </div>
                          <div>
                            <div className="font-medium">Dates of Birth:</div>
                            <div className="text-muted-foreground">
                              {result.dates_of_birth?.length > 0 ? result.dates_of_birth.join(', ') : 'None'}
                            </div>
                          </div>
                          <div>
                            <div className="font-medium">Places of Birth:</div>
                            <div className="text-muted-foreground">
                              {result.places_of_birth?.length > 0 ? result.places_of_birth.join(', ') : 'None'}
                            </div>
                          </div>
                        </div>

                        {/* Remarks */}
                        <div className="space-y-1">
                          <div className="text-sm font-medium">Remarks:</div>
                          <div className="text-sm text-muted-foreground">
                            {result.remarks || 'None'}
                          </div>
                        </div>

                        {/* Source Information */}
                        <div className="space-y-1 border-t pt-2">
                          <div className="space-y-2 text-sm">
                            <div>
                              <div className="font-medium">Source:</div>
                              <div className="text-muted-foreground">
                                {result.source || 'None'}
                              </div>
                            </div>
                            <div>
                              <div className="font-medium">Start Date:</div>
                              <div className="text-muted-foreground">
                                {result.start_date || 'None'}
                              </div>
                            </div>
                            <div>
                              <div className="font-medium">End Date:</div>
                              <div className="text-muted-foreground">
                                {result.end_date || 'None'}
                              </div>
                            </div>
                            <div>
                              <div className="font-medium">Source Information URL:</div>
                              <div className="break-all text-muted-foreground">
                                {result.source_information_url
                                  ? (
                                    <a
                                      href={result.source_information_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-blue-600 hover:underline"
                                    >
                                      {result.source_information_url}
                                      <ExternalLink className="ml-1 inline-block size-3" />
                                    </a>
                                  )
                                  : 'None'}
                              </div>
                            </div>
                            <div>
                              <div className="font-medium">Source List URL:</div>
                              <div className="break-all text-muted-foreground">
                                {result.source_list_url
                                  ? (
                                    <a
                                      href={result.source_list_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-blue-600 hover:underline"
                                    >
                                      {result.source_list_url}
                                      <ExternalLink className="ml-1 inline-block size-3" />
                                    </a>
                                  )
                                  : 'None'}
                              </div>
                            </div>
                          </div>
                        </div>
                      </CollapsibleContent>
                    </div>
                  </Collapsible>
                ))}
              </>
            )}
        </div>
      )}
      {item.verified && (
        <div className="flex flex-wrap items-center gap-3">
          <Button
            variant="outline"
            className="flex-1 gap-2"
            onClick={() => onSign(item.id, 'creator')}
          >
            {item.signatures.some(
              (s) => s.userId === userId && s.role === 'creator',
            )
              ? (
                <>
                  <X className="size-4" />
                  Remove Creator Signature
                </>
              )
              : (
                <>
                  <PenTool className="size-4" />
                  Sign as Creator
                </>
              )}
          </Button>
          <Button
            variant="outline"
            className="flex-1 gap-2"
            onClick={() => onSign(item.id, 'reviewer')}
          >
            {item.signatures.some(
              (s) => s.userId === userId && s.role === 'reviewer',
            )
              ? (
                <>
                  <X className="size-4" />
                  Remove Reviewer Signature
                </>
              )
              : (
                <>
                  <PenTool className="size-4" />
                  Sign as Reviewer
                </>
              )}
          </Button>
        </div>
      )}
      <div>
        {item.verifiedBy && (
          <div className="text-sm text-muted-foreground">
            Verified by
            {' '}
            {item.verifiedBy.name}
            {' '}
            on
            {' '}
            {format(new Date(item.verifiedBy.timestamp), 'MMM d, yyyy h:mm a')}
          </div>
        )}
        {item.signatures.length > 0 && (
          <div className="space-y-2">
            <div className="text-sm font-medium">Signatures:</div>
            {item.signatures.map((signature, index) => (
              <div
                key={index}
                className="text-sm text-muted-foreground"
              >
                {signature.name}
                {' '}
                (
                {signature.role}
                ) -
                {' '}
                {format(new Date(signature.timestamp), 'MMM d, yyyy h:mm a')}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
