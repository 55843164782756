import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { GridApi, RowDoubleClickedEvent } from '@ag-grid-community/core';
import { RotateCcw, Building2, Shield } from 'lucide-react';
import { toast } from 'sonner';
import AGDataGrid from '@/components/ag-data-grid';
import Container from '@/components/container';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { useGrid } from '@/contexts/grid-context';
import { useDomainStats } from '@/hooks/use-dashboard-stats';
import { useDomains } from '@/hooks/use-domains';
import type { Domain } from '@/types/domain';
import { domainColumnDefs } from './components/domain-grid-columns';

function DomainStatCard({
  title,
  icon: Icon,
  active,
  inactive,
  pendingCreate,
  total,
  className,
}: {
  title: string;
  icon: React.ElementType;
  active: number;
  inactive: number;
  pendingCreate: number;
  total: number;
  className?: string;
}) {
  return (
    <Card className={className}>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{title}</CardTitle>
        <Icon className="size-4 text-muted-foreground" />
      </CardHeader>
      <CardContent>
        <div className="space-y-3">
          <div>
            <div className="text-2xl font-bold">{total}</div>
            <p className="text-xs text-muted-foreground">Total domains</p>
          </div>
          <div className="grid grid-cols-3 gap-2">
            <div>
              <div className="text-sm font-medium text-green-600">{active}</div>
              <p className="text-xs text-muted-foreground">Active</p>
            </div>
            <div>
              <div className="text-sm font-medium text-amber-600">{inactive}</div>
              <p className="text-xs text-muted-foreground">Inactive</p>
            </div>
            <div>
              <div className="text-sm font-medium text-blue-600">{pendingCreate}</div>
              <p className="text-xs text-muted-foreground">Pending</p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function DomainsPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const gridApiRef = useRef<GridApi<Domain> | null>(null);
  const { registerGridApi } = useGrid();
  const navigate = useNavigate();

  const { data: domains, isLoading: domainsLoading, error: domainsError } = useDomains();
  const { data: domainStats, isLoading: statsLoading, error: statsError } = useDomainStats();

  // Show error toasts if the queries fail
  if (domainsError instanceof Error) {
    toast.error(domainsError.message);
  }
  if (statsError instanceof Error) {
    toast.error(statsError.message);
  }

  const handleRowDoubleClick = (event: RowDoubleClickedEvent<Domain>) => {
    if (event.data) {
      navigate(`/app/domains/${event.data.name}`);
    }
  };

  const handleGridReady = (params: { api: GridApi<Domain> }) => {
    gridApiRef.current = params.api;
    registerGridApi(params.api);
  };

  const clearFilters = () => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel(null);
      setSearchQuery('');
    }
  };

  return (
    <Container className="pb-12">
      <h1 className="mb-6 text-3xl font-bold">Domains</h1>
      <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
        <DomainStatCard
          title=".Bank"
          icon={Building2}
          active={domainStats?.bank.active ?? 0}
          inactive={domainStats?.bank.inactive ?? 0}
          pendingCreate={domainStats?.bank.pendingCreate ?? 0}
          total={(domainStats?.bank.active ?? 0) + (domainStats?.bank.inactive ?? 0)}
        />
        <DomainStatCard
          title=".Insurance"
          icon={Shield}
          active={domainStats?.insurance.active ?? 0}
          inactive={domainStats?.insurance.inactive ?? 0}
          pendingCreate={domainStats?.insurance.pendingCreate ?? 0}
          total={(domainStats?.insurance.active ?? 0) + (domainStats?.insurance.inactive ?? 0)}
        />
      </div>
      <div className="mb-6 flex gap-4">
        <div className="flex-1">
          <Input
            type="search"
            placeholder="Search domains..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <Button
          variant="outline"
          onClick={clearFilters}
          className="gap-2"
        >
          <RotateCcw className="size-4" />
          Clear Filters
        </Button>
      </div>
      <div className={domainsLoading ? 'opacity-50' : ''}>
        <AGDataGrid
          columnDefs={domainColumnDefs}
          rowData={domains || []}
          quickFilterText={searchQuery}
          onRowDoubleClicked={handleRowDoubleClick}
          onGridReady={handleGridReady}
          overlayLoadingTemplate={
            domainsLoading ? '<span class="ag-overlay-loading-center">Loading...</span>' : undefined
          }
          overlayNoRowsTemplate={
            domainsLoading ? '' : '<span class="ag-overlay-no-rows-center">No domains found</span>'
          }
        />
      </div>
    </Container>
  );
}

export default DomainsPage;
