import * as React from 'react';
import { Separator } from '@/components/ui/separator';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useHub } from '@/contexts/hub-context';
import type { CommunicationDisplay, MessageContent } from '../types/communications';
import { MessageInput } from '../components/message-input';
import { MessageList } from '../components/message-list';
import { ThreadPagination } from '../components/thread-pagination';

export type CenterPaneProps = {
  communications: CommunicationDisplay[];
  onMessageSelect: (messageId: string) => void;
  onSend: (content: MessageContent) => void;
  onUploadFiles: (files: File[]) => void;
  selectedMessageId?: string;
  isLoading?: boolean;
  isRefreshing?: boolean;
  onNextPage?: () => void;
  onPrevPage?: () => void;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
  isFetchingNextPage?: boolean;
};

export function CenterPane({
  communications,
  onMessageSelect,
  onSend,
  onUploadFiles,
  selectedMessageId,
  isLoading,
  isRefreshing,
  onNextPage,
  onPrevPage,
  hasNextPage,
  hasPrevPage,
  isFetchingNextPage,
}: CenterPaneProps) {
  const { isDealInbox } = useHub();
  const messageInputRef = React.useRef<HTMLDivElement>(null);
  const [messageInputHeight, setMessageInputHeight] = React.useState(0);

  // Observer to track message input height changes
  React.useEffect(() => {
    if (!messageInputRef.current)
      return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setMessageInputHeight(entry.contentRect.height);
      }
    });

    resizeObserver.observe(messageInputRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div className="flex h-full flex-col">
      <Tabs defaultValue="all" className="w-full">
        <div className="flex items-center justify-between px-4 py-2">
          <h1 className="text-xl font-bold">Messages</h1>
          <TabsList>
            <TabsTrigger value="all">All</TabsTrigger>
            <TabsTrigger value="internal">Internal</TabsTrigger>
            <TabsTrigger value="external">External</TabsTrigger>
          </TabsList>
        </div>
        <Separator />
        <TabsContent value="all" className="m-0">
          <MessageList
            communications={communications}
            isLoading={isLoading || isFetchingNextPage}
            isRefreshing={isRefreshing}
            onMessageSelect={onMessageSelect}
            selectedMessageId={selectedMessageId}
            messageInputHeight={messageInputHeight}
          />
        </TabsContent>
        <TabsContent value="internal" className="m-0">
          <MessageList
            communications={communications.filter((c) => !c.isExternal)}
            isLoading={isLoading || isFetchingNextPage}
            isRefreshing={isRefreshing}
            onMessageSelect={onMessageSelect}
            selectedMessageId={selectedMessageId}
            messageInputHeight={messageInputHeight}
          />
        </TabsContent>
        <TabsContent value="external" className="m-0">
          <MessageList
            communications={communications.filter((c) => c.isExternal)}
            isLoading={isLoading || isFetchingNextPage}
            isRefreshing={isRefreshing}
            onMessageSelect={onMessageSelect}
            selectedMessageId={selectedMessageId}
            messageInputHeight={messageInputHeight}
          />
        </TabsContent>
      </Tabs>
      {isDealInbox
        ? (
          <div className="mt-auto flex-none border-t px-4 pt-4" ref={messageInputRef}>
            <MessageInput onSend={onSend} onUploadFiles={onUploadFiles} />
          </div>
        )
        : (
          <div className="mt-auto flex-none border-t px-4 pb-2 pt-4" ref={messageInputRef}>
            <ThreadPagination
              onNextPage={onNextPage}
              onPrevPage={onPrevPage}
              hasNextPage={!!hasNextPage}
              hasPrevPage={!!hasPrevPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          </div>
        )}
    </div>
  );
}
