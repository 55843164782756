import { useRef, useState } from 'react';
import type { GridApi, RowDoubleClickedEvent } from '@ag-grid-community/core';
import { RotateCcw } from 'lucide-react';
import AGDataGrid from '@/components/ag-data-grid';
import Container from '@/components/container';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useGrid } from '@/contexts/grid-context';
import { useRawTransactions } from '@/hooks/use-raw-transactions';
import type { RawTransaction } from '@/hooks/use-raw-transactions';
import { transactionColumnDefs } from './components/transaction-grid-columns';
import TransactionSheet from './components/transaction-sheet';

function RawTransactionsPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTransaction, setSelectedTransaction] = useState<RawTransaction | null>(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const gridApiRef = useRef<GridApi<RawTransaction> | null>(null);
  const { registerGridApi } = useGrid();

  const { data: transactions, isLoading } = useRawTransactions();

  const handleRowDoubleClick = (event: RowDoubleClickedEvent<RawTransaction>) => {
    if (event.data) {
      setSelectedTransaction(event.data);
      setIsSheetOpen(true);
    }
  };

  const handleSheetOpenChange = (open: boolean) => {
    setIsSheetOpen(open);
    if (!open) {
      setSelectedTransaction(null);
    }
  };

  const handleGridReady = (params: { api: GridApi<RawTransaction> }) => {
    gridApiRef.current = params.api;
    registerGridApi(params.api);
  };

  const clearFilters = () => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel(null);
      setSearchQuery('');
    }
  };

  return (
    <Container maxWidth={false} className="pb-12">
      <div className="mb-3 flex justify-between">
        <div>
          <h1 className="mt-2 text-xl font-bold">Raw Registry Transactions</h1>
        </div>
      </div>
      <div className="mb-6 flex gap-4">
        <div className="flex-1">
          <Input
            type="search"
            placeholder="Search transactions..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <Button
          variant="outline"
          onClick={clearFilters}
          className="gap-2"
        >
          <RotateCcw className="size-4" />
          Clear Filters
        </Button>
      </div>
      <div className={isLoading ? 'opacity-50' : ''}>
        <AGDataGrid
          columnDefs={transactionColumnDefs}
          rowData={transactions || []}
          quickFilterText={searchQuery}
          rowSelection={{ mode: 'singleRow' }}
          onRowDoubleClicked={handleRowDoubleClick}
          onGridReady={handleGridReady}
          overlayLoadingTemplate={
            isLoading ? '<span class="ag-overlay-loading-center">Loading...</span>' : undefined
          }
          overlayNoRowsTemplate={
            isLoading ? '' : '<span class="ag-overlay-no-rows-center">No transactions found</span>'
          }
        />
      </div>

      <TransactionSheet
        open={isSheetOpen}
        onOpenChange={handleSheetOpenChange}
        transaction={selectedTransaction || undefined}
      />
    </Container>
  );
}

export default RawTransactionsPage;
