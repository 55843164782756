import React, { useId } from 'react';
import { useFieldArray, useFormState, type Control, type FieldPath } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { cn } from '@/lib/utils';
import type { ContactFormData } from '@/types/contact-schema';

/**
 * Props for the DomainAssociations component.
 */
type DomainAssociationsProps = {
  /**
   * The react-hook-form control object.
   */
  control: Control<ContactFormData>;
  /**
   * The name of the field in the form.
   */
  name: 'domain_associations';
};

/**
 * Props for the DomainAssociationField component.
 */
type DomainAssociationFieldProps = {
  /**
   * The react-hook-form control object.
   */
  control: Control<ContactFormData>;
  /**
   * The name of the field in the form.
   */
  name: 'domain_associations';
  /**
   * The index of this field in the array.
   */
  index: number;
  /**
   * Callback function to remove this field.
   */
  onRemove: () => void;
};

/**
 * Individual domain association field component.
 * Handles a single domain and type input pair with error handling.
 */
function DomainAssociationField({
  control,
  name,
  index,
  onRemove,
}: DomainAssociationFieldProps) {
  const domainId = useId();
  const typeId = useId();
  const domainErrorId = useId();
  const typeErrorId = useId();
  const fieldGroupId = useId();

  // Use controller for domain input
  const {
    field: domainField,
    fieldState: { error: domainError },
  } = useController({
    name: `${name}.${index}.domain` as FieldPath<ContactFormData>,
    control,
  });

  // Use controller for type select
  const {
    field: typeField,
    fieldState: { error: typeError },
  } = useController({
    name: `${name}.${index}.type` as FieldPath<ContactFormData>,
    control,
  });

  return (
    <div
      className="grid grid-cols-[1fr,auto,auto] gap-2"
      role="group"
      id={fieldGroupId}
      aria-label={`Domain association ${index + 1}`}
    >
      <div className="space-y-1">
        <Input
          id={domainId}
          value={domainField.value as string}
          onChange={domainField.onChange}
          onBlur={domainField.onBlur}
          name={domainField.name}
          placeholder="Enter domain"
          className={cn(domainError ? 'border-destructive focus-visible:ring-destructive' : '')}
          aria-invalid={!!domainError}
          aria-describedby={domainError ? domainErrorId : undefined}
          aria-label={`Domain name for association ${index + 1}`}
        />
        {domainError && (
          <p id={domainErrorId} className="text-xs text-destructive">
            {domainError.message}
          </p>
        )}
      </div>
      <div className="space-y-1">
        <Select
          value={typeField.value as string}
          onValueChange={(value) => typeField.onChange(value)}
        >
          <SelectTrigger
            id={typeId}
            className={cn(typeError ? 'border-destructive focus-visible:ring-destructive' : '')}
            aria-invalid={!!typeError}
            aria-describedby={typeError ? typeErrorId : undefined}
            aria-label={`Association type for domain ${index + 1}`}
          >
            <SelectValue placeholder="Select type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="registrant">Registrant</SelectItem>
            <SelectItem value="verification">Verification</SelectItem>
          </SelectContent>
        </Select>
        {typeError && (
          <p id={typeErrorId} className="text-xs text-destructive">
            {typeError.message}
          </p>
        )}
      </div>
      <Button
        type="button"
        variant="destructive"
        size="icon"
        onClick={onRemove}
        aria-label={`Remove domain association ${index + 1}`}
      >
        <X className="size-4" />
      </Button>
    </div>
  );
}

// Memoize the component to prevent unnecessary re-renders
const MemoizedDomainAssociationField = React.memo(DomainAssociationField);

/**
 * Domain Associations component.
 * Manages a list of domain associations with add/remove functionality.
 * Allows users to add, edit, and remove domain associations for a contact.
 */
export function DomainAssociations({ control, name }: DomainAssociationsProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const handleAddDomain = () => {
    append({ domain: '', type: 'registrant' });
  };

  // Function to focus the first input of the newly added field
  const focusNewField = () => {
    // Use setTimeout to ensure the DOM has updated
    setTimeout(() => {
      const newFieldIndex = fields.length;
      const newDomainInput = document.querySelector<HTMLInputElement>(
        `input[name="${name}.${newFieldIndex}.domain"]`,
      );

      if (newDomainInput) {
        newDomainInput.focus();
      }
    }, 0);
  };

  return (
    <div
      className="space-y-4"
      role="region"
      aria-label="Domain associations section"
    >
      <div className="flex items-center justify-between">
        <h3 className="text-sm font-medium">Domain Associations</h3>
        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={() => {
            handleAddDomain();
            focusNewField();
          }}
          aria-label="Add new domain association"
        >
          Add Domain
        </Button>
      </div>

      <div className="space-y-4">
        {fields.length === 0
          ? (
            <p className="text-sm text-muted-foreground">No domain associations added yet.</p>
          )
          : (
            fields.map((field, index) => (
              <MemoizedDomainAssociationField
                key={field.id}
                control={control}
                name={name}
                index={index}
                onRemove={() => remove(index)}
              />
            ))
          )}
      </div>
    </div>
  );
}
