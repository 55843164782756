/* eslint-disable react-dom/no-dangerously-set-innerhtml */
import * as React from 'react';
import DOMPurify from 'dompurify';

import type { Message as NylasMessage } from 'nylas';

type MessageContentProps = {
  content: Partial<NylasMessage> | null;
};

export function MessageContent({ content }: MessageContentProps) {
  if (!content) {
    return (
      <div className="text-sm text-muted-foreground">
        Loading message content...
      </div>
    );
  }

  return (
    <div style={{ containerType: 'inline-size' }}>
      <div
        className="block w-full min-w-0 overflow-x-auto [&_img]:max-w-full [&_img]:h-auto [&_table]:max-w-full [&_pre]:max-w-full [&_iframe]:max-w-full [&_iframe]:h-auto"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            content?.body as string || '',
          ),
        }}
      />
    </div>
  );
}
