// src/pages/app/deals/components/deal-columns.tsx
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  closestCenter,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { format } from 'date-fns';
import { Calendar, ArrowRight, GripVertical } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import type { Deal, DealStage } from '@/hooks/use-deals';
import { cn } from '@/lib/utils';

const DEFAULT_STAGES: DealStage[] = [
  'Annual Verification',
  'Pending Verification',
  'Organization Verification in Progress',
  'Name Selection in Progress',
  'Employment Verification in Progress',
  'WHOIS Update in Progress',
  'Failed Verification',
  'Hold',
  'Verification Completed',
  'Verified Data Sent',
  'Completed',
];

// Custom hook to persist stage ordering
function usePersistedStages() {
  const [stages, setStages] = useState<DealStage[]>(() => {
    try {
      const stored = localStorage.getItem('deal-columns-order');
      if (!stored)
        return DEFAULT_STAGES;

      const parsed = JSON.parse(stored) as DealStage[];

      // Validate that all default stages exist in the stored data
      const hasAllStages = DEFAULT_STAGES.every((defaultStage) =>
        parsed.includes(defaultStage),
      );

      // If we have all stages and no extras, use the stored order
      if (hasAllStages && parsed.length === DEFAULT_STAGES.length) {
        return parsed;
      }

      return DEFAULT_STAGES;
    } catch {
      return DEFAULT_STAGES;
    }
  });

  useEffect(() => {
    localStorage.setItem('deal-columns-order', JSON.stringify(stages));
  }, [stages]);

  return {
    stages,
    setStages,
    resetToDefault: () => setStages(DEFAULT_STAGES),
  };
}

function DealCard({ deal }: { deal: Deal }) {
  return (
    <Link
      to={`/app/deals/${deal.id}`}
      className="block transition-colors hover:bg-muted/50"
    >
      <div className="border-b border-border p-3 last:border-0">
        <div className="space-y-1 text-sm">
          <div className="font-medium text-foreground">
            {deal.domains.join(', ')}
          </div>
          <div className="flex items-center gap-2 text-xs text-muted-foreground">
            <Calendar className="size-3" />
            <span>{format(new Date(deal.created_at), 'MMM d')}</span>
            <ArrowRight className="size-3" />
            <span>{format(new Date(deal.updated_at), 'MMM d')}</span>
          </div>
        </div>
      </div>
    </Link>
  );
}

interface SortableDealColumnProps {
  stage: DealStage;
  deals: Deal[];
  count: number;
  isAnyDragging: boolean;
  isBeingDragged: boolean;
}

function SortableDealColumn({
  stage,
  deals,
  count,
  isAnyDragging,
  isBeingDragged,
}: SortableDealColumnProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: stage });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <Card
      ref={setNodeRef}
      style={style}
      className={cn(
        'overflow-hidden transition-all duration-200',
        isAnyDragging && !isBeingDragged && 'scale-95 opacity-70',
      )}
    >
      <CardHeader className="flex flex-row items-center justify-between border-b border-border p-3">
        <CardTitle className="flex items-center gap-2 text-sm font-medium">
          <div {...attributes} {...listeners} className="cursor-grab">
            <GripVertical className="size-4 text-muted-foreground" />
          </div>
          <span>{stage}</span>
          <span className="rounded-full bg-muted px-2 py-0.5 text-xs">
            {count}
          </span>
        </CardTitle>
      </CardHeader>
      <CardContent
        className={cn(
          'max-h-[570px] overflow-y-auto p-0 transition-all duration-200',
          isAnyDragging && 'max-h-[100px]',
        )}
      >
        {count > 0
          ? deals.map((deal) => (
              <DealCard key={deal.id} deal={deal} />
            ))
          : (
              <div className="p-3 text-center text-sm text-muted-foreground">
                No deals
              </div>
            )}
      </CardContent>
    </Card>
  );
}

export function DealColumns({ deals }: { deals: Deal[] }) {
  const { stages, setStages } = usePersistedStages();
  const [activeId, setActiveId] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 8,
      },
    }),
  );

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string);
    setIsDragging(true);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setStages((currentStages) => {
        const oldIndex = currentStages.indexOf(active.id as DealStage);
        const newIndex = currentStages.indexOf(over.id as DealStage);
        return arrayMove(currentStages, oldIndex, newIndex);
      });
    }

    setActiveId(null);
    setIsDragging(false);
  };

  // Get deal counts by stage for overlay display
  const stageCounts: Record<string, number> = {};
  for (const stage of stages) {
    stageCounts[stage] = deals.filter((deal) => deal.deal_stage === stage).length;
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={stages} strategy={rectSortingStrategy}>
        <div className={cn(
          'grid gap-4',
          isDragging
            ? 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'
            : 'grid-cols-1 lg:grid-cols-3 xl:grid-cols-4',
        )}
        >
          {stages.map((stage) => {
            const stageDeals = deals
              .filter((deal) => deal.deal_stage === stage)
              .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
            const count = stageDeals.length;

            return (
              <SortableDealColumn
                key={stage}
                stage={stage}
                deals={stageDeals}
                count={count}
                isAnyDragging={isDragging}
                isBeingDragged={activeId === stage}
              />
            );
          })}
        </div>
      </SortableContext>

      <DragOverlay>
        {activeId && (
          <Card className="w-full overflow-hidden">
            <CardHeader className="border-b border-border p-3">
              <CardTitle className="flex items-center gap-2 text-sm font-medium">
                <span>{activeId}</span>
                <span className="rounded-full bg-muted px-2 py-0.5 text-xs">
                  {stageCounts[activeId] || 0}
                </span>
              </CardTitle>
            </CardHeader>
            <CardContent className="h-[100px] p-0">
              <div className="flex h-full items-center justify-center text-muted-foreground">
                <p>Drop to reorder</p>
              </div>
            </CardContent>
          </Card>
        )}
      </DragOverlay>
    </DndContext>
  );
}
