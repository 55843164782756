import React, { useState, useEffect, useMemo } from 'react';
import { format, addDays, addMinutes, isBefore, isAfter } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { TimePicker } from '@/components/ui/time-picker';
import {
  getSystemTimezone,
  getFormattedTimezones,
  findTimezoneIdByFormattedString,
  getUnixTimestamp,
  getSystemTimezoneId,
} from '@/lib/timezone';

// Main Dialog Props
type ScheduleSendDialogProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onScheduleSend: (unixTimestamp: number) => void;
};

function ScheduleSendDialog({
  open,
  onOpenChange,
  onScheduleSend,
}: ScheduleSendDialogProps) {
  const [date, setDate] = useState<Date>(new Date());
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [time, setTime] = useState<string>('08:00');
  const [timeFormat, setTimeFormat] = useState<'AM' | 'PM'>('AM');
  const [timezoneId, setTimezoneId] = useState<string>('');

  // Initialize timezone when dialog opens
  useEffect(() => {
    if (open && !timezoneId) {
      setTimezoneId(getSystemTimezoneId());
    }
  }, [open, timezoneId]);

  // Date range constraints
  const today = new Date();
  const maxDate = addDays(today, 30);

  // Memoize formatted timezone options to prevent recalculation on every render
  const formattedTimezones = useMemo(() => {
    // Use the scheduled date for offset calculations to account for DST on the target date
    return getFormattedTimezones(date);
  }, [date]); // Recalculate when date changes

  // Find the formatted display string for the current timezone ID
  const formattedTimezone = useMemo(() => {
    if (!timezoneId)
      return '';
    const allTimezones = Object.values(formattedTimezones).flat();
    return allTimezones.find((tz) => tz.id === timezoneId)?.display || '';
  }, [timezoneId, formattedTimezones]);

  const formatTime = (): string => {
    if (!time)
      return '';

    try {
      const [hours, minutes] = time.split(':');
      let hoursNum = Number.parseInt(hours, 10);
      const minutesStr = minutes || '00';

      if (timeFormat === 'AM' && hoursNum === 12) {
        hoursNum = 0;
      } else if (timeFormat === 'PM' && hoursNum < 12) {
        hoursNum += 12;
      }

      return `${hoursNum.toString().padStart(2, '0')}:${minutesStr.padStart(2, '0')}`;
    } catch {
      return time;
    }
  };

  // Calculate Unix timestamp when Schedule Send is clicked
  const handleScheduleSend = () => {
    try {
      // Format date and time
      const now = new Date();
      const minScheduleTime = addMinutes(now, 5);
      const maxScheduleTime = addDays(now, 30);
      const formattedTime = formatTime();

      // Basic validation
      if (!formattedTime) {
        toast.error('Please enter a valid time');
        return;
      }

      const [hours, minutes] = formattedTime.split(':').map(Number);
      if (Number.isNaN(hours) || Number.isNaN(minutes)) {
        toast.error('Please enter a valid time');
        return;
      }

      if (!timezoneId) {
        toast.error('Please select a timezone');
        return;
      }

      // Get Unix timestamp with timezone conversion
      const unixTimestamp = getUnixTimestamp(date, hours, minutes, timezoneId);

      // Convert back to Date for validation
      const scheduledDate = new Date(unixTimestamp * 1000);

      // Validate scheduled time
      if (isBefore(scheduledDate, minScheduleTime)) {
        toast.error('Schedule time must be at least 5 minutes in the future');
        return;
      }

      if (isAfter(scheduledDate, maxScheduleTime)) {
        toast.error('Schedule time cannot be more than 30 days in the future');
        return;
      }

      // Call the onScheduleSend callback with the timestamp
      onScheduleSend(unixTimestamp);

      // Close dialog
      onOpenChange(false);
    } catch (error) {
      console.error('Error scheduling message:', error);
      toast.error('Failed to schedule message');
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Schedule Message</DialogTitle>
          <DialogDescription>
            Choose when you want your message to be sent
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          {/* Date Picker */}
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="date" className="text-right font-medium">
              Date
            </label>
            <div className="col-span-3">
              <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
                <PopoverTrigger asChild>
                  <Button
                    id="date"
                    variant="outline"
                    className="w-full justify-start text-left font-normal"
                  >
                    <CalendarIcon className="mr-2 size-4" />
                    {format(date, 'PPP')}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={date}
                    onSelect={(newDate) => {
                      if (newDate) {
                        setDate(newDate);
                        setCalendarOpen(false);
                      }
                    }}
                    disabled={(date) =>
                      isBefore(date, today) || date > maxDate}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </div>
          </div>

          {/* Time Picker */}
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="time" className="text-right font-medium">
              Time
            </label>
            <div className="col-span-3 flex">
              <div className="relative w-full">
                <TimePicker
                  time={time}
                  timeFormat={timeFormat}
                  onTimeChange={setTime}
                  onFormatChange={setTimeFormat}
                />
              </div>
            </div>
          </div>

          {/* Timezone Selector */}
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="timezone" className="text-right font-medium">
              Timezone
            </label>
            <div className="col-span-3">
              <Select value={timezoneId} onValueChange={setTimezoneId}>
                <SelectTrigger id="timezone" className="w-full">
                  <SelectValue>
                    {formattedTimezone || "Select timezone"}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {/* Popular timezones */}
                  <SelectGroup>
                    <SelectLabel>Popular Timezones</SelectLabel>
                    {formattedTimezones.popular.map((tz) => (
                      <SelectItem key={tz.id} value={tz.id}>
                        {tz.display}
                      </SelectItem>
                    ))}
                  </SelectGroup>

                  {/* Regional groups */}
                  {Object.entries(formattedTimezones)
                    .filter(([region]) => region !== 'popular')
                    .map(([region, timezones]) => (
                      <SelectGroup key={region}>
                        <SelectLabel>{region}</SelectLabel>
                        {timezones.map((tz) => (
                          <SelectItem key={tz.id} value={tz.id}>
                            {tz.display}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button type="button" onClick={handleScheduleSend}>
            Schedule Send
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default ScheduleSendDialog;
