import { useState } from 'react';
import { ChevronDown, Send } from 'lucide-react';
import { AiOutlineSignature } from 'react-icons/ai';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import type { MessageToolbarProps } from './types';
import type { SendMode } from '../../types/communications';
import ScheduleSendDialog from './schedule-send-dialog';

/**
 * Renders the message toolbar with various message composition actions
 */
export function MessageToolbar({
  onSignatureInsert,
  onSend,
  isSendEnabled,
  template,
  sendMode: propsSendMode,
  onSendModeChange,
}: MessageToolbarProps) {
  const [localSendMode, setLocalSendMode] = useState<SendMode>('send');
  const [showScheduleDialog, setShowScheduleDialog] = useState(false);

  // Use either the prop value or local state
  const sendMode = propsSendMode ?? localSendMode;

  // Handle send mode change
  const handleSendModeChange = (mode: SendMode) => {
    if (onSendModeChange) {
      onSendModeChange(mode);
    }
    setLocalSendMode(mode);
  };

  // Handle scheduled send
  const handleScheduleSend = (timestamp: number) => {
    // Add the send_at timestamp to the message content
    onSend(timestamp);
  };
  return (
    <div className="flex items-center justify-between border-t bg-muted/40 px-2 py-1">
      <div className="flex items-center gap-0.5">
        <Button
          variant="ghost"
          size="sm"
          className="size-8 p-0"
          onClick={() => template && onSignatureInsert(template.template)}
        >
          <AiOutlineSignature className="size-4" />
        </Button>
      </div>

      <div className="flex items-center">
        <Button
          size="sm"
          onClick={() => {
            if (sendMode === 'send') {
              // Send the message now
              onSend();
            } else {
              // Show the schedule dialog
              setShowScheduleDialog(true);
            }
          }}
          disabled={!isSendEnabled}
          className="rounded-r-none border-r-0"
        >
          <Send className="mr-2 size-4" />
          {sendMode === 'send' ? 'Send' : 'Send Later'}
        </Button>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              size="sm"
              variant="default"
              className="rounded-l-none px-2"
              disabled={!isSendEnabled}
            >
              <ChevronDown className="size-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuCheckboxItem
              checked={sendMode === 'send'}
              onCheckedChange={() => handleSendModeChange('send')}
            >
              Send
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              checked={sendMode === 'send-later'}
              onCheckedChange={() => handleSendModeChange('send-later')}
            >
              Send later
            </DropdownMenuCheckboxItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {/* Schedule Send Dialog */}
      <ScheduleSendDialog
        open={showScheduleDialog}
        onOpenChange={setShowScheduleDialog}
        onScheduleSend={handleScheduleSend}
      />
    </div>
  );
}
