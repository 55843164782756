import * as React from 'react';
import { FaReply, FaReplyAll } from 'react-icons/fa';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useHub } from '@/contexts/hub-context';
import { useMessagesByIds } from '@/hooks/use-messages';
import type { CommunicationDisplay } from '../types/communications';
import type { EmailName } from 'nylas';
import { EmailNameDisplay } from './email-name-display';

function hasMultipleRecipients(emailTo?: EmailName[], emailCC?: EmailName[], emailBCC?: EmailName[]): boolean {
  return (emailTo?.length ?? 0) + (emailCC?.length ?? 0) + (emailBCC?.length ?? 0) > 1;
}

type MessageHeaderProps = {
  sender: CommunicationDisplay['sender'];
  timestamp: string | Date;
  isExternal?: boolean;
  isEmail?: boolean;
  emailThreadId?: string;
  emailMessageId?: string;
  emailFrom?: EmailName[];
  emailTo?: EmailName[];
  emailCC?: EmailName[];
  emailBCC?: EmailName[];
  emailSubject?: string;
};

export function MessageHeader({
  sender,
  timestamp,
  isExternal,
  isEmail,
  emailThreadId,
  emailMessageId,
  emailFrom,
  emailTo,
  emailCC,
  emailBCC,
  emailSubject,
}: MessageHeaderProps) {
  const { isDealInbox, setReplyThread, incrementReplyKey } = useHub();
  const { messages } = useMessagesByIds(emailMessageId ? [emailMessageId] : []);

  return (
    <div className="flex items-start gap-3">
      <Avatar className="size-9 border">
        <AvatarImage
          src={sender.avatar || ''}
          alt={sender.name || sender.email}
        />
        <AvatarFallback>{sender.name.charAt(0) || sender.email.charAt(0).toUpperCase()}</AvatarFallback>
      </Avatar>
      <div className="flex-1">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {isDealInbox && isEmail && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" size="icon" className="size-8 ">
                    <HiOutlineDotsVertical className="size-6" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem onClick={() => {
                    if (emailThreadId && messages?.at(0)) {
                      const replyMessage = { ...messages.at(0)!, to: [], cc: [], bcc: [] };
                      setReplyThread(emailThreadId, replyMessage);
                    }
                  }}
                  >
                    <FaReply className="mr-2 size-4 text-muted-foreground" />
                    Reply
                  </DropdownMenuItem>
                  {hasMultipleRecipients(emailTo, emailCC, emailBCC) && (
                    <DropdownMenuItem onClick={() => {
                      if (emailThreadId && messages?.at(0)) {
                        setReplyThread(emailThreadId, messages.at(0));
                        incrementReplyKey();
                      }
                    }}
                    >
                      <FaReplyAll className="mr-2 size-4 text-muted-foreground" />
                      Reply to all
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            )}
            {isEmail
              ? (
                <EmailNameDisplay className="text-base font-semibold text-foreground" emailNames={emailFrom} />
              )
              : (
                <EmailNameDisplay className="text-base font-semibold text-foreground" emailNames={[{ name: sender.name, email: sender.email }]} />
              )}

            {isExternal && (
              <span className="rounded-full bg-blue-100 px-2 py-0.5 text-xs text-blue-800">
                External
              </span>
            )}
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm text-muted-foreground">
              {new Date(timestamp).toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })}
            </span>
          </div>
        </div>
        {isEmail && (
          <div className="mt-1 space-y-1">
            <EmailNameDisplay emailNames={emailTo} label="To" />
            {emailCC && <EmailNameDisplay emailNames={emailCC} label="Cc" />}
            {emailBCC && <EmailNameDisplay emailNames={emailBCC} label="Bcc" />}
            <div className="text-sm ">
              <span className=" font-semibold">Subject: </span>
              {emailSubject}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
