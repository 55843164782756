import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useAxios } from './use-axios';

const API_BASE_URL = '/raw-transactions';

export const RAW_TRANSACTIONS_QUERY_KEY = ['raw-transactions'];

interface ApiError {
  error?: {
    message?: string;
    status?: number;
  };
  details?: Array<{ message: string }>;
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested resource was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

export interface RawTransaction {
  id: string;
  domain: string;
  data: {
    cost: number;
    ianaId: string;
    period: number;
    endDate: number;
    category: string;
    operation: string;
    registrar: string;
    startDate: number;
    domainName: string;
    domainRoid: string;
    transactionId: number;
    transactionDate: number;
    serverTransactionId: string;
  };
}

/**
 * Hook to fetch all raw transactions from the GoDaddy Registry.
 * Returns a react-query result with raw transaction data.
 *
 * Refreshes automatically every minute while the component is mounted.
 */
export function useRawTransactions() {
  const axios = useAxios();

  return useQuery({
    queryKey: RAW_TRANSACTIONS_QUERY_KEY,
    queryFn: async () => {
      try {
        const { data } = await axios.get<RawTransaction[]>(API_BASE_URL);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    // Cache settings
    gcTime: Infinity,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    // Refresh every minute
    refetchInterval: 60 * 1000,
    refetchIntervalInBackground: false,
  });
}
