import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import type { Partner, CreatePartnerInput, UpdatePartnerInput } from '@/types/partner';

const formSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  primary_contact: z.string().min(1, 'Primary contact is required'),
  email: z.string().email('Invalid email address'),
  iana_id: z.string().nullable().optional(),
});

interface PartnerSheetProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  initialData?: Omit<Partner, 'id' | 'created_at' | 'updated_at'>;
  onSubmit: (data: CreatePartnerInput & UpdatePartnerInput) => Promise<void>;
}

export default function PartnerSheet({
  open,
  onOpenChange,
  initialData,
  onSubmit,
}: PartnerSheetProps) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      primary_contact: '',
      email: '',
      iana_id: null,
    },
  });

  // Update form values when initialData changes
  useEffect(() => {
    if (initialData) {
      form.reset({
        name: initialData.name,
        primary_contact: initialData.primary_contact,
        email: initialData.email,
        iana_id: initialData.iana_id ?? null,
      });
    } else {
      form.reset({
        name: '',
        primary_contact: '',
        email: '',
        iana_id: null,
      });
    }
  }, [initialData, form]);

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      await onSubmit(values);
      form.reset();
      onOpenChange(false);
    } catch (error) {
      // Error is handled by the mutation
      console.error('Form submission error:', error);
    }
  };

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="sm:max-w-[540px]">
        <SheetHeader>
          <SheetTitle>{initialData ? 'Edit Partner' : 'Create Partner'}</SheetTitle>
          <SheetDescription>
            {initialData
              ? 'Update the partner details below.'
              : 'Add a new partner by filling out the form below.'}
          </SheetDescription>
        </SheetHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="mt-8 space-y-6">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Partner name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="primary_contact"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Primary Contact</FormLabel>
                  <FormControl>
                    <Input placeholder="Primary contact name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input type="email" placeholder="contact@example.com" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="iana_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>IANA ID</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="IANA ID (optional)"
                      {...field}
                      value={field.value || ''}
                      onChange={(e) => field.onChange(e.target.value || null)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end gap-4">
              <Button
                type="button"
                variant="outline"
                onClick={() => onOpenChange(false)}
              >
                Cancel
              </Button>
              <Button type="submit">
                {initialData ? 'Update Partner' : 'Create Partner'}
              </Button>
            </div>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
}
