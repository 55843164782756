import * as React from 'react';
import startCase from 'lodash.startcase';
import { Folder, Inbox, MessageSquare } from 'lucide-react';
import { RiMailSendLine } from 'react-icons/ri';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { useHub } from '@/contexts/hub-context';
import { useNylasFolders, useNylasFoldersBatch } from '@/hooks/use-nylas-folders';
import { AccountSwitcher } from '../components/account-switcher';
import { CompactContactsPanel } from '../components/compact-contacts-panel';

function getAccounts(verifyGrantId: string | undefined, myGrantId: string | undefined, myGrantEmail: string | undefined) {
  const accounts = [];

  if (verifyGrantId) {
    accounts.push({
      label: 'verify@ftld.com',
      email: 'verify@ftld.com',
      grantId: verifyGrantId,
      icon: <RiMailSendLine className="size-4" />,
    });
  }

  if (myGrantId && myGrantEmail) {
    accounts.push({
      label: myGrantEmail,
      email: myGrantEmail,
      grantId: myGrantId,
      icon: <RiMailSendLine className="size-4" />,
    });
  }

  return accounts;
}

interface LeftPaneProps {
  isCollapsed: boolean;
}

export function LeftPane({ isCollapsed }: LeftPaneProps) {
  const {
    selectedInbox,
    setSelectedInbox,
    selectedFolderId,
    setSelectedFolderId,
    myGrantId,
    myGrantEmail,
    verifyGrantId,
    sendAsGrantId,
    setSendAsGrantId,
    setSendAsEmailAddress,
  } = useHub();

  const { data: myFolders } = useNylasFoldersBatch(myGrantId, ['INBOX', 'SENT', 'IMPORTANT', 'STARRED', 'DRAFT', 'SPAM', 'TRASH']);
  // const { data: verifyFolders } = useNylasFolders(verifyGrantId, { queryParams: { limit: 100 } });
  // NOTE: Label_9049067228774022953 is the folder ID for fTLD's 'Complete' folder
  const { data: verifyFolders } = useNylasFoldersBatch(verifyGrantId, ['INBOX', 'SENT', 'IMPORTANT', 'Label_9049067228774022953']);

  return (
    <ResizablePanelGroup direction="vertical" className="h-full">
      <ResizablePanel defaultSize={60} minSize={30}>
        <div className={isCollapsed ? 'h-[52px]' : 'p-2'}>
          <AccountSwitcher
            isCollapsed={isCollapsed}
            accounts={getAccounts(verifyGrantId, myGrantId, myGrantEmail)}
            value={sendAsGrantId}
            onChange={({ email, grantId }) => {
              setSendAsGrantId(grantId);
              setSendAsEmailAddress(email);
            }}
          />
        </div>
        <Separator />
        <ScrollArea className="h-full">
          <div className="space-y-2 p-2">
            <button
              type="button"
              onClick={() => {
                setSelectedFolderId(undefined);
                setSelectedInbox('deal');
              }}
              className={`flex w-full items-center gap-2 rounded-lg px-4 py-2 hover:bg-accent ${selectedInbox === 'deal' ? 'bg-accent font-semibold' : ''
                }`}
            >
              <MessageSquare className="size-4" />
              <span className={`${isCollapsed ? 'hidden' : ''}`}>Deal Communications</span>
            </button>
            <Separator />
            <Accordion type="single" collapsible className="space-y-1">
              {verifyGrantId && (
                <AccordionItem value="verify" className="border-0">
                  <AccordionTrigger
                    onClick={() => {
                      setSelectedFolderId(undefined);
                      setSelectedInbox('verify@ftld.com');
                    }}
                    className={`rounded-lg px-4 py-2 hover:bg-accent hover:no-underline ${selectedInbox === 'verify@ftld.com' ? 'bg-accent font-semibold' : ''
                      }`}
                  >
                    <div className="flex items-center gap-2">
                      <Inbox className="size-4" />
                      <span className={`${isCollapsed ? 'hidden' : ''}`}>verify@ftld.com</span>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="pb-0 pt-1">
                    <div className="space-y-1 pl-6">
                      {verifyFolders?.map((folder) => (
                        <button
                          key={folder.id}
                          type="button"
                          onClick={() => {
                            setSelectedFolderId(folder.id);
                            setSelectedInbox('verify@ftld.com');
                          }}
                          className={`flex w-full items-center gap-2 rounded-lg px-4 py-2 text-sm hover:bg-accent ${selectedFolderId === folder.id ? 'bg-accent font-semibold' : ''}`}
                        >
                          <Folder className="size-4" />
                          <span className={`${isCollapsed ? 'hidden' : ''}`}>{startCase(folder.name.toLowerCase())}</span>
                        </button>
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              )}

              {myGrantId && myGrantEmail && myFolders && (
                <AccordionItem value="grant" className="border-0">
                  <AccordionTrigger
                    onClick={() => {
                      setSelectedFolderId(undefined);
                      setSelectedInbox(myGrantEmail);
                    }}
                    className={`rounded-lg px-4 py-2 hover:bg-accent hover:no-underline ${selectedInbox === myGrantEmail ? 'bg-accent font-semibold' : ''
                      }`}
                  >
                    <div className="flex items-center gap-2">
                      <Inbox className="size-4" />
                      <span className={`${isCollapsed ? 'hidden' : ''}`}>{myGrantEmail}</span>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="pb-0 pt-1">
                    <div className="space-y-1 pl-6">
                      {myFolders?.map((folder) => (
                        <button
                          key={folder.id}
                          type="button"
                          onClick={() => {
                            setSelectedFolderId(folder.id);
                            setSelectedInbox(myGrantEmail);
                          }}
                          className={`flex w-full items-center gap-2 rounded-lg px-4 py-2 text-sm hover:bg-accent ${selectedFolderId === folder.id ? 'bg-accent font-semibold' : ''}`}
                        >
                          <Folder className="size-4" />
                          <span className={`${isCollapsed ? 'hidden' : ''}`}>{startCase(folder.name.toLowerCase())}</span>
                        </button>
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              )}
            </Accordion>
          </div>
        </ScrollArea>
      </ResizablePanel>
      <ResizableHandle withHandle />
      <ResizablePanel defaultSize={40} minSize={30}>
        <CompactContactsPanel />
      </ResizablePanel>
    </ResizablePanelGroup>
  );
}
