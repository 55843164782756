import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useAxios } from './use-axios';

const API_BASE_URL = '/email-templates';

export const EMAIL_TEMPLATES_QUERY_KEY = ['email-templates'];

export type RenderEmailTemplateResponse = {
  html: string;
  subject: string;
  to?: string[];
  cc?: string[];
};

export type TemplateVariable = {
  description: string;
  example: string;
};

export type TemplateVariables = {
  description: string;
  variables: Record<string, TemplateVariable>;
};

export type EmailTemplate = {
  name: string; // This is the unique identifier
  short_description: string;
  long_description: string;
  tld: string;
  template: {
    body: string;
  };
  to?: string;
  cc?: string;
  subject: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
};

interface CreateEmailTemplateData {
  name: string;
  short_description: string;
  long_description: string;
  tld: string;
  template: {
    body: string;
  };
  to?: string;
  cc?: string;
  subject: string;
}

interface UpdateEmailTemplateData extends Partial<Omit<CreateEmailTemplateData, 'name'>> {
  name: string; // name is required for updates as it's the identifier
}

interface ApiError {
  error?: {
    message?: string;
    status?: number;
  };
  details?: Array<{ message: string }>;
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested resource was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

// Get template variables
export function useEmailTemplateVariables() {
  const axios = useAxios();

  return useQuery({
    queryKey: [...EMAIL_TEMPLATES_QUERY_KEY, 'variables'],
    queryFn: async () => {
      try {
        const { data } = await axios.get<TemplateVariables>(`${API_BASE_URL}/variables`);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });
}

// List all email templates
export function useEmailTemplates() {
  const axios = useAxios();

  return useQuery({
    queryKey: EMAIL_TEMPLATES_QUERY_KEY,
    queryFn: async () => {
      try {
        const { data } = await axios.get<EmailTemplate[]>(API_BASE_URL);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });
}

// Get a single email template
export function useEmailTemplate(name: string) {
  const axios = useAxios();

  return useQuery({
    queryKey: [...EMAIL_TEMPLATES_QUERY_KEY, name],
    queryFn: async () => {
      try {
        const { data } = await axios.get<EmailTemplate>(`${API_BASE_URL}/${name}`);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: !!name,
  });
}

// Create an email template
export function useCreateEmailTemplate() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newTemplate: CreateEmailTemplateData) => {
      try {
        const { data } = await axios.post<EmailTemplate>(API_BASE_URL, newTemplate);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: EMAIL_TEMPLATES_QUERY_KEY });
    },
  });
}

// Update an email template
export function useUpdateEmailTemplate() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ oldName, data }: { oldName: string; data: UpdateEmailTemplateData }) => {
      try {
        const { data: response } = await axios.put<EmailTemplate>(`${API_BASE_URL}/${oldName}`, data);
        return response;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: EMAIL_TEMPLATES_QUERY_KEY });
    },
  });
}

// Delete an email template
export function useDeleteEmailTemplate() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (name: string) => {
      try {
        await axios.delete(`${API_BASE_URL}/${name}`);
        return name;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: EMAIL_TEMPLATES_QUERY_KEY });
    },
  });
}

// Render an email template with deal data
export function useRenderEmailTemplate(name: string, dealId: string, to?: string[], cc?: string[]) {
  const axios = useAxios();

  return useQuery({
    queryKey: [...EMAIL_TEMPLATES_QUERY_KEY, name, 'render', dealId, ...(to || []), ...(cc || [])],
    queryFn: async () => {
      try {
        const { data } = await axios.get<RenderEmailTemplateResponse>(
          `${API_BASE_URL}/${name}/render/${dealId}`,
          {
            params: {
              ...(to ? { to } : {}),
              ...(cc ? { cc } : {}),
            },
          },
        );
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: !!name && !!dealId,
    // Reduce retry attempts and timing for failed requests
    retry: 1,
    retryDelay: 1000,
  });
}

// Delete multiple email templates
export function useDeleteEmailTemplates() {
  const queryClient = useQueryClient();
  const deleteMutation = useDeleteEmailTemplate();

  return useMutation({
    mutationFn: async (names: string[]) => {
      try {
        await Promise.all(names.map(async (name) => {
          await deleteMutation.mutateAsync(name);
        }));
        return names;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: EMAIL_TEMPLATES_QUERY_KEY });
    },
  });
}
