import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { startOfMonth, endOfMonth, subMonths, format } from 'date-fns';
import { Download, CalendarIcon } from 'lucide-react';
import { toast } from 'sonner';
import { utils, writeFile } from 'xlsx';
import * as z from 'zod';
import { useBreadcrumb } from '@/components/breadcrumb-provider';
import Container from '@/components/container';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useDeals, type Deal, type DealOutcome } from '@/hooks/use-deals';
import { cn } from '@/lib/utils';

const formSchema = z.object({
  startDate: z.date(),
  endDate: z.date(),
  tld: z.array(z.string()).min(1, 'Select at least one TLD'),
  outcomes: z.array(z.string()),
  dateRange: z.enum(['previous', 'current', 'custom']),
});

type DealsWonLostFilters = z.infer<typeof formSchema>;

const TLDS = [
  { value: '.bank', label: '.Bank' },
  { value: '.insurance', label: '.Insurance' },
];

const DEAL_OUTCOMES: DealOutcome[] = [
  'Approved',
  'Combined',
  'Consolidation',
  'Did not Register',
  'Domain Deleted',
  'Duplicate',
  'fTLD Domain',
  'Incomplete',
  'Ineligible',
  'Junk',
  'Name Selection',
  'No Renew',
  'No Reply',
  'No Verification Needed',
  'Not Wanted',
  'Organization',
  'Price',
];

function DealsWonLostReport() {
  const { setLabel, removeLabel } = useBreadcrumb();
  const [isGenerating, setIsGenerating] = useState(false);
  const { data: deals } = useDeals();

  const form = useForm<DealsWonLostFilters>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      startDate: startOfMonth(subMonths(new Date(), 1)),
      endDate: endOfMonth(subMonths(new Date(), 1)),
      tld: ['.bank'],
      outcomes: ['', ...DEAL_OUTCOMES], // Include empty string for "No outcome"
      dateRange: 'previous',
    },
  });

  useEffect(() => {
    const path = '/app/reports/deals-won-lost';
    setLabel(path, {
      name: 'Deals Won/Lost Report',
      path,
    });
    return () => removeLabel(path);
  }, [setLabel, removeLabel]);

  const handleDateRangeChange = (value: string) => {
    const today = new Date();
    switch (value) {
      case 'previous': {
        form.setValue('startDate', startOfMonth(subMonths(today, 1)));
        form.setValue('endDate', endOfMonth(subMonths(today, 1)));
        break;
      }
      case 'current': {
        form.setValue('startDate', startOfMonth(today));
        form.setValue('endDate', endOfMonth(today));
        break;
      }
      // For custom, keep existing dates
    }
    form.setValue('dateRange', value as 'previous' | 'current' | 'custom');
  };

  const handleGenerateReport = async (data: DealsWonLostFilters) => {
    try {
      setIsGenerating(true);

      if (!deals) {
        throw new Error('No deals data available');
      }

      // Filter deals based on criteria
      const filteredDeals = deals.filter((deal: Deal) => {
        const dealDate = new Date(deal.created_at);
        const isInDateRange = dealDate >= data.startDate && dealDate <= data.endDate;
        const hasTLD = deal.domains.some((domain) =>
          data.tld.some((tld) => domain.endsWith(tld.toLowerCase())),
        );
        const hasMatchingOutcome = data.outcomes.includes(deal.outcome || '');
        const isNewBusiness = deal.deal_type === 'New Business';

        return isInDateRange && hasTLD && hasMatchingOutcome && isNewBusiness;
      }).sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

      // Transform deals into report rows
      const rows = filteredDeals.map((deal: Deal) => ({
        'Deal Name': deal.domains.join(', '),
        'Deal Stage': deal.deal_stage,
        'Create Date': format(new Date(deal.created_at), 'MM/dd/yyyy'),
        'Deal Type': deal.deal_type,
        'Outcome': deal.outcome || deal.deal_stage,
      }));

      // Create workbook and worksheet
      const wb = utils.book_new();
      const ws = utils.json_to_sheet(rows);

      // Auto-size columns
      const maxLengths = {
        'Deal Name': 40,
        'Deal Stage': 30,
        'Create Date': 12,
        'Deal Type': 15,
        'Outcome': 20,
      };

      ws['!cols'] = Object.values(maxLengths).map((wch) => ({ wch }));

      // Add the worksheet to the workbook
      utils.book_append_sheet(wb, ws, 'Deals');

      // Generate filename
      const selectedTlds = [...new Set(data.tld)].map((t) => t.replace('.', '')).join('_');
      const parts = [
        'deal_outcomes',
        selectedTlds,
        format(data.startDate, 'yyyyMMdd'),
        format(data.endDate, 'yyyyMMdd'),
        format(new Date(), 'yyyyMMdd_HHmm'),
      ];
      const filename = `${parts.join('_')}.xlsx`.toLowerCase();

      // Generate and download the file
      writeFile(wb, filename);

      toast.success('Report generated successfully');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('Failed to generate report');
      }
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Container maxWidth={false} className="pb-12">
      <div className="mb-6">
        <h1 className="text-xl font-bold">Deals Won/Lost Report</h1>
        <p className="text-sm text-muted-foreground">
          Generate a report of deals and their outcomes within a specified date range.
        </p>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleGenerateReport)} className="space-y-8">
          <div className="grid gap-6 md:grid-cols-2">
            <div className="space-y-4">
              <FormField
                control={form.control}
                name="dateRange"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Date Range Preset</FormLabel>
                    <Select
                      value={field.value}
                      onValueChange={handleDateRangeChange}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select date range" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="previous">Previous Month</SelectItem>
                        <SelectItem value="current">Current Month</SelectItem>
                        <SelectItem value="custom">Custom Range</SelectItem>
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />

              <div className="grid gap-4 md:grid-cols-2">
                <FormField
                  control={form.control}
                  name="startDate"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>Start Date</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant="outline"
                              className={cn(
                                'w-full pl-3 text-left font-normal',
                                !field.value && 'text-muted-foreground',
                              )}
                            >
                              {field.value ? format(field.value, 'MM/dd/yyyy') : 'Pick a date'}
                              <CalendarIcon className="ml-auto size-4 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            mode="single"
                            selected={field.value}
                            onSelect={field.onChange}
                            disabled={(date) =>
                              date > new Date() || date > form.getValues('endDate')}
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="endDate"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>End Date</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant="outline"
                              className={cn(
                                'w-full pl-3 text-left font-normal',
                                !field.value && 'text-muted-foreground',
                              )}
                            >
                              {field.value ? format(field.value, 'MM/dd/yyyy') : 'Pick a date'}
                              <CalendarIcon className="ml-auto size-4 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            mode="single"
                            selected={field.value}
                            onSelect={field.onChange}
                            disabled={(date) =>
                              date > new Date() || date < form.getValues('startDate')}
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <FormField
              control={form.control}
              name="tld"
              render={() => (
                <FormItem>
                  <FormLabel>TLD</FormLabel>
                  <div className="space-y-2">
                    {TLDS.map((tld) => (
                      <FormField
                        key={tld.value}
                        control={form.control}
                        name="tld"
                        render={({ field }) => (
                          <FormItem
                            key={tld.value}
                            className="flex flex-row items-start space-x-3 space-y-0"
                          >
                            <FormControl>
                              <Checkbox
                                checked={field.value?.includes(tld.value)}
                                onCheckedChange={(checked) => {
                                  const newValue = checked
                                    ? [...field.value, tld.value]
                                    : field.value?.filter((value) => value !== tld.value);
                                  field.onChange(newValue);
                                }}
                              />
                            </FormControl>
                            <FormLabel className="font-normal">
                              {tld.label}
                            </FormLabel>
                          </FormItem>
                        )}
                      />
                    ))}
                  </div>
                  <FormDescription>
                    Select one or more TLDs to include in the report.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="outcomes"
            render={() => (
              <FormItem>
                <FormLabel>Outcomes to Include</FormLabel>
                <div className="grid gap-2 md:grid-cols-3">
                  <FormField
                    control={form.control}
                    name="outcomes"
                    render={({ field }) => (
                      <FormItem
                        className="flex flex-row items-start space-x-3 space-y-0"
                      >
                        <FormControl>
                          <Checkbox
                            checked={field.value?.includes('')}
                            onCheckedChange={(checked) => {
                              const newValue = checked
                                ? [...field.value, '']
                                : field.value?.filter((value) => value !== '');
                              field.onChange(newValue);
                            }}
                          />
                        </FormControl>
                        <FormLabel className="font-normal">
                          No outcome
                        </FormLabel>
                      </FormItem>
                    )}
                  />
                  {DEAL_OUTCOMES.map((outcome) => (
                    <FormField
                      key={outcome}
                      control={form.control}
                      name="outcomes"
                      render={({ field }) => (
                        <FormItem
                          className="flex flex-row items-start space-x-3 space-y-0"
                        >
                          <FormControl>
                            <Checkbox
                              checked={field.value?.includes(outcome)}
                              onCheckedChange={(checked) => {
                                const newValue = checked
                                  ? [...field.value, outcome]
                                  : field.value?.filter((value) => value !== outcome);
                                field.onChange(newValue);
                              }}
                            />
                          </FormControl>
                          <FormLabel className="font-normal">
                            {outcome}
                          </FormLabel>
                        </FormItem>
                      )}
                    />
                  ))}
                </div>
                <FormDescription>
                  Select which outcomes to include in the report.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            disabled={isGenerating || !form.formState.isValid}
            className="gap-2"
          >
            <Download className="size-4" />
            {isGenerating ? 'Generating...' : 'Generate Report'}
          </Button>
        </form>
      </Form>
    </Container>
  );
}

export default DealsWonLostReport;
