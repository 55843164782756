import { getTimezoneOffset, formatInTimeZone, toDate } from 'date-fns-tz';

// Timezone data structure with IANA identifiers
type TimezoneData = {
  id: string;
  name: string;
  region: string;
  popular?: boolean;
};

// Interface for formatted timezone objects used in UI
export type FormattedTimezone = {
  id: string;
  display: string;
};

// Grouped timezone structure for UI
export type GroupedTimezones = {
  popular: FormattedTimezone[];
  [region: string]: FormattedTimezone[];
};

// List of timezones grouped by region
export const TIMEZONES: TimezoneData[] = [
  // Popular timezones
  { id: 'America/New_York', name: 'Eastern Time (ET)', region: 'North America', popular: true },
  { id: 'America/Chicago', name: 'Central Time (CT)', region: 'North America', popular: true },
  { id: 'America/Denver', name: 'Mountain Time (MT)', region: 'North America', popular: true },
  { id: 'America/Los_Angeles', name: 'Pacific Time (PT)', region: 'North America', popular: true },
  { id: 'Europe/London', name: 'London (GMT/BST)', region: 'Europe', popular: true },
  { id: 'Europe/Paris', name: 'Central European Time (CET)', region: 'Europe', popular: true },
  { id: 'Asia/Shanghai', name: 'China Standard Time (CST)', region: 'Asia', popular: true },
  { id: 'Asia/Tokyo', name: 'Japan Standard Time (JST)', region: 'Asia', popular: true },

  // North America
  { id: 'America/Anchorage', name: 'Alaska (AKST/AKDT)', region: 'North America' },
  { id: 'America/Halifax', name: 'Atlantic Time (Canada)', region: 'North America' },
  { id: 'America/Mexico_City', name: 'Mexico City', region: 'North America' },
  { id: 'America/Phoenix', name: 'Arizona (MST)', region: 'North America' },
  { id: 'America/St_Johns', name: 'Newfoundland (NST/NDT)', region: 'North America' },
  { id: 'America/Toronto', name: 'Toronto (EST/EDT)', region: 'North America' },

  // Central & South America
  { id: 'America/Argentina/Buenos_Aires', name: 'Buenos Aires', region: 'Central & South America' },
  { id: 'America/Bogota', name: 'Bogotá', region: 'Central & South America' },
  { id: 'America/Santiago', name: 'Chile', region: 'Central & South America' },
  { id: 'America/Sao_Paulo', name: 'São Paulo', region: 'Central & South America' },

  // Europe
  { id: 'Europe/Berlin', name: 'Berlin, Frankfurt (CET)', region: 'Europe' },
  { id: 'Europe/Dublin', name: 'Dublin (GMT/IST)', region: 'Europe' },
  { id: 'Europe/Kyiv', name: 'Kyiv (EET/EEST)', region: 'Europe' },
  { id: 'Europe/Moscow', name: 'Moscow, St. Petersburg', region: 'Europe' },

  // Africa
  { id: 'Africa/Cairo', name: 'Cairo (EET)', region: 'Africa' },
  { id: 'Africa/Johannesburg', name: 'Johannesburg (SAST)', region: 'Africa' },
  { id: 'Africa/Lagos', name: 'Lagos (WAT)', region: 'Africa' },
  { id: 'Africa/Nairobi', name: 'Nairobi (EAT)', region: 'Africa' },

  // Asia
  { id: 'Asia/Bangkok', name: 'Bangkok, Vietnam (ICT)', region: 'Asia' },
  { id: 'Asia/Dhaka', name: 'Bangladesh (BDT)', region: 'Asia' },
  { id: 'Asia/Dubai', name: 'Dubai (GST)', region: 'Asia' },
  { id: 'Asia/Hong_Kong', name: 'Hong Kong (HKT)', region: 'Asia' },
  { id: 'Asia/Karachi', name: 'Pakistan (PKT)', region: 'Asia' },
  { id: 'Asia/Kolkata', name: 'India (IST)', region: 'Asia' },
  { id: 'Asia/Riyadh', name: 'Saudi Arabia (AST)', region: 'Asia' },
  { id: 'Asia/Seoul', name: 'Korea (KST)', region: 'Asia' },
  { id: 'Asia/Singapore', name: 'Singapore (SGT)', region: 'Asia' },
  { id: 'Asia/Tehran', name: 'Iran (IRST/IRDT)', region: 'Asia' },
  { id: 'Asia/Taipei', name: 'Taipei (CST)', region: 'Asia' },

  // Oceania
  { id: 'Australia/Adelaide', name: 'Adelaide (ACST/ACDT)', region: 'Oceania' },
  { id: 'Australia/Perth', name: 'Perth (AWST)', region: 'Oceania' },
  { id: 'Australia/Sydney', name: 'Sydney, Melbourne (AEST)', region: 'Oceania' },
  { id: 'Pacific/Auckland', name: 'New Zealand (NZST/NZDT)', region: 'Oceania' },

  // Pacific
  { id: 'Pacific/Honolulu', name: 'Hawaii (HST)', region: 'Pacific' },
  { id: 'Pacific/Kiritimati', name: 'Kiritimati (LINT)', region: 'Pacific' },
  { id: 'Pacific/Midway', name: 'Midway Island, Samoa (SST)', region: 'Pacific' },
  { id: 'Pacific/Tongatapu', name: 'Tonga (TOT)', region: 'Pacific' },

  // UTC
  { id: 'UTC', name: 'Coordinated Universal Time', region: 'UTC' },
];

/**
 * Detects if a date is in Daylight Saving Time for a specific timezone.
 * Works correctly for both Northern and Southern hemispheres.
 *
 * @param date The date to check
 * @param timeZone IANA timezone identifier
 * @returns True if the date is in DST, false otherwise
 */
export function isDaylightSavingTime(date: Date, timeZone: string): boolean {
  // Get the offsets for mid-January and mid-July
  const january = new Date(date.getFullYear(), 0, 15); // January 15th
  const july = new Date(date.getFullYear(), 6, 15); // July 15th

  const januaryOffset = getTimezoneOffset(timeZone, january);
  const julyOffset = getTimezoneOffset(timeZone, july);

  // If January and July have the same offset, this timezone doesn't use DST
  if (januaryOffset === julyOffset) {
    return false;
  }

  // The standard time offset is the more negative one (further from UTC)
  // This works for both Northern and Southern hemispheres
  const standardOffset = Math.min(januaryOffset, julyOffset);

  // Get the offset for the date we're checking
  const dateOffset = getTimezoneOffset(timeZone, date);

  // We're in DST if our offset is greater than the standard offset
  // (closer to UTC than standard time)
  return dateOffset > standardOffset;
}

/**
 * Formats a timezone with offset and DST indicator for display
 * @param timezone Timezone data object
 * @param referenceDate Date to use for calculating the timezone offset
 * @returns Formatted timezone string for display
 */
export function formatTimezone(timezone: TimezoneData, referenceDate: Date = new Date()): string {
  // Get the timezone offset in minutes at the reference date
  const offsetMinutes = getTimezoneOffset(timezone.id, referenceDate) / (60 * 1000);
  const absOffsetMinutes = Math.abs(offsetMinutes);
  const hours = Math.floor(absOffsetMinutes / 60);
  const minutes = absOffsetMinutes % 60;
  const sign = offsetMinutes <= 0 ? '+' : '-'; // Inverted because getTimezoneOffset returns opposite sign

  const formattedOffset = `UTC${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

  // Determine if the reference date is in DST for this timezone
  const isDST = isDaylightSavingTime(referenceDate, timezone.id);

  // Add DST indicator
  return `${formattedOffset} ${timezone.name}${isDST ? ' (DST)' : ''}`;
}

/**
 * Gets the system timezone from browser settings and matches it to our timezone list
 * @param referenceDate Date to use for formatting the timezone
 * @returns Formatted timezone string for the system timezone
 */
export function getSystemTimezone(referenceDate: Date = new Date()): string {
  try {
    // Get the system timezone in IANA format
    const systemTimezoneIANA = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Find the matching timezone from our list
    const matchedTimezone = TIMEZONES.find((tz) => tz.id === systemTimezoneIANA);

    // If no match found, try to find a timezone in the same region
    if (!matchedTimezone) {
      // Extract region from IANA timezone (e.g., "America/New_York" → "America")
      const regionMatch = systemTimezoneIANA.match(/^([^/]+)\//);
      const region = regionMatch ? regionMatch[1] : null;

      if (region) {
        // Try to find a timezone in the same region
        const regionTimezone = TIMEZONES.find((tz) => tz.id.startsWith(`${region}/`));
        if (regionTimezone) {
          return formatTimezone(regionTimezone, referenceDate);
        }
      }

      // If still no match, fall back to a popular timezone
      const popularTimezone = TIMEZONES.find((tz) => tz.popular);
      if (popularTimezone) {
        return formatTimezone(popularTimezone, referenceDate);
      }

      // Last resort fallback to UTC
      return 'UTC+00:00';
    }

    return formatTimezone(matchedTimezone, referenceDate);
  } catch (error) {
    console.error('Error determining system timezone:', error);
    return 'UTC+00:00'; // Safe fallback
  }
}

/**
 * Prepares formatted timezone options for display in UI components
 * @param referenceDate Date to use for calculating offsets and DST
 * @returns Object with popular and other timezones, formatted for display
 */
export function getFormattedTimezones(referenceDate: Date = new Date()): GroupedTimezones {
  // Initialize grouped timezones object with popular category
  const grouped: GroupedTimezones = { popular: [] };

  // First add popular timezones
  for (const tz of TIMEZONES) {
    if (tz.popular) {
      grouped.popular.push({
        id: tz.id,
        display: formatTimezone(tz, referenceDate),
      });
    }
  }

  // Then add region-grouped timezones (excluding those already in popular)
  for (const tz of TIMEZONES) {
    if (!tz.popular) {
      if (!grouped[tz.region]) {
        grouped[tz.region] = [];
      }
      grouped[tz.region].push({
        id: tz.id,
        display: formatTimezone(tz, referenceDate),
      });
    }
  }

  return grouped;
}

/**
 * Converts a date from a specific timezone to a UTC timestamp
 * @param date Local date object
 * @param hours Hours component of the time
 * @param minutes Minutes component of the time
 * @param timezoneId IANA timezone identifier
 * @returns Unix timestamp in seconds, or null if the time is invalid
 */
export function getUnixTimestamp(
  date: Date,
  hours: number,
  minutes: number,
  timezoneId: string,
): number {
  try {
    // Format the date in the target timezone to get correct date components
    const dateInTZ = formatInTimeZone(date, timezoneId, 'yyyy-MM-dd');

    // Create the full datetime string
    const fullDateTimeStr = `${dateInTZ}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00.000`;

    // Convert to a date object in the target timezone
    const scheduledInTZ = toDate(fullDateTimeStr, { timeZone: timezoneId });

    if (scheduledInTZ.toString() === 'Invalid Date') {
      // Handle non-existent time during DST transition
      console.warn('Selected time falls in DST transition gap, adjusting forward by 1 hour');
      const adjustedHours = hours + 1;
      const adjustedTimeStr = `${dateInTZ}T${adjustedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00.000`;
      const adjustedDate = toDate(adjustedTimeStr, { timeZone: timezoneId });

      // If adjusted time is valid, use it
      if (adjustedDate.toString() !== 'Invalid Date') {
        return Math.floor(adjustedDate.getTime() / 1000);
      }
    }

    // If the time is valid, return its timestamp
    if (scheduledInTZ.toString() !== 'Invalid Date') {
      return Math.floor(scheduledInTZ.getTime() / 1000);
    }

    // If all else fails, use current time as fallback
    console.warn('Using current time as fallback');
    return Math.floor(Date.now() / 1000);
  } catch (error) {
    // Log the error but return a fallback value
    console.error('Error converting to Unix timestamp:', error instanceof Error ? error.message : String(error));

    // As a fallback, use the current time
    return Math.floor(Date.now() / 1000);
  }
}

/**
 * Gets the system timezone ID from browser settings
 * @returns IANA timezone identifier, or 'UTC' as fallback
 */
export function getSystemTimezoneId(): string {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
  } catch (error) {
    console.error('Error getting system timezone:', error);
    return 'UTC';
  }
}

/**
 * Finds the timezone data by its formatted display string
 * @param formattedTimezoneStr Formatted timezone string
 * @param formattedTimezones List of formatted timezones
 * @returns The timezone ID or null if not found
 */
export function findTimezoneIdByFormattedString(
  formattedTimezoneStr: string,

  formattedTimezones: GroupedTimezones,
): string | null {
  const allTimezones = Object.values(formattedTimezones).flat();
  const found = allTimezones.find((tz) => tz.display === formattedTimezoneStr);

  if (!found) {
    return null;
  }

  const timezone = TIMEZONES.find((tz) => tz.id === found.id);
  return timezone ? timezone.id : null;
}
