import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useAxios } from './use-axios';

const API_BASE_URL = '/raw-changes';

export const RAW_CHANGES_QUERY_KEY = ['raw-changes'];

interface ApiError {
  error?: {
    message?: string;
    status?: number;
  };
  details?: Array<{ message: string }>;
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested resource was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

export interface RawChange {
  id: string;
  domain: string;
  data: {
    updatedOn: string;
    domainName: string;
    domainRoid: string;
    contactRoid: string;
    registrantFax: string | null;
    domainExpireOn: string;
    domainCreatedOn: string;
    domainUpdatedOn: string;
    registrantEmail: string;
    registrantPhone: string;
    registrantFaxExt: string | null;
    domainSponsorName: string;
    registrantIntCity: string;
    registrantIntName: string;
    registrantLocCity: string | null;
    registrantLocName: string | null;
    registrantIntState: string | null;
    registrantLocState: string | null;
    registrantPhoneExt: string | null;
    domainSponsorIanaId: string;
    serverTransactionId: string;
    registrantIntPostCode: string;
    registrantLocPostCode: string | null;
    registrantIntCountryCode: string;
    registrantLocCountryCode: string | null;
    registrantIntOrganization: string;
    registrantLocOrganization: string | null;
    registrantIntAddressStreet1: string;
    registrantIntAddressStreet2: string | null;
    registrantIntAddressStreet3: string | null;
    registrantLocAddressStreet1: string | null;
    registrantLocAddressStreet2: string | null;
    registrantLocAddressStreet3: string | null;
  };
}

/**
 * Hook to fetch all raw contact changes from the GoDaddy Registry.
 * Returns a react-query result with raw change data.
 *
 * Refreshes automatically every minute while the component is mounted.
 */
export function useRawChanges() {
  const axios = useAxios();

  return useQuery({
    queryKey: RAW_CHANGES_QUERY_KEY,
    queryFn: async () => {
      try {
        const { data } = await axios.get<RawChange[]>(API_BASE_URL);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    // Cache settings
    gcTime: Infinity,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    // Refresh every minute
    refetchInterval: 60 * 1000,
    refetchIntervalInBackground: false,
  });
}
