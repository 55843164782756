import type { ColDef } from '@ag-grid-community/core';
import type { RawChange } from '@/hooks/use-raw-changes';

export const changeColumnDefs: ColDef<RawChange>[] = [
  {
    field: 'data.updatedOn',
    headerName: 'Updated On',
    flex: 0,
    minWidth: 150,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      if (!params.value || Number.isNaN(new Date(params.value).getTime())) {
        return '---';
      }
      return new Date(params.value).toLocaleString();
    },
  },
  {
    field: 'domain',
    headerName: 'Domain',
    flex: 0,
    minWidth: 120,
    sortable: true,
    filter: true,
  },
  {
    field: 'data.registrantIntName',
    headerName: 'Registrant Name',
    flex: 0,
    minWidth: 150,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return params.value || '---';
    },
  },
  {
    field: 'data.registrantIntOrganization',
    headerName: 'Organization',
    flex: 0,
    minWidth: 200,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return params.value || '---';
    },
  },
  {
    field: 'data.registrantEmail',
    headerName: 'Email',
    flex: 0,
    minWidth: 150,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return params.value || '---';
    },
  },
  {
    field: 'data.registrantPhone',
    headerName: 'Phone',
    flex: 0,
    minWidth: 120,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return params.value || '---';
    },
  },
  {
    field: 'data.registrantIntCity',
    headerName: 'City',
    flex: 0,
    minWidth: 100,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return params.value || '---';
    },
  },
  {
    field: 'data.registrantIntState',
    headerName: 'State',
    flex: 0,
    minWidth: 80,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return params.value || '---';
    },
  },
  {
    field: 'data.registrantIntCountryCode',
    headerName: 'Country',
    flex: 0,
    minWidth: 80,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return params.value || '---';
    },
  },
  {
    field: 'data.domainSponsorName',
    headerName: 'Registrar',
    flex: 0,
    minWidth: 200,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return params.value || '---';
    },
  },
  {
    field: 'data.domainSponsorIanaId',
    headerName: 'IANA ID',
    flex: 0,
    minWidth: 80,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return params.value || '---';
    },
  },
];
