import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import type {
  Contact,
  CreateContactInput,
  UpdateContactInput,
  DomainAssociation,
  CreateDomainAssociationInput,
  ContactLicense,
  CreateContactLicenseInput,
} from '@/types/contact';
import { useAxios } from './use-axios';

const API_BASE_URL = '/contacts';

export const CONTACTS_QUERY_KEY = ['contacts'];

type ApiError = {
  error?: string | {
    message?: string;
    status?: number;
  };
  details?: Array<{ message: string }> | string;
};

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle invalid domain associations error
    if (
      error.response?.status === 400 && 
      error.response?.data?.error === "Invalid domain associations" && 
      typeof error.response?.data?.details === "string"
    ) {
      return error.response.data.details;
    }
    
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested resource was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

// List all contacts
export function useContacts() {
  const axios = useAxios();

  return useQuery({
    queryKey: CONTACTS_QUERY_KEY,
    queryFn: async () => {
      try {
        const { data } = await axios.get<Contact[]>(API_BASE_URL);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });
}

// Get a single contact by ID
export function useContact(id: string) {
  const axios = useAxios();

  return useQuery({
    queryKey: [...CONTACTS_QUERY_KEY, id],
    queryFn: async () => {
      try {
        const { data } = await axios.get<Contact>(`${API_BASE_URL}/${id}`);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: !!id,
  });
}

// Create a new contact
export function useCreateContact() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (input: CreateContactInput) => {
      try {
        const { data } = await axios.post<Contact>(API_BASE_URL, input);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY });
    },
  });
}

// Update a contact
export function useUpdateContact() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, ...input }: { id: string } & UpdateContactInput) => {
      try {
        const { data } = await axios.put<Contact>(`${API_BASE_URL}/${id}`, input);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { id }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...CONTACTS_QUERY_KEY, id] }),
        queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY }),
      ]);
    },
  });
}

// Delete a contact
export function useDeleteContact() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      try {
        await axios.delete(`${API_BASE_URL}/${id}`);
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, id) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...CONTACTS_QUERY_KEY, id] }),
        queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY }),
      ]);
    },
  });
}

// Add a domain association
export function useAddDomainAssociation() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ contactId, ...input }: { contactId: string } & CreateDomainAssociationInput) => {
      try {
        await axios.post(`${API_BASE_URL}/${contactId}/domains`, input);
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { contactId }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...CONTACTS_QUERY_KEY, contactId] }),
        queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY }),
      ]);
    },
  });
}

// Remove a domain association
export function useRemoveDomainAssociation() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ contactId, domain }: { contactId: string; domain: string }) => {
      try {
        await axios.delete(`${API_BASE_URL}/${contactId}/domains/${domain}`);
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { contactId }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...CONTACTS_QUERY_KEY, contactId] }),
        queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY }),
      ]);
    },
  });
}

// Update a domain association
export function useUpdateDomainAssociation() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ contactId, domain, type }: { contactId: string } & CreateDomainAssociationInput) => {
      try {
        await axios.put(`${API_BASE_URL}/${contactId}/domains/${domain}`, { type });
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { contactId }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...CONTACTS_QUERY_KEY, contactId] }),
        queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY }),
      ]);
    },
  });
}

// Get contact's domain associations
export function useContactDomains(contactId: string) {
  const axios = useAxios();

  return useQuery({
    queryKey: [...CONTACTS_QUERY_KEY, contactId, 'domains'],
    queryFn: async () => {
      try {
        const { data } = await axios.get<DomainAssociation[]>(`${API_BASE_URL}/${contactId}/domains`);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: !!contactId,
  });
}

// Add a license
export function useAddLicense() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ contactId, ...input }: { contactId: string } & CreateContactLicenseInput) => {
      try {
        const { data } = await axios.post<ContactLicense>(`${API_BASE_URL}/${contactId}/licenses`, input);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { contactId }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...CONTACTS_QUERY_KEY, contactId] }),
        queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY }),
      ]);
    },
  });
}

// Remove a license
export function useRemoveLicense() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ contactId, licenseId }: { contactId: string; licenseId: string }) => {
      try {
        await axios.delete(`${API_BASE_URL}/${contactId}/licenses/${licenseId}`);
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { contactId }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...CONTACTS_QUERY_KEY, contactId] }),
        queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY }),
      ]);
    },
  });
}

// Update a license
export function useUpdateLicense() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      contactId,
      licenseId,
      ...input
    }: { contactId: string; licenseId: string } & CreateContactLicenseInput) => {
      try {
        const { data } = await axios.put<ContactLicense>(
          `${API_BASE_URL}/${contactId}/licenses/${licenseId}`,
          input,
        );
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { contactId }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...CONTACTS_QUERY_KEY, contactId] }),
        queryClient.invalidateQueries({ queryKey: CONTACTS_QUERY_KEY }),
      ]);
    },
  });
}

export { type Contact, type CreateContactInput, type UpdateContactInput } from '@/types/contact';
