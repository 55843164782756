import React, { useState } from 'react';
import { Clock } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';

export type TimePickerProps = {
  time: string;
  timeFormat: 'AM' | 'PM';
  onTimeChange: (time: string) => void;
  onFormatChange: (format: 'AM' | 'PM') => void;
};

export function TimePicker({ time, timeFormat, onTimeChange, onFormatChange }: TimePickerProps) {
  const [open, setOpen] = useState(false);

  // Formats time input to valid HH:MM format
  const formatTimeInput = (value: string): string => {
    // Empty check
    if (!value) return '';

    // Already valid format
    if (/^(?:[01]?\d|2[0-3]):[0-5]\d$/.test(value)) return value;

    try {
      // Handle cases like "3" -> "03:00"
      if (/^\d{1,2}$/.test(value)) {
        const hour = parseInt(value, 10);
        if (hour >= 0 && hour <= 23) {
          return `${hour.toString().padStart(2, '0')}:00`;
        }
      }

      // Handle "3:" -> "03:00"
      if (/^\d{1,2}:$/.test(value)) {
        const hour = parseInt(value.slice(0, -1), 10);
        if (hour >= 0 && hour <= 23) {
          return `${hour.toString().padStart(2, '0')}:00`;
        }
      }

      // Handle "3:3" -> "03:30"
      if (/^\d{1,2}:\d{1}$/.test(value)) {
        const [hourStr, minStr] = value.split(':');
        const hour = parseInt(hourStr, 10);
        const min = parseInt(minStr, 10) * 10; // Convert single digit to tens

        if (hour >= 0 && hour <= 23 && min >= 0 && min <= 50) {
          return `${hour.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`;
        }
      }
    } catch (err) {
      // Just return what they typed if parsing fails
      console.log('Time parse error:', err);
      return value;
    }

    // fallback
    return value;
  };

  function handleTimeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const val = e.target.value;
    // Allow partial inputs while typing
    if (/^(?:[01]?\d|2[0-3])(?::[0-5]?\d?)?$/.test(val) || val === '') {
      onTimeChange(val);
    }
  }

  // Fix time format when user leaves the field
  const handleTimeBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onTimeChange(formatTimeInput(e.target.value));
  };

  // Handle Enter key press
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onTimeChange(formatTimeInput(e.currentTarget.value));
      setOpen(false);
    }
  };

  // Quick time selection buttons
  const handlePresetTimeClick = (hour: string) => {
    onTimeChange(`${hour}:00`);
    setOpen(false);
  };

  // AM/PM toggle
  const toggleTimeFormat = () => {
    onFormatChange(timeFormat === 'AM' ? 'PM' : 'AM');
  };

  // Preset hours for quick selection buttons
  const row1Hours = ['12', '01', '02', '03'];
  const row2Hours = ['04', '05', '06', '07'];
  const row3Hours = ['08', '09', '10', '11'];

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          id="time"
          variant="outline"
          className="w-full justify-start text-left font-normal"
        >
          <Clock className="mr-2 size-4" />
          {time || '08:00'} {timeFormat}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-4">
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <Input
              type="text"
              placeholder="HH:MM"
              value={time}
              onChange={handleTimeChange}
              onBlur={handleTimeBlur}
              onKeyDown={handleKeyDown}
              className="w-20"
            />
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={toggleTimeFormat}
            >
              {timeFormat}
            </Button>
          </div>

          {/* Quick selection time grid */}
          <div className="grid grid-cols-4 gap-2 pt-2">
            {row1Hours.map((hour) => (
              <Button
                key={hour}
                variant="outline"
                size="sm"
                onClick={() => handlePresetTimeClick(hour)}
                className="text-xs"
              >
                {hour}:00
              </Button>
            ))}
            {row2Hours.map((hour) => (
              <Button
                key={hour}
                variant="outline"
                size="sm"
                onClick={() => handlePresetTimeClick(hour)}
                className="text-xs"
              >
                {hour}:00
              </Button>
            ))}
            {row3Hours.map((hour) => (
              <Button
                key={hour}
                variant="outline"
                size="sm"
                onClick={() => handlePresetTimeClick(hour)}
                className="text-xs"
              >
                {hour}:00
              </Button>
            ))}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
