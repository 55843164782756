import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import type { VerificationItem, OFACCheck, FdicCheck, SignableNote } from '@/components/verification-document/types';
import { useAxios } from './use-axios';
import { usePersistedState } from './use-persisted-state';

const API_BASE_URL = '/deals';

// Function to create default verification items for a deal
export function createDefaultItems(deal: Deal): VerificationItem[] {
  const ofacChecks: OFACCheck[] = [];
  const fdicChecks: FdicCheck[] = [];
  // Keep track of entity names already added to OFAC checks
  const existingOfacEntities = new Set<string>();

  // Add OFAC checks for people and their organizations
  if (deal.registrants.people?.length > 0) {
    for (const person of deal.registrants.people) {
      // Add OFAC check for person if not already added
      if (!existingOfacEntities.has(person.name)) {
        ofacChecks.push({
          id: `ofac-${person.email}`,
          type: 'ofac',
          entityName: person.name,
          searchResults: null,
          verified: false,
          signatures: [],
        });
        existingOfacEntities.add(person.name);
      }

      // If person has organization name, add OFAC check for org if not already added
      if (person.organization_name && !existingOfacEntities.has(person.organization_name)) {
        ofacChecks.push({
          id: `ofac-org-${person.organization_name}`,
          type: 'ofac',
          entityName: person.organization_name,
          searchResults: null,
          verified: false,
          signatures: [],
        });
        existingOfacEntities.add(person.organization_name);

        // If org has FDIC license, add FDIC check
        const fdicLicense = person.organization_licenses?.find(
          (license) => license.authority === 'FDIC',
        );
        if (fdicLicense) {
          fdicChecks.push({
            id: `fdic-org-${person.organization_name}`,
            type: 'fdic',
            entityName: person.organization_name,
            certNumber: fdicLicense.value,
            searchResults: null,
            verified: false,
            signatures: [],
          });
        }
      }
    }
  }

  const notes: SignableNote[] = [
    {
      id: 'org-eligibility',
      type: 'note',
      title: 'Organization Eligibility',
      content: '',
      signatures: [],
    },
    {
      id: 'email-eligibility',
      type: 'note',
      title: 'Email Eligibility',
      content: '',
      signatures: [],
    },
    {
      id: 'org-address',
      type: 'note',
      title: 'Organization Address',
      content: '',
      signatures: [],
    },
    {
      id: 'name-selection',
      type: 'note',
      title: 'Name Selection',
      content: '',
      signatures: [],
    },
    {
      id: 'employment-authority',
      type: 'note',
      title: 'Employment Authority',
      content: '',
      signatures: [],
    },
    {
      id: 'alt-contacts',
      type: 'note',
      title: 'Alt Contacts',
      content: '',
      signatures: [],
    },
  ];

  return [...ofacChecks, ...fdicChecks, ...notes];
}

// Function to ensure required checks are present
export function ensureRequiredChecks(deal: Deal, existingItems: VerificationItem[]): VerificationItem[] {
  // Check if there's a Marketing Information note
  const hasMarketingInfo = existingItems.some(
    (item) => item.type === 'note' && item.title === 'Marketing Information',
  );

  // Check if there's a Note item
  const hasNote = existingItems.some(
    (item) => item.type === 'note' && item.title.toLowerCase() === 'note',
  );

  // Create items to add if needed
  const itemsToAdd: VerificationItem[] = [];

  // Create a default note if one doesn't exist
  if (!hasNote) {
    itemsToAdd.push({
      id: 'verification-note',
      type: 'note',
      title: 'Note',
      content: '',
      signatures: [],
    });
  }

  // If any items need to be added, add them in the correct order
  if (itemsToAdd.length > 0) {
    // If there's a Marketing Information note, find its index and insert after it
    if (hasMarketingInfo) {
      const marketingIndex = existingItems.findIndex(
        (item) => item.type === 'note' && item.title === 'Marketing Information',
      );
      existingItems = [
        ...existingItems.slice(0, marketingIndex + 1),
        ...itemsToAdd,
        ...existingItems.slice(marketingIndex + 1),
      ];
    } else {
      // If no Marketing Information, just add at the beginning
      existingItems = [...itemsToAdd, ...existingItems];
    }
  }

  // If any OFAC or FDIC checks exist, return items unchanged
  if (existingItems.some((item) => item.type === 'ofac' || item.type === 'fdic')) {
    return existingItems;
  }

  // Generate required checks
  const newChecks: VerificationItem[] = [];
  // Keep track of entity names already added to OFAC checks
  const existingOfacEntities = new Set<string>();

  // Add OFAC checks for people and their organizations
  if (deal.registrants.people?.length > 0) {
    for (const person of deal.registrants.people) {
      // Add OFAC check for person if not already added
      if (!existingOfacEntities.has(person.name)) {
        newChecks.push({
          id: `ofac-${person.email}`,
          type: 'ofac',
          entityName: person.name,
          searchResults: null,
          verified: false,
          signatures: [],
        });
        existingOfacEntities.add(person.name);
      }

      // If person has organization name, add OFAC check for org if not already added
      if (person.organization_name && !existingOfacEntities.has(person.organization_name)) {
        newChecks.push({
          id: `ofac-org-${person.organization_name}`,
          type: 'ofac',
          entityName: person.organization_name,
          searchResults: null,
          verified: false,
          signatures: [],
        });
        existingOfacEntities.add(person.organization_name);

        // If org has FDIC license, add FDIC check
        const fdicLicense = person.organization_licenses?.find(
          (license) => license.authority === 'FDIC',
        );
        if (fdicLicense) {
          newChecks.push({
            id: `fdic-org-${person.organization_name}`,
            type: 'fdic',
            entityName: person.organization_name,
            certNumber: fdicLicense.value,
            searchResults: null,
            verified: false,
            signatures: [],
          });
        }
      }
    }
  }

  // Find the index after Marketing Info (if it exists) or Note
  const insertIndex = hasMarketingInfo
    ? existingItems.findIndex((item) => item.type === 'note' && item.title === 'Marketing Information') + 1
    : existingItems.findIndex((item) => item.type === 'note' && item.title.toLowerCase() === 'note') + 1;

  // Insert checks after Marketing Info or Note
  return [
    ...existingItems.slice(0, insertIndex),
    ...newChecks,
    ...existingItems.slice(insertIndex),
  ];
}

// Function to enforce specific ordering of verification items
export function enforceVerificationOrder(items: VerificationItem[]): VerificationItem[] {
  // Create a new array to store items in the correct order
  const orderedItems: VerificationItem[] = [];
  const remainingItems = [...items];

  // Helper function to find and move an item
  const findAndMove = (predicate: (item: VerificationItem) => boolean): VerificationItem | undefined => {
    const index = remainingItems.findIndex((item) => predicate(item));
    if (index !== -1) {
      const [item] = remainingItems.splice(index, 1);
      return item;
    }
    return undefined;
  };

  // 0. Find and move "Marketing Information" to the very top
  const isMarketingInfo = (item: VerificationItem) =>
    item.type === 'note' && item.title === 'Marketing Information';
  const marketingInfoItem = findAndMove(isMarketingInfo);
  if (marketingInfoItem)
    orderedItems.push(marketingInfoItem);

  // 1. Find and move "Note" below Marketing Information
  const isNote = (item: VerificationItem) =>
    item.type === 'note' && item.title.toLowerCase() === 'note';
  const noteItem = findAndMove(isNote);
  if (noteItem)
    orderedItems.push(noteItem);

  // 2. Find and move "Additional Notes" below "Note"
  const isAdditionalNotes = (item: VerificationItem) =>
    item.type === 'note' && item.title.toLowerCase() === 'additional notes';
  const additionalNotesItem = findAndMove(isAdditionalNotes);
  if (additionalNotesItem)
    orderedItems.push(additionalNotesItem);

  // 3. Find and move "Forced Verification Note" below other top notes
  const isForcedVerificationNote = (item: VerificationItem) =>
    item.type === 'note' && item.title === 'Forced Verification Note';
  const forcedVerificationNoteItem = findAndMove(isForcedVerificationNote);
  if (forcedVerificationNoteItem)
    orderedItems.push(forcedVerificationNoteItem);

  // 4. Find and move OFAC org checks before OFAC person checks
  const ofacOrgChecks = remainingItems.filter((item) =>
    item.type === 'ofac' && item.id.includes('ofac-org-'),
  );
  const ofacPersonChecks = remainingItems.filter((item) =>
    item.type === 'ofac' && !item.id.includes('ofac-org-'),
  );
  remainingItems.splice(0, remainingItems.length, ...remainingItems.filter((item) =>
    item.type !== 'ofac',
  ));
  orderedItems.push(...ofacOrgChecks, ...ofacPersonChecks);

  // 5. Find and move FDIC check above Organization Eligibility
  const isFdicCheck = (item: VerificationItem) => item.type === 'fdic';
  const fdicChecks = findAndMove(isFdicCheck);

  const orgEligibilityIndex = remainingItems.findIndex((item) =>
    item.type === 'note' && item.title === 'Organization Eligibility',
  );

  if (orgEligibilityIndex !== -1) {
    if (fdicChecks) {
      orderedItems.push(fdicChecks);
    }
    orderedItems.push(remainingItems[orgEligibilityIndex]);
    remainingItems.splice(orgEligibilityIndex, 1);
  } else if (fdicChecks) {
    orderedItems.push(fdicChecks);
  }

  // 6. Find and move Email Eligibility
  const emailEligibilityIndex = remainingItems.findIndex((item) =>
    item.type === 'note' && item.title === 'Email Eligibility',
  );
  if (emailEligibilityIndex !== -1) {
    orderedItems.push(remainingItems[emailEligibilityIndex]);
    remainingItems.splice(emailEligibilityIndex, 1);
  }

  // 7. Find and move Organization Address
  const orgAddressIndex = remainingItems.findIndex((item) =>
    item.type === 'note' && item.title === 'Organization Address',
  );
  if (orgAddressIndex !== -1) {
    orderedItems.push(remainingItems[orgAddressIndex]);
    remainingItems.splice(orgAddressIndex, 1);
  }

  // 8. Find Name Selection and any trademark notes
  const nameSelectionIndex = remainingItems.findIndex((item) =>
    item.type === 'note' && item.title === 'Name Selection',
  );

  if (nameSelectionIndex !== -1) {
    orderedItems.push(remainingItems[nameSelectionIndex]);
    remainingItems.splice(nameSelectionIndex, 1);

    // Find and move trademark notes below Name Selection
    const trademarkNotes = remainingItems.filter((item) =>
      item.type === 'note' && item.title.toLowerCase().includes('trademark'),
    );
    remainingItems.splice(0, remainingItems.length, ...remainingItems.filter((item) =>
      !(item.type === 'note' && item.title.toLowerCase().includes('trademark')),
    ));
    orderedItems.push(...trademarkNotes);

    // Find and move use restriction notes below trademark (or Name Selection if no trademark)
    const useRestrictionNotes = remainingItems.filter((item) =>
      item.type === 'note' && item.title.toLowerCase().includes('use restriction'),
    );
    remainingItems.splice(0, remainingItems.length, ...remainingItems.filter((item) =>
      !(item.type === 'note' && item.title.toLowerCase().includes('use restriction')),
    ));
    orderedItems.push(...useRestrictionNotes);
  }

  // 9. Find and move Employment Authority
  const employmentAuthorityIndex = remainingItems.findIndex((item) =>
    item.type === 'note' && item.title === 'Employment Authority',
  );
  if (employmentAuthorityIndex !== -1) {
    orderedItems.push(remainingItems[employmentAuthorityIndex]);
    remainingItems.splice(employmentAuthorityIndex, 1);
  }

  // Add remaining items in their current order
  orderedItems.push(...remainingItems);

  // Ensure Alt Contacts exists and is at the bottom
  const hasAltContacts = orderedItems.some((item) =>
    item.type === 'note' && item.title === 'Alt Contacts',
  );

  if (!hasAltContacts) {
    orderedItems.push({
      id: 'alt-contacts',
      type: 'note',
      title: 'Alt Contacts',
      content: '',
      signatures: [],
    });
  }

  return orderedItems;
}

export const DEALS_QUERY_KEY = ['deals'];

interface ApiError {
  error?: {
    message?: string;
    status?: number;
  };
  details?: Array<{ message: string }>;
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested resource was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

export type DealType =
  | 'New Business'
  | 'Existing Business'
  | 'Annual Verification'
  | 'Forced Verification'
  | string; // Allow for future additions

export type DealOutcome =
  | 'Approved'
  | 'Combined'
  | 'Consolidation'
  | 'Did not Register'
  | 'Domain Deleted'
  | 'Duplicate'
  | 'fTLD Domain'
  | 'Incomplete'
  | 'Ineligible'
  | 'Junk'
  | 'Name Selection'
  | 'No Renew'
  | 'No Reply'
  | 'No Verification Needed'
  | 'Not Wanted'
  | 'Organization'
  | 'Price'
  | 'Test'
  | string; // Allow for future additions

export type DealStage =
  | 'Annual Verification'
  | 'Pending Verification'
  | 'Organization Verification in Progress'
  | 'Name Selection in Progress'
  | 'Employment Verification in Progress'
  | 'WHOIS Update in Progress'
  | 'Failed Verification'
  | 'Hold'
  | 'Verification Completed'
  | 'Verified Data Sent'
  | 'Completed'
  | string; // Allow for future additions

interface License {
  authority: string;
  value: string;
  contact_ids: string[];
  license_ids: string[];
}

interface Person {
  name: string;
  legal_name: string;
  alt_name: string | null;
  email: string;
  alt_email: string | null;
  phone_number: string;
  alt_phone: string | null;
  fax_number: string | null;
  address_line_1: string;
  address_line_2: string | null;
  address_line_3: string | null;
  city: string;
  state_province: string;
  postal_code: string;
  country: string;
  title: string;
  organization_name: string;
  c_suite: boolean;
  sole_prop: boolean;
  new_legal_name: boolean;
  contact_ids: string[];
  associated_domains: string[];
  organization_licenses?: License[];
}

interface VerificationContact extends Person {
  organization_name: string;
}

export interface Deal {
  id: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  verification_document: {
    body: string;
  } | null;
  deal_stage: DealStage;
  deal_type: DealType;
  outcome: DealOutcome | null;
  assigned_to: string;
  domains: string[];
  archived_at: string | null;
  partner_id: string | null;
  partner: {
    name: string;
    email: string;
    iana_id: string;
  } | null;
  registrants: {
    people: Person[];
  };
  verification_contacts: {
    people: VerificationContact[];
  };
}

export interface CreateDealInput {
  verification_document?: {
    body: string;
  } | null;
  deal_stage: DealStage;
  deal_type?: DealType;
  outcome?: DealOutcome | null;
  assigned_to: string;
  domains: string[];
}

export interface UpdateDealInput {
  verification_document?: {
    body: string;
  } | null;
  deal_stage?: DealStage;
  deal_type?: DealType;
  outcome?: DealOutcome | null;
  assigned_to?: string;
  domains?: string[];
  archived_at?: string | null;
  partner_id?: string | null;
  registrants?: {
    people: Array<{
      name: string;
      legal_name: string;
      alt_name: string | null;
      email: string;
      alt_email: string | null;
      phone_number: string;
      alt_phone: string | null;
      fax_number: string | null;
      address_line_1: string;
      address_line_2: string | null;
      address_line_3: string | null;
      city: string;
      state_province: string;
      postal_code: string;
      country: string;
      title: string;
      c_suite: boolean;
      sole_prop: boolean;
      new_legal_name: boolean;
      contact_ids: string[];
    }>;
    organizations: Array<{
      name: string;
      email: string;
      phone_number: string;
      alt_phone: string | null;
      fax_number: string | null;
      address_line_1: string;
      address_line_2: string | null;
      address_line_3: string | null;
      city: string;
      state_province: string;
      postal_code: string;
      country: string;
      contact_ids: string[];
      licenses: Array<{
        authority: string;
        value: string;
        contact_ids: string[];
        license_ids: string[];
      }>;
    }>;
  };
  verification_contacts?: {
    people: Array<{
      name: string;
      legal_name: string;
      alt_name: string | null;
      email: string;
      alt_email: string | null;
      phone_number: string;
      alt_phone: string | null;
      fax_number: string | null;
      address_line_1: string;
      address_line_2: string | null;
      address_line_3: string | null;
      city: string;
      state_province: string;
      postal_code: string;
      country: string;
      title: string;
      c_suite: boolean;
      sole_prop: boolean;
      new_legal_name: boolean;
      organization_name: string;
      contact_ids: string[];
    }>;
  };
}

// Type for minimal deal data to store in localStorage
interface MinimalDeal {
  id: string;
  deal_stage: DealStage;
  deal_type: DealType;
  outcome: DealOutcome | null;
  assigned_to: string;
  domains: string[];
  archived_at: string | null;
  created_at: string;
  updated_at: string;
}

function dealToMinimal(deal: Deal): MinimalDeal {
  return {
    id: deal.id,
    deal_stage: deal.deal_stage,
    deal_type: deal.deal_type,
    outcome: deal.outcome,
    assigned_to: deal.assigned_to,
    domains: deal.domains,
    archived_at: deal.archived_at,
    created_at: deal.created_at,
    updated_at: deal.updated_at,
  };
}

// List all deals
export function useDeals() {
  const axios = useAxios();
  const [persistedDeals, setPersistedDeals] = usePersistedState<MinimalDeal[]>('cached-deals', []);

  return useQuery({
    queryKey: DEALS_QUERY_KEY,
    queryFn: async () => {
      try {
        const { data } = await axios.get<Deal[]>(API_BASE_URL);
        // Filter out archived deals (where archived_at is truthy)
        const filteredDeals = data.filter((deal) => !deal.archived_at);
        // Update persisted deals with minimal data
        setPersistedDeals(filteredDeals.map((deal) => dealToMinimal(deal)));
        return filteredDeals;
      } catch (error) {
        // On error, return persisted deals if available
        if (persistedDeals.length > 0) {
          return persistedDeals as Deal[];
        }
        throw new Error(getErrorMessage(error));
      }
    },
    // Keep data cached indefinitely
    gcTime: Infinity,
    // Always refetch in background when component mounts
    refetchOnMount: 'always',
    // Disable window focus refetching since we have interval
    refetchOnWindowFocus: false,
    // Refetch every 2 minutes while page is visible
    refetchInterval: 2 * 60 * 1000,
    refetchIntervalInBackground: false,
    // Initialize with persisted data
    initialData: persistedDeals as Deal[],
  });
}

// Get a single deal by ID
export function useDeal(id: string) {
  const axios = useAxios();

  return useQuery({
    queryKey: [...DEALS_QUERY_KEY, id],
    queryFn: async () => {
      try {
        const { data } = await axios.get<Deal>(`${API_BASE_URL}/${id}`);

        // Apply verification document guarantees
        if (data.verification_document?.body) {
          try {
            const savedItems = JSON.parse(data.verification_document.body);
            if (Array.isArray(savedItems) && savedItems.length > 0) {
              // Apply guarantees and update if needed
              const orderedItems = enforceVerificationOrder(
                ensureRequiredChecks(data, savedItems as VerificationItem[]),
              );

              // If items were modified, save the changes
              if (JSON.stringify(orderedItems) !== JSON.stringify(savedItems)) {
                await axios.put<Deal>(`${API_BASE_URL}/${id}`, {
                  verification_document: {
                    body: JSON.stringify(orderedItems),
                  },
                });
              }

              // Return deal with updated verification document
              return {
                ...data,
                verification_document: {
                  body: JSON.stringify(orderedItems),
                },
              };
            }
          } catch {
            // If parsing fails, continue to create default items
          }
        }

        // Create default items if verification_document is empty, null, or invalid
        const defaultItems = enforceVerificationOrder(createDefaultItems(data));

        // Save default items immediately
        if (!data.verification_document?.body) {
          await axios.put<Deal>(`${API_BASE_URL}/${id}`, {
            verification_document: {
              body: JSON.stringify(defaultItems),
            },
          });
        }

        // Return deal with default verification document
        return {
          ...data,
          verification_document: {
            body: JSON.stringify(defaultItems),
          },
        };
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: !!id,
    // Keep data cached indefinitely
    gcTime: Infinity,
    // Always refetch in background when component mounts
    refetchOnMount: 'always',
    // Disable window focus refetching since we have interval
    refetchOnWindowFocus: false,
    // Refetch every 2 minutes while page is visible
    refetchInterval: 2 * 60 * 1000,
    refetchIntervalInBackground: false,
  });
}

// Create a new deal
export function useCreateDeal() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (input: CreateDealInput) => {
      try {
        const { data } = await axios.post<Deal>(API_BASE_URL, input);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: DEALS_QUERY_KEY });
    },
  });
}

// Update a deal
export function useUpdateDeal() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, ...input }: { id: string } & UpdateDealInput) => {
      try {
        // If updating verification document, apply guarantees
        if (input.verification_document?.body) {
          try {
            // Get current deal to ensure guarantees are applied with latest data
            const { data: currentDeal } = await axios.get<Deal>(`${API_BASE_URL}/${id}`);

            const savedItems = JSON.parse(input.verification_document.body);
            if (Array.isArray(savedItems)) {
              // Apply guarantees
              const orderedItems = enforceVerificationOrder(
                ensureRequiredChecks(currentDeal, savedItems as VerificationItem[]),
              );

              // Update input with guaranteed items
              input = {
                ...input,
                verification_document: {
                  body: JSON.stringify(orderedItems),
                },
              };
            }
          } catch {
            // If parsing fails, continue with original input
          }
        }

        const { data } = await axios.put<Deal>(`${API_BASE_URL}/${id}`, input);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { id }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...DEALS_QUERY_KEY, id] }),
        queryClient.invalidateQueries({ queryKey: DEALS_QUERY_KEY }),
      ]);
    },
  });
}
