import * as React from "react";
import * as RPNInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";

type PhoneInputProps = Omit<
  React.ComponentProps<"input">,
  "onChange" | "value" | "ref"
> &
  Omit<RPNInput.Props<typeof RPNInput.default>, "onChange"> & {
    onChange?: (value: RPNInput.Value) => void;
  };

const PhoneInput: React.ForwardRefExoticComponent<PhoneInputProps> =
  React.forwardRef<React.ElementRef<typeof RPNInput.default>, PhoneInputProps>(
    ({ className, onChange, defaultCountry, ...props }, ref) => {
      // Track the selected country to prevent resetting when phone number is cleared
      const [currentCountry, setCurrentCountry] = React.useState<RPNInput.Country>(defaultCountry as RPNInput.Country || 'US');

      return (
        <RPNInput.default
          ref={ref}
          className={cn("flex", className)}
          flagComponent={FlagComponent}
          countrySelectComponent={(countrySelectProps) => (
            <CountrySelect
              {...countrySelectProps}
              onCountryChange={setCurrentCountry}
              currentCountry={currentCountry}
            />
          )}
          inputComponent={InputComponent}
          smartCaret={false}
          country={currentCountry}
          defaultCountry={defaultCountry}
          /**
           * Handles the onChange event.
           *
           * react-phone-number-input might trigger the onChange event as undefined
           * when a valid phone number is not entered. To prevent this,
           * the value is coerced to an empty string.
           *
           * @param {E164Number | undefined} value - The entered value
           */
          onChange={(value) => {
            // Preserve country selection even when value is empty
            if (!value) {
              onChange?.("" as RPNInput.Value);
            } else {
              onChange?.(value);
            }
          }}
          {...props}
        />
      );
    },
  );
PhoneInput.displayName = "PhoneInput";

const InputComponent = React.forwardRef<
  HTMLInputElement,
  React.ComponentProps<"input">
>(({ className, ...props }, ref) => (
  <Input
    className={cn("rounded-e-lg rounded-s-none flex-1", className)}
    {...props}
    ref={ref}
    style={{ height: '40px' }}
  />
));
InputComponent.displayName = "InputComponent";

type CountryEntry = { label: string; value: RPNInput.Country | undefined };

type CountrySelectProps = {
  disabled?: boolean;
  value: RPNInput.Country;
  options: CountryEntry[];
  onChange: (country: RPNInput.Country) => void;
  onCountryChange?: (country: RPNInput.Country) => void;
  currentCountry?: RPNInput.Country;
};

const CountrySelect = ({
  disabled,
  value: selectedCountry,
  options: countryList,
  onChange,
  onCountryChange,
  currentCountry,
}: CountrySelectProps) => {
  // Create a ref to manually open/close the select
  const selectRef = React.useRef<HTMLSelectElement>(null);
  
  // Track selected country in local state to ensure UI updates immediately
  const [localCountry, setLocalCountry] = React.useState<RPNInput.Country>(currentCountry || selectedCountry);

  // Update local state when prop changes
  React.useEffect(() => {
    setLocalCountry(currentCountry || selectedCountry);
  }, [currentCountry, selectedCountry]);

  // Handle country selection
  const handleCountryChange = (newCountry: RPNInput.Country) => {
    setLocalCountry(newCountry);
    onChange(newCountry);
    onCountryChange?.(newCountry);
  };
  
  return (
    <div className="relative w-[80px]">
      {/* Invisible select - positioned behind our custom UI */}
      <select
        ref={selectRef}
        disabled={disabled}
        value={localCountry}
        onChange={(e) => handleCountryChange(e.target.value as RPNInput.Country)}
        className={cn(
          "absolute opacity-0", // Make it invisible but functional
          "inset-0 w-full h-10",
          "appearance-none cursor-pointer",
          disabled && "pointer-events-none"
        )}
        aria-label="Select country"
      >
        {countryList.map(({ value, label }) =>
          value ? (
            <option key={value} value={value}>
              {label} (+{RPNInput.getCountryCallingCode(value)})
            </option>
          ) : null
        )}
      </select>

      {/* Custom UI - looks like a form control but doesn't receive events */}
      <div 
        className={cn(
          "h-10 w-full",
          "rounded-s-lg rounded-e-none border border-r-0 border-input bg-background",
          "flex items-center",
          "pl-9 pr-4",
          disabled && "opacity-50"
        )}
        onClick={() => selectRef.current?.click()}
      >
        {/* Flag */}
        <div className="absolute left-2.5 flex items-center">
          <FlagComponent country={localCountry} countryName={localCountry} />
        </div>
        
        {/* Country code with non-breaking space after flag for better spacing */}
        <span className="text-sm text-foreground">
          &nbsp;+{localCountry ? RPNInput.getCountryCallingCode(localCountry) : ''}
        </span>
        
        {/* Arrow */}
        <div className="absolute right-0 pr-0.5">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="m6 9 6 6 6-6"/>
          </svg>
        </div>
      </div>
    </div>
  );
};

const FlagComponent = ({ country, countryName }: RPNInput.FlagProps) => {
  const Flag = flags[country];

  return (
    <span className="flex h-4 w-6 overflow-hidden rounded-sm bg-foreground/20 [&_svg]:size-full">
      {Flag && <Flag title={countryName} />}
    </span>
  );
};

export { PhoneInput };
