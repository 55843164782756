import { useState, useRef, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Building2, Mail, Phone, User, Pencil, Copy, ChevronDown, ChevronRight, Globe, UserSearch, Plus, Trash2 } from 'lucide-react';
import { toast } from 'sonner';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Switch } from '@/components/ui/switch';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import type { VerificationItem, OFACCheck } from '@/components/verification-document';
import { useDeal } from '@/contexts/deal-context';
import {
  useUpdateContact,
  useCreateContact,
  useDeleteContact,
  useAddLicense,
  useUpdateLicense,
  useRemoveLicense,
  useAddDomainAssociation,
  useRemoveDomainAssociation,
} from '@/hooks/use-contacts';
import { useDeal as useCurrentDeal, DEALS_QUERY_KEY } from '@/hooks/use-deals';
import type { Deal } from '@/hooks/use-deals';
import type { TradeSearchResponse } from '@/hooks/use-trade';
import { ContactSheet } from './contact-sheet';

interface EditableFieldProps {
  label: string;
  value: string | null | undefined;
  onSave: (value: string) => Promise<void>;
  type?: string;
  className?: string;
}

function EditableField({ label, value, onSave, type = 'text', className }: EditableFieldProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value || '');
  const inputRef = useRef<HTMLInputElement>(null);
  const { dealId } = useDeal();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleSave = async () => {
    try {
      await onSave(editValue);
      setIsEditing(false);
      toast.success('Updated successfully');
    } catch {
      toast.error('Failed to update');
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(value || '');
      toast.success('Copied to clipboard');
    } catch {
      toast.error('Failed to copy to clipboard');
    }
  };

  const handleAddOFACCheck = async () => {
    if (!value)
      return;

    // Get current verification document
    const deal = await queryClient.getQueryData([...DEALS_QUERY_KEY, dealId]) as Deal;
    if (!deal?.verification_document?.body)
      return;

    try {
      let items: VerificationItem[] = [];
      try {
        items = JSON.parse(deal.verification_document.body);
      } catch {
        toast.error('Failed to parse verification document');
        return;
      }

      // Find the last OFAC check or first FDIC check
      const lastOFACIndex = [...items].reverse().findIndex((item: VerificationItem) => item.type === 'ofac');
      const firstFDICIndex = items.findIndex((item: VerificationItem) => item.type === 'fdic');

      // Determine insert position
      let insertPosition;
      if (lastOFACIndex !== -1) {
        insertPosition = items.length - lastOFACIndex; // Convert from reverse index
      } else if (firstFDICIndex === -1) {
        insertPosition = items.length;
      } else {
        insertPosition = firstFDICIndex;
      }

      // Create new OFAC check
      const newOFACCheck: OFACCheck = {
        id: `ofac-${Date.now()}`,
        type: 'ofac',
        entityName: value,
        verified: false,
        signatures: [],
        searchResults: null,
        verifiedBy: undefined,
      };

      // Insert the new OFAC check
      items.splice(insertPosition, 0, newOFACCheck);

      // Update verification document
      await queryClient.setQueryData([...DEALS_QUERY_KEY, dealId], {
        ...deal,
        verification_document: {
          ...deal.verification_document,
          body: JSON.stringify(items),
        },
      });

      toast.success('OFAC check added');
    } catch {
      toast.error('Failed to add OFAC check');
    }
  };

  if (isEditing) {
    return (
      <div className="flex items-center gap-2">
        <Input
          ref={inputRef}
          type={type}
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          className="h-8"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              void handleSave();
            } else if (e.key === 'Escape') {
              setIsEditing(false);
            }
          }}
          onBlur={() => void handleSave()}
        />
      </div>
    );
  }

  // Determine if this field should show OFAC check button
  const showOFACCheck = label === 'RDDS Name' || label === 'Alt Name' || label === 'Organization Name';

  return (
    <div className="group flex items-center justify-between">
      <div
        className={`${className} cursor-pointer`}
        onClick={() => setIsEditing(true)}
      >
        {label && (
          <span className="text-muted-foreground">
            {label}
            :
            {' '}
          </span>
        )}
        {value || 'Not set'}
      </div>
      <div className="flex gap-1 opacity-0 group-hover:opacity-100">
        {value && showOFACCheck && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => void handleAddOFACCheck()}
                  className="h-6"
                >
                  <UserSearch className="size-3" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                Add OFAC check for this entity
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
        <Button
          size="sm"
          variant="ghost"
          onClick={handleCopy}
          className="h-6"
        >
          <Copy className="size-3" />
        </Button>
      </div>
    </div>
  );
}

interface EditableSwitchProps {
  label: string;
  value: boolean;
  onSave: (value: boolean) => Promise<void>;
}

function EditableSwitch({ label, value, onSave }: EditableSwitchProps) {
  return (
    <div className="flex items-center justify-between">
      <span className="text-muted-foreground">{label}</span>
      <Switch
        checked={value}
        onCheckedChange={async (checked) => {
          try {
            await onSave(checked);
            toast.success('Updated successfully');
          } catch {
            toast.error('Failed to update');
          }
        }}
      />
    </div>
  );
}

interface PersonCardProps {
  person: {
    name: string;
    legal_name: string;
    alt_name: string | null;
    email: string;
    alt_email: string | null;
    phone_number: string;
    alt_phone: string | null;
    fax_number: string | null;
    address_line_1: string;
    address_line_2: string | null;
    address_line_3: string | null;
    city: string;
    state_province: string;
    postal_code: string;
    country: string;
    title: string;
    organization_name?: string;
    c_suite: boolean;
    sole_prop: boolean;
    new_legal_name: boolean;
    contact_ids: string[];
    associated_domains: string[];
    organization_licenses?: Array<{
      authority: string;
      value: string;
      contact_ids: string[];
      license_ids: string[];
    }>;
  };
  index: number;
  type: 'registrant' | 'verification';
  onUpdate: (index: number, field: string, value: string | boolean | any) => Promise<void>;
  onDelete?: (contactIds: string[]) => Promise<void>;
}

function PersonCard({ person, index, type, onUpdate, onDelete }: PersonCardProps) {
  const queryClient = useQueryClient();
  const { dealId } = useDeal();
  const [showDomains, setShowDomains] = useState(false);
  const [showAddress, setShowAddress] = useState(() => type === 'registrant');
  const [isAddingLicense, setIsAddingLicense] = useState(false);
  const [editingLicenseIndex, setEditingLicenseIndex] = useState<number | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const addLicense = useAddLicense();
  const updateLicense = useUpdateLicense();
  const removeLicense = useRemoveLicense();
  const addDomainAssociation = useAddDomainAssociation();
  const removeDomainAssociation = useRemoveDomainAssociation();
  const { data: deal } = useCurrentDeal(dealId!);

  useEffect(() => {
    // Reset editing state when person changes
    setIsAddingLicense(false);
    setEditingLicenseIndex(null);

    // For verification contacts, check if we should expand the address
    if (type === 'verification' && deal?.registrants.people) {
      // Find a registrant with matching organization
      const matchingRegistrant = deal.registrants.people.find(
        (reg) => reg.organization_name === person.organization_name,
      );

      if (matchingRegistrant) {
        // Check if any address fields differ
        const addressDiffers
          = matchingRegistrant.address_line_1 !== person.address_line_1
          || matchingRegistrant.address_line_2 !== person.address_line_2
          || matchingRegistrant.address_line_3 !== person.address_line_3
          || matchingRegistrant.city !== person.city
          || matchingRegistrant.state_province !== person.state_province
          || matchingRegistrant.postal_code !== person.postal_code
          || matchingRegistrant.country !== person.country;

        setShowAddress(addressDiffers);
      }
    }
  }, [type, person, deal?.registrants.people]);

  const handleUpdate = async (field: string, value: string | boolean | any) => {
    await onUpdate(index, field, value);
    await queryClient.invalidateQueries({ queryKey: [...DEALS_QUERY_KEY, dealId] });
  };

  const handleAddFDICCheck = async (certNumber: string) => {
    if (!certNumber)
      return;

    // Get current verification document
    const deal = await queryClient.getQueryData([...DEALS_QUERY_KEY, dealId]) as Deal;
    if (!deal?.verification_document?.body)
      return;

    try {
      let items: VerificationItem[] = [];
      try {
        items = JSON.parse(deal.verification_document.body);
      } catch {
        toast.error('Failed to parse verification document');
        return;
      }

      // Find the last FDIC check
      const lastFDICIndex = [...items].reverse().findIndex((item: VerificationItem) => item.type === 'fdic');

      // Determine insert position - after the last FDIC check or at the end
      const insertPosition = lastFDICIndex === -1
        ? items.length // Convert from reverse index and add 1 to place after
        : items.length - lastFDICIndex + 1;

      // Create new FDIC check
      const newFDICCheck = {
        id: `fdic-${Date.now()}`,
        type: 'fdic' as const,
        entityName: person.organization_name || person.legal_name,
        certNumber,
        verified: false,
        signatures: [],
        searchResults: null,
        verifiedBy: undefined,
      };

      // Insert the new FDIC check
      items.splice(insertPosition, 0, newFDICCheck);

      // Update verification document
      await queryClient.setQueryData([...DEALS_QUERY_KEY, dealId], {
        ...deal,
        verification_document: {
          ...deal.verification_document,
          body: JSON.stringify(items),
        },
      });

      toast.success('FDIC check added');
    } catch {
      toast.error('Failed to add FDIC check');
    }
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between">
        <div className="font-medium">{person.name}</div>
        {onDelete && (
          <Button
            size="sm"
            variant="ghost"
            onClick={() => setIsDeleteDialogOpen(true)}
            className="text-destructive hover:text-destructive"
          >
            <Trash2 className="size-4" />
          </Button>
        )}
      </div>
      <div className="grid gap-x-4 gap-y-1">
        <div className="grid grid-cols-2 gap-x-4">
          <EditableField
            label="RDDS Name"
            value={person.legal_name}
            onSave={async (value) => handleUpdate('legal_name', value)}
            className="font-medium"
          />
          <EditableField
            label="Alt Name"
            value={person.alt_name || ''}
            onSave={async (value) => handleUpdate('alt_name', value)}
            className="text-sm"
          />
        </div>
        <EditableField
          label="Title"
          value={person.title}
          onSave={async (value) => handleUpdate('title', value)}
          className="text-sm"
        />
        <EditableField
          label="Organization Name"
          value={person.organization_name || ''}
          onSave={async (value) => handleUpdate('organization_name', value)}
          className="text-sm"
        />
      </div>

      {/* Address Section */}
      <div className="space-y-1">
        <button
          onClick={() => setShowAddress(!showAddress)}
          className="flex w-full items-center gap-2 text-sm text-muted-foreground hover:text-foreground"
        >
          {showAddress ? <ChevronDown className="size-4" /> : <ChevronRight className="size-4" />}
          <Building2 className="size-4" />
          <span>Address Details</span>
        </button>
        {showAddress && (
          <div className="ml-6 grid gap-1 text-sm">
            <EditableField
              label="Address Line 1"
              value={person.address_line_1}
              onSave={async (value) => handleUpdate('address_line_1', value)}
            />
            <EditableField
              label="Address Line 2"
              value={person.address_line_2 || ''}
              onSave={async (value) => handleUpdate('address_line_2', value)}
            />
            <EditableField
              label="Address Line 3"
              value={person.address_line_3 || ''}
              onSave={async (value) => handleUpdate('address_line_3', value)}
            />
            <EditableField
              label="City"
              value={person.city}
              onSave={async (value) => handleUpdate('city', value)}
            />
            <EditableField
              label="State/Province"
              value={person.state_province}
              onSave={async (value) => handleUpdate('state_province', value)}
            />
            <EditableField
              label="Postal Code"
              value={person.postal_code}
              onSave={async (value) => handleUpdate('postal_code', value)}
            />
            <EditableField
              label="Country"
              value={person.country}
              onSave={async (value) => handleUpdate('country', value)}
            />
          </div>
        )}
      </div>

      {/* Associated Domains Section */}
      {person.associated_domains.length > 0 && (
        <div className="space-y-1">
          <button
            onClick={() => setShowDomains(!showDomains)}
            className="flex w-full items-center gap-2 text-sm text-muted-foreground hover:text-foreground"
          >
            {showDomains ? <ChevronDown className="size-4" /> : <ChevronRight className="size-4" />}
            <Globe className="size-4" />
            <span>
              {person.associated_domains.length}
              {' '}
              Associated Domain
              {person.associated_domains.length === 1 ? '' : 's'}
            </span>
          </button>
          {showDomains && (
            <div className="ml-6 space-y-1">
              {person.associated_domains.map((domain, idx) => (
                <div key={idx} className="flex items-center gap-2 text-sm">
                  <span>{domain}</span>
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={async () => {
                      try {
                        await navigator.clipboard.writeText(domain);
                        toast.success('Domain copied to clipboard');
                      } catch {
                        toast.error('Failed to copy to clipboard');
                      }
                    }}
                  >
                    <Copy className="size-3" />
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      <div className="grid grid-cols-2 gap-x-4 gap-y-1">
        <EditableField
          label="Company Phone"
          value={person.phone_number}
          onSave={async (value) => handleUpdate('phone_number', value)}
          type="tel"
        />
        <EditableField
          label="Direct Phone"
          value={person.alt_phone || ''}
          onSave={async (value) => handleUpdate('alt_phone', value)}
          type="tel"
        />
        <EditableField
          label="Fax"
          value={person.fax_number || ''}
          onSave={async (value) => handleUpdate('fax_number', value)}
          type="tel"
        />
        <EditableField
          label="Email"
          value={person.email}
          onSave={async (value) => handleUpdate('email', value)}
          type="email"
        />
        <EditableField
          label="Alt Email"
          value={person.alt_email || ''}
          onSave={async (value) => handleUpdate('alt_email', value)}
          type="email"
        />
      </div>

      <div className="grid grid-cols-3 gap-x-4">
        <EditableSwitch
          label="C-Suite"
          value={person.c_suite}
          onSave={async (value) => handleUpdate('c_suite', value)}
        />
        <EditableSwitch
          label="Sole Prop"
          value={person.sole_prop}
          onSave={async (value) => handleUpdate('sole_prop', value)}
        />
        <EditableSwitch
          label="New Legal Name"
          value={person.new_legal_name}
          onSave={async (value) => handleUpdate('new_legal_name', value)}
        />
      </div>

      {/* Show licenses for registrant contacts */}
      {type === 'registrant' && person && (
        <div className="grid gap-1">
          <div className="flex items-center justify-between">
            <div className="text-sm font-medium text-muted-foreground">Licenses</div>
            <Button
              size="sm"
              variant="outline"
              onClick={() => setIsAddingLicense(true)}
            >
              Add License
            </Button>
          </div>
          {isAddingLicense && (
            <LicenseForm
              onSubmit={async (authority, value) => {
                try {
                  // Call API to add license for each contact
                  const addedLicenses = await Promise.all(
                    person.contact_ids?.map(async (contactId) => {
                      const result = await addLicense.mutateAsync({
                        contactId,
                        authority,
                        value,
                      });
                      return result;
                    }) ?? [],
                  );

                  // Update local state with new license
                  if (addedLicenses.length > 0) {
                    const newLicense = {
                      authority,
                      value,
                      contact_ids: person.contact_ids,
                      license_ids: addedLicenses.map((license: { id: string }) => license.id),
                    };
                    await handleUpdate('organization_licenses', [...(person.organization_licenses || []), newLicense]);
                  }

                  toast.success('License added');
                  setIsAddingLicense(false);
                } catch {
                  toast.error('Failed to add license');
                }
              }}
              onCancel={() => setIsAddingLicense(false)}
            />
          )}
          <div className="grid gap-1">
            {person.organization_licenses?.map((license: { authority: string; value: string; contact_ids: string[]; license_ids: string[] }, licenseIndex: number) => (
              <div key={licenseIndex} className="rounded-md border p-2">
                {editingLicenseIndex === licenseIndex
                  ? (
                      <LicenseForm
                        initialAuthority={license.authority}
                        initialValue={license.value}
                        onSubmit={async (authority, value) => {
                          try {
                            const license = person.organization_licenses?.[licenseIndex];
                            if (!license)
                              return;

                            // Try to update all license IDs for each contact ID
                            await Promise.all(
                              license.contact_ids.flatMap(async (contactId) =>
                                license.license_ids.map(async (licenseId) => {
                                  try {
                                    await updateLicense.mutateAsync({
                                      contactId,
                                      licenseId,
                                      authority,
                                      value,
                                    });
                                  } catch (error) {
                                    // Ignore errors - some license IDs may not exist for some contacts
                                    console.log(`Failed to update license ${licenseId} for contact ${contactId}`, error);
                                  }
                                }),
                              ),
                            );

                            // Update local state
                            const updatedLicenses = [...(person.organization_licenses || [])];
                            updatedLicenses[licenseIndex] = {
                              ...license,
                              authority,
                              value,
                            };
                            await handleUpdate('organization_licenses', updatedLicenses);

                            toast.success('License updated');
                            setEditingLicenseIndex(null);
                          } catch {
                            toast.error('Failed to update license');
                          }
                        }}
                        onCancel={() => setEditingLicenseIndex(null)}
                      />
                    )
                  : (
                      <div className="flex items-center justify-between">
                        <div>
                          <div className="font-medium">{license.authority}</div>
                          <div className="text-sm text-muted-foreground">{license.value}</div>
                        </div>
                        <div className="flex gap-2">
                          {license.authority.toUpperCase() === 'FDIC' && (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <Button
                                    size="sm"
                                    variant="ghost"
                                    onClick={() => void handleAddFDICCheck(license.value)}
                                  >
                                    <UserSearch className="size-3" />
                                  </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                  Add FDIC check for certificate #
                                  {license.value}
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          )}
                          <Button
                            size="sm"
                            variant="ghost"
                            onClick={() => setEditingLicenseIndex(licenseIndex)}
                          >
                            Edit
                          </Button>
                          <Button
                            size="sm"
                            variant="ghost"
                            onClick={async () => {
                              try {
                                const license = person.organization_licenses?.[licenseIndex];
                                if (!license)
                                  return;

                                // Try to remove all license IDs for each contact ID
                                await Promise.all(
                                  license.contact_ids.flatMap(async (contactId) =>
                                    license.license_ids.map(async (licenseId) => {
                                      try {
                                        await removeLicense.mutateAsync({ contactId, licenseId });
                                      } catch (error) {
                                        // Ignore errors - some license IDs may not exist for some contacts
                                        console.log(`Failed to remove license ${licenseId} for contact ${contactId}`, error);
                                      }
                                    }),
                                  ),
                                );

                                // Update local state by removing the license
                                const updatedLicenses = (person.organization_licenses || []).filter((_, idx) => idx !== licenseIndex);
                                await handleUpdate('organization_licenses', updatedLicenses);

                                toast.success('License removed');
                              } catch {
                                toast.error('Failed to remove license');
                              }
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    )}
              </div>
            ))}
          </div>
        </div>
      )}

      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Contact</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this contact? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={async () => {
                if (onDelete) {
                  await onDelete(person.contact_ids);
                  setIsDeleteDialogOpen(false);
                }
              }}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}

interface LicenseFormProps {
  onSubmit: (authority: string, value: string) => Promise<void>;
  onCancel: () => void;
  initialAuthority?: string;
  initialValue?: string;
}

function LicenseForm({ onSubmit, onCancel, initialAuthority = '', initialValue = '' }: LicenseFormProps) {
  const [authority, setAuthority] = useState(initialAuthority);
  const [value, setValue] = useState(initialValue);

  return (
    <div className="rounded-md border p-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <div className="mb-2 text-sm font-medium">Authority</div>
          <Input
            placeholder="Enter authority"
            value={authority}
            onChange={(e) => setAuthority(e.target.value)}
          />
        </div>
        <div>
          <div className="mb-2 text-sm font-medium">Value</div>
          <Input
            placeholder="Enter value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-4 flex justify-end gap-2">
        <Button
          size="sm"
          variant="outline"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          onClick={async () => {
            if (!authority || !value) {
              toast.error('Please fill in both fields');
              return;
            }
            await onSubmit(authority, value);
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export function ContactsPanel() {
  const { dealId } = useDeal();
  const { data: deal, isLoading: isLoadingDeal } = useCurrentDeal(dealId!);
  const updateContact = useUpdateContact();
  const createContact = useCreateContact();
  const deleteContact = useDeleteContact();
  const addLicense = useAddLicense();
  const updateLicense = useUpdateLicense();
  const removeLicense = useRemoveLicense();
  const addDomainAssociation = useAddDomainAssociation();
  const removeDomainAssociation = useRemoveDomainAssociation();
  const queryClient = useQueryClient();
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [editingContact, setEditingContact] = useState<any | null>(null);

  if (isLoadingDeal || !deal) {
    return (
      <div className="flex h-full items-center justify-center">
        <div className="text-sm text-muted-foreground">Loading deal...</div>
      </div>
    );
  }

  const handleUpdateRegistrantPerson = async (index: number, field: string, value: string | boolean | any) => {
    const person = deal.registrants.people[index];

    try {
      if (field.startsWith('organization_licenses')) {
        const licenseIndex = Number.parseInt(field.split('.')[1], 10);
        const license = person.organization_licenses?.[licenseIndex];

        if (field.endsWith('authority') || field.endsWith('value')) {
          // Update the specific license field
          const fieldName = field.split('.').pop();
          const licenseData = {
            authority: fieldName === 'authority' ? value : license?.authority,
            value: fieldName === 'value' ? value : license?.value,
          };

          // Update or create license for each contact ID
          await Promise.all(person.contact_ids?.map(async (contactId: string) => {
            const licenseId = license?.license_ids?.find((id) =>
              license.contact_ids?.includes(contactId),
            );

            await (licenseId
              ? updateLicense.mutateAsync({
                  contactId,
                  licenseId,
                  ...licenseData,
                })
              : addLicense.mutateAsync({
                  contactId,
                  ...licenseData,
                }));
          }) ?? []);
        }
      } else {
        const contactData = {
          legal_name: person.legal_name,
          email: person.email,
          phone_number: person.phone_number,
          alt_phone: person.alt_phone,
          fax_number: person.fax_number,
          address_line_1: person.address_line_1,
          address_line_2: person.address_line_2,
          address_line_3: person.address_line_3,
          city: person.city,
          state_province: person.state_province,
          postal_code: person.postal_code,
          country: person.country,
          alt_name: person.alt_name,
          alt_email: person.alt_email,
          title: person.title,
          organization_name: person.organization_name,
          c_suite: person.c_suite,
          sole_prop: person.sole_prop,
          new_legal_name: person.new_legal_name,
        };

        // Update the specific field
        const fieldParts = field.split('.');
        let current: any = contactData;
        for (let i = 0; i < fieldParts.length - 1; i++) {
          if (!current[fieldParts[i]])
            current[fieldParts[i]] = {};
          current = current[fieldParts[i]];
        }
        const lastField = fieldParts.at(-1);
        if (lastField) {
          current[lastField] = value;
        }

        // Update each contact ID for this person
        if (person.contact_ids?.length) {
          await Promise.all(
            person.contact_ids.map(async (contactId: string) =>
              updateContact.mutateAsync({ id: contactId, ...contactData }),
            ),
          );
        }
      }
    } catch (error) {
      toast.error('Failed to update contact');
      throw error;
    }
  };

  const handleUpdateVerificationPerson = async (index: number, field: string, value: string | boolean) => {
    const person = deal.verification_contacts.people[index];

    try {
      const contactData = {
        legal_name: person.legal_name,
        email: person.email,
        phone_number: person.phone_number,
        alt_phone: person.alt_phone,
        fax_number: person.fax_number,
        address_line_1: person.address_line_1,
        address_line_2: person.address_line_2,
        address_line_3: person.address_line_3,
        city: person.city,
        state_province: person.state_province,
        postal_code: person.postal_code,
        country: person.country,
        alt_name: person.alt_name,
        alt_email: person.alt_email,
        title: person.title,
        organization_legal_name: person.organization_name,
        c_suite: person.c_suite,
        sole_prop: person.sole_prop,
        new_legal_name: person.new_legal_name,
      };

      // Update the specific field
      const fieldParts = field.split('.');
      let current: any = contactData;
      for (let i = 0; i < fieldParts.length - 1; i++) {
        if (!current[fieldParts[i]])
          current[fieldParts[i]] = {};
        current = current[fieldParts[i]];
      }
      const lastField = fieldParts.at(-1);
      if (lastField) {
        current[lastField] = value;
      }

      // Update each contact ID for this person
      if (person.contact_ids?.length) {
        await Promise.all(
          person.contact_ids.map(async (contactId) =>
            updateContact.mutateAsync({ id: contactId, ...contactData }),
          ),
        );
      }
    } catch (error) {
      toast.error('Failed to update verification contact');
      throw error;
    }
  };

  const handleCreateContact = async (data: any) => {
    try {
      if (!deal.domains?.length) {
        throw new Error('No domains available in the deal');
      }

      // Create a contact and domain association for each domain
      await Promise.all(
        deal.domains.map(async (domain) => {
          // Create a new contact for this domain
          const newContact = await createContact.mutateAsync({
            legal_name: data.legal_name,
            email: data.email,
            phone_number: data.phone_number,
            alt_phone: data.alt_phone,
            fax_number: data.fax_number,
            address_line_1: data.address_line_1,
            address_line_2: data.address_line_2,
            address_line_3: data.address_line_3,
            city: data.city,
            state_province: data.state_province,
            postal_code: data.postal_code,
            country: data.country,
            alt_name: data.alt_name,
            alt_email: data.alt_email,
            title: data.title,
            role_name: data.role_name,
            organization_legal_name: data.organization_legal_name,
            c_suite: data.c_suite,
            sole_prop: data.sole_prop,
            new_legal_name: data.new_legal_name,
          });

          // Create domain association for this contact
          await addDomainAssociation.mutateAsync({
            contactId: newContact.id,
            domain,
            type: data.type,
          });
        }),
      );

      // Refresh the deal data
      await queryClient.invalidateQueries({ queryKey: [...DEALS_QUERY_KEY, dealId] });

      toast.success('Contact created successfully');
      setIsSheetOpen(false);
    } catch (error) {
      toast.error('Failed to create contact');
      throw error;
    }
  };

  const handleDeleteContact = async (contactIds: string[]) => {
    try {
      // First remove all domain associations for each contact
      await Promise.all(
        contactIds.flatMap(async (contactId) => {
          if (deal.domains) {
            return Promise.all(
              deal.domains.map(async (domain) => {
                try {
                  await removeDomainAssociation.mutateAsync({
                    contactId,
                    domain,
                  });
                } catch (error) {
                  // Ignore errors - some associations might not exist
                  console.log(`Failed to remove domain association for contact ${contactId} and domain ${domain}`, error);
                }
              }),
            );
          }
          return [];
        }),
      );

      // Then delete the contacts
      await Promise.all(contactIds.map(async (id) => deleteContact.mutateAsync(id)));
      toast.success('Contact deleted successfully');

      // Refresh the deal data
      await queryClient.invalidateQueries({ queryKey: [...DEALS_QUERY_KEY, dealId] });
    } catch {
      toast.error('Failed to delete contact');
    }
  };

  return (
    <ScrollArea className="h-[600px]">
      <div className="flex h-full max-h-[600px] flex-col overflow-hidden bg-background">
        <div className="flex items-center justify-between border-b p-4">
          <div>
            <h2 className="text-lg font-semibold">Contacts</h2>
            <p className="text-sm text-muted-foreground">
              {deal.registrants.people.length + deal.verification_contacts.people.length}
              {' '}
              contact
              {deal.registrants.people.length + deal.verification_contacts.people.length === 1 ? '' : 's'}
            </p>
          </div>
          <Button
            variant="outline"
            onClick={() => {
              setEditingContact(null);
              setIsSheetOpen(true);
            }}
            className="gap-2"
          >
            <Plus className="size-4" />
            Add Contact
          </Button>
        </div>

        <div className="flex-1 overflow-y-auto p-4">
          <div className="mx-auto max-w-2xl space-y-4">
            <Accordion
              type="multiple"
              className="w-full"
              defaultValue={['registrant-people', 'verification-people']}
            >
              {/* Registrant People */}
              {deal.registrants.people.length > 0 && (
                <AccordionItem value="registrant-people">
                  <AccordionTrigger className="px-4 py-2 hover:no-underline">
                    <div className="flex items-center gap-2">
                      <User className="size-4" />
                      <span>Registrant Contacts</span>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="space-y-4 px-4 pb-4">
                    {deal.registrants.people.map((person, index) => (
                      <PersonCard
                        key={index}
                        person={person}
                        index={index}
                        type="registrant"
                        onUpdate={handleUpdateRegistrantPerson}
                        onDelete={handleDeleteContact}
                      />
                    ))}
                  </AccordionContent>
                </AccordionItem>
              )}

              {/* Verification People */}
              {deal.verification_contacts.people.length > 0 && (
                <AccordionItem value="verification-people">
                  <AccordionTrigger className="px-4 py-2 hover:no-underline">
                    <div className="flex items-center gap-2">
                      <User className="size-4" />
                      <span>Verification Contacts</span>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="space-y-4 px-4 pb-4">
                    {deal.verification_contacts.people.map((person, index) => (
                      <PersonCard
                        key={index}
                        person={person}
                        index={index}
                        type="verification"
                        onUpdate={handleUpdateVerificationPerson}
                        onDelete={handleDeleteContact}
                      />
                    ))}
                  </AccordionContent>
                </AccordionItem>
              )}
            </Accordion>

            <ContactSheet
              open={isSheetOpen}
              onOpenChange={setIsSheetOpen}
              initialData={editingContact}
              onSubmit={handleCreateContact}
            />
          </div>
        </div>
      </div>
    </ScrollArea>
  );
}
