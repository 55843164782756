import { useQuery } from '@tanstack/react-query';
import { useAxios } from './use-axios';
import { usePersistedState } from './use-persisted-state';

/**
 * Domain with pending create status
 */
export interface PendingCreateDomain {
  name: string;
  eppStatuses: string[];
}

/**
 * Domain statistics by type
 */
export interface DomainTypeStats {
  active: number;
  inactive: number;
  pendingCreate: number;
}

/**
 * Total domain statistics
 */
export interface TotalDomainStats extends DomainTypeStats {
  all: number;
}

/**
 * Annual verification statistics
 */
export interface AnnualVerificationStats {
  initiated: number;
  needed: number;
  remaining: number;
  percentComplete: number;
  percentOfYearElapsed: number;
  progressRatio: number;
  status: string;
}

/**
 * Business metrics statistics - OLD STRUCTURE, KEPT FOR COMPATIBILITY
 */
export interface BusinessMetrics {
  total: number;
  won: number;
  percentage: number;
}

/**
 * Deal outcomes with arrays of deals
 */
export interface DealOutcomes {
  [outcome: string]: Deal[];
}

/**
 * New business statistics
 */
export interface NewBusinessStats {
  mtd: DealOutcomes;
  ytd: DealOutcomes;
}

/**
 * Deal stages for all pipeline types
 */
export interface DealStages {
  'Annual Verification': number;
  'Pending Verification': number;
  'Organization Verification in Progress': number;
  'Name Selection in Progress': number;
  'Employment Verification in Progress': number;
  'WHOIS Update in Progress': number;
  'Failed Verification': number;
  'Hold': number;
  'Verification Completed': number;
  'Verified Data Sent': number;
  'Completed': number;
  [key: string]: number; // Allow for any additional stages that might be added
}

/**
 * Deal object with partner information
 */
export interface Deal {
  id: string;
  created_at: string;
  updated_at: string;
  deal_stage: string;
  deal_type: string;
  assigned_to: string;
  domains: string[];
  outcome: string | null;
  outcome_updated: string | null;
  partner_id: string;
  partner?: {
    name: string;
    email: string;
    iana_id: string;
  };
}

/**
 * Deal pipeline structure
 */
export interface Pipeline {
  total: number;
  stages: DealStages;
  deals?: {
    [stage: string]: Deal[];
  };
}

/**
 * Dashboard statistics response type
 */
export interface DashboardResponse {
  stats: {
    bank: DomainTypeStats;
    insurance: DomainTypeStats;
    total: TotalDomainStats;
  };
  pendingCreateDomains: {
    bank: PendingCreateDomain[];
    insurance: PendingCreateDomain[];
  };
  annualVerification: AnnualVerificationStats;
  newBusiness: NewBusinessStats;
  pipelines: {
    'New Business': Pipeline;
    'Existing Business': Pipeline;
    'Annual Verification': Pipeline;
    'Forced Verification': Pipeline;
    [key: string]: Pipeline; // Allow for any additional pipeline types
  };
}

/**
 * Query key for dashboard statistics
 */
export const DASHBOARD_STATS_QUERY_KEY = ['dashboard', 'stats'];

/**
 * Query key for domain statistics
 */
export const DOMAIN_STATS_QUERY_KEY = ['dashboard', 'domain-stats'];

/**
 * Domain statistics response type
 */
export interface DomainStatsResponse {
  bank: DomainTypeStats;
  insurance: DomainTypeStats;
  total: TotalDomainStats;
  pendingCreateDomains: {
    bank: PendingCreateDomain[];
    insurance: PendingCreateDomain[];
  };
}

/**
 * Hook to fetch domain statistics for the dashboard
 *
 * @returns Query result containing domain statistics
 * @example
 * ```tsx
 * const { data: stats, isLoading, error, isFetching } = useDashboardStats();
 *
 * if (isLoading) return <div>Loading stats...</div>;
 * if (error) return <div>Error loading stats</div>;
 *
 * return (
 *   <div>Total Active Domains: {stats?.stats.total.active}</div>
 * );
 * ```
 */
export function useDashboardStats() {
  const axios = useAxios();
  const [cachedStats, setCachedStats] = usePersistedState<DashboardResponse | null>('cached-dashboard-stats', null);

  return useQuery({
    queryKey: DASHBOARD_STATS_QUERY_KEY,
    queryFn: async () => {
      try {
        const { data } = await axios.get<DashboardResponse>('/dashboard/stats');

        // Create a cacheable version by removing detailed deal arrays which can be large
        const cacheCopy = structuredClone(data);
        if (cacheCopy.pipelines) {
          // Keep the pipeline structure and stage counts, but remove detailed deal objects
          for (const pipelineKey of Object.keys(cacheCopy.pipelines)) {
            if (cacheCopy.pipelines[pipelineKey].deals) {
              delete cacheCopy.pipelines[pipelineKey].deals;
            }
          }
        }

        // Update cached stats for offline access (without detailed deals)
        setCachedStats(cacheCopy);
        return data;
      } catch (error) {
        // On error, return cached stats if available
        if (cachedStats) {
          return cachedStats;
        }
        if (error instanceof Error) {
          throw error;
        }
        throw new Error('Failed to fetch dashboard statistics');
      }
    },
    // Cache statistics for 5 minutes before considering them stale
    staleTime: 5 * 60 * 1000,
    // Keep data cached indefinitely
    gcTime: Infinity,
    // Always refetch in background when component mounts
    refetchOnMount: 'always',
    // Disable window focus refetching since we have interval
    refetchOnWindowFocus: false,
    // Refetch every 2 minutes while page is visible
    refetchInterval: 2 * 60 * 1000,
    refetchIntervalInBackground: false,
    // Initialize with cached data if available
    initialData: cachedStats,
  });
}

/**
 * Hook to fetch domain-specific statistics
 *
 * @returns Query result containing domain statistics
 * @example
 * ```tsx
 * const { data: domainStats, isLoading, error } = useDomainStats();
 *
 * if (isLoading) return <div>Loading domain stats...</div>;
 * if (error) return <div>Error loading domain stats</div>;
 *
 * return (
 *   <div>
 *     <div>Active .bank Domains: {domainStats?.bank.active}</div>
 *     <div>Active .insurance Domains: {domainStats?.insurance.active}</div>
 *   </div>
 * );
 * ```
 */
export function useDomainStats() {
  const axios = useAxios();
  const [cachedDomainStats, setCachedDomainStats] = usePersistedState<DomainStatsResponse | null>('cached-domain-stats', null);

  return useQuery({
    queryKey: DOMAIN_STATS_QUERY_KEY,
    queryFn: async () => {
      try {
        const { data } = await axios.get<DomainStatsResponse>('/dashboard/domain-stats');

        // Cache the domain stats for offline access
        setCachedDomainStats(data);
        return data;
      } catch (error) {
        // On error, return cached stats if available
        if (cachedDomainStats) {
          return cachedDomainStats;
        }
        if (error instanceof Error) {
          throw error;
        }
        throw new Error('Failed to fetch domain statistics');
      }
    },
    // Cache statistics for 5 minutes before considering them stale
    staleTime: 5 * 60 * 1000,
    // Keep data cached indefinitely
    gcTime: Infinity,
    // Always refetch in background when component mounts
    refetchOnMount: 'always',
    // Disable window focus refetching since we have interval
    refetchOnWindowFocus: false,
    // Refetch every 2 minutes while page is visible
    refetchInterval: 2 * 60 * 1000,
    refetchIntervalInBackground: false,
    // Initialize with cached data if available
    initialData: cachedDomainStats,
  });
}
