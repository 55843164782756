import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import type { User } from '@clerk/backend';
import { useUser } from '@clerk/clerk-react';
import { format, isPast, isFuture } from 'date-fns';
import { Building2, Shield, Globe, Loader2, RotateCcw, Clock, CheckCircle2, TrendingUp, CalendarClock, Calendar, Briefcase, ChevronRight, ExternalLink, CheckCircle, AlertTriangle, ClipboardList } from 'lucide-react';
import Container from '@/components/container';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from '@/components/ui/card';
import { Progress } from '@/components/ui/progress';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { useDashboardStats, type PendingCreateDomain, type DealStages, type Deal, type DealOutcomes } from '@/hooks/use-dashboard-stats';
import { useDeal } from '@/hooks/use-deals';
import { useAssignedTasks, type Task, type TaskMessage } from '@/hooks/use-tasks';
import { cn } from '@/lib/utils';

// Define an interface for the enriched task type that includes the optional user fields
interface EnrichedTask extends Omit<Task, 'assigned_to_users'> {
  created_by_user?: Partial<User>;
  assigned_to_users?: Array<Partial<User> | undefined>;
  chat_history: Array<TaskMessage & { user?: Partial<User> }>;
}

/**
 * Returns a greeting based on the time of day.
 */
function getGreeting() {
  const hour = new Intl.DateTimeFormat('default', {
    hour: 'numeric',
    hour12: false,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }).format(new Date());

  const hourNumber = Number.parseInt(hour, 10);
  if (hourNumber < 12)
    return 'Good morning';
  if (hourNumber < 17)
    return 'Good afternoon';
  return 'Good evening';
}

// Function to calculate approval rate from outcomes data
function calculateApprovalRate(outcomes?: DealOutcomes): number {
  if (!outcomes)
    return 0;

  const positiveOutcomes = new Set(['Approved', 'No Verification Needed', 'fTLD Domain']);
  let total = 0;
  let approved = 0;

  for (const [outcome, deals] of Object.entries(outcomes)) {
    const dealCount = deals.length;
    total += dealCount;

    if (positiveOutcomes.has(outcome)) {
      approved += dealCount;
    }
  }

  return total > 0 ? (approved / total) * 100 : 0;
}

function DomainStatCard({
  title,
  icon: Icon,
  active,
  inactive,
  pendingCreate,
  total,
  className,
}: {
  title: string;
  icon: React.ElementType;
  active: number;
  inactive: number;
  pendingCreate: number;
  total: number;
  className?: string;
}) {
  return (
    <Card className={className}>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{title}</CardTitle>
        <Icon className="size-4 text-muted-foreground" />
      </CardHeader>
      <CardContent>
        <div className="space-y-3">
          <div>
            <div className="text-2xl font-bold">{total}</div>
            <p className="text-xs text-muted-foreground">Total domains</p>
          </div>
          <div className="grid grid-cols-3 gap-2">
            <div>
              <div className="text-sm font-medium text-green-600">{active}</div>
              <p className="text-xs text-muted-foreground">Active</p>
            </div>
            <div>
              <div className="text-sm font-medium text-amber-600">{inactive}</div>
              <p className="text-xs text-muted-foreground">Inactive</p>
            </div>
            <div>
              <div className="text-sm font-medium text-blue-600">{pendingCreate}</div>
              <p className="text-xs text-muted-foreground">Pending</p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function PendingCreateDomainsList({
  bankDomains,
  insuranceDomains,
}: {
  bankDomains: PendingCreateDomain[] | undefined;
  insuranceDomains: PendingCreateDomain[] | undefined;
}) {
  // Handle case where inputs might be undefined
  const bankDomainsArray = bankDomains || [];
  const insuranceDomainsArray = insuranceDomains || [];

  // If no domains, show "no domains" message
  if (bankDomainsArray.length === 0 && insuranceDomainsArray.length === 0) {
    return <p>No pending create domains found.</p>;
  }

  return (
    <div className="space-y-4">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Domain Name</TableHead>
            <TableHead>Type</TableHead>
            <TableHead>Status</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {bankDomainsArray.map((domain: PendingCreateDomain) => (
            <TableRow key={domain.name}>
              <TableCell className="font-medium">{domain.name}</TableCell>
              <TableCell>
                <Badge variant="outline" className="bg-green-50">
                  <Building2 className="mr-1 size-3" />
                  .Bank
                </Badge>
              </TableCell>
              <TableCell>
                <Badge variant="outline" className="bg-blue-50 text-blue-800">
                  <Clock className="mr-1 size-3" />
                  Pending Create
                </Badge>
              </TableCell>
            </TableRow>
          ))}
          {insuranceDomainsArray.map((domain: PendingCreateDomain) => (
            <TableRow key={domain.name}>
              <TableCell className="font-medium">{domain.name}</TableCell>
              <TableCell>
                <Badge variant="outline" className="bg-purple-50">
                  <Shield className="mr-1 size-3" />
                  .Insurance
                </Badge>
              </TableCell>
              <TableCell>
                <Badge variant="outline" className="bg-blue-50 text-blue-800">
                  <Clock className="mr-1 size-3" />
                  Pending Create
                </Badge>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

function BusinessMetricsCard({
  title,
  icon: Icon,
  outcomes,
  className,
  isLoading = false,
}: {
  title: string;
  icon: React.ElementType;
  outcomes?: DealOutcomes;
  className?: string;
  isLoading?: boolean;
}) {
  const navigate = useNavigate();
  const [expandedOutcome, setExpandedOutcome] = useState<string | null>(null);
  // Add state for active stage filters
  const [activeStageFilters, setActiveStageFilters] = useState<Set<string>>(new Set());

  // Toggle filter for a specific stage
  const toggleStageFilter = (stage: string, event: React.MouseEvent) => {
    // Stop propagation to prevent collapsing the section when clicking filters
    event.stopPropagation();

    setActiveStageFilters((prev) => {
      const newFilters = new Set(prev);
      if (newFilters.has(stage)) {
        newFilters.delete(stage);
      } else {
        newFilters.add(stage);
      }
      return newFilters;
    });
  };

  // Reset all filters
  const resetFilters = (event: React.MouseEvent) => {
    event.stopPropagation();
    setActiveStageFilters(new Set());
  };

  // Skip calculations if no outcomes data
  if (!outcomes && !isLoading) {
    return (
      <Card className={className}>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">{title}</CardTitle>
          <Icon className="size-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="py-4 text-center text-muted-foreground">
            <p>No data available</p>
          </div>
        </CardContent>
      </Card>
    );
  }

  // Calculate metrics from outcomes data
  const calculateMetrics = () => {
    if (!outcomes)
      return { total: 0, outcomePercentages: {}, outcomeCounts: {} };

    let total = 0;
    const outcomeCounts: Record<string, number> = {};

    // Count deals in each outcome category
    for (const [outcome, deals] of Object.entries(outcomes)) {
      const dealCount = deals.length;
      total += dealCount;
      outcomeCounts[outcome] = dealCount;
    }

    // Calculate percentages
    const outcomePercentages: Record<string, number> = {};
    for (const [outcome, count] of Object.entries(outcomeCounts)) {
      outcomePercentages[outcome] = total > 0 ? (count / total) * 100 : 0;
    }

    return { total, outcomePercentages, outcomeCounts };
  };

  const { total, outcomePercentages, outcomeCounts } = calculateMetrics();

  // Get outcomes sorted by percentage (highest first)
  const sortedOutcomes = Object.entries(outcomePercentages)
    .filter(([_, percentage]) => percentage > 0)
    .sort(([_, percentageA], [__, percentageB]) => percentageB - percentageA);

  // Format date for display
  const formatDate = (dateString: string) => {
    try {
      return format(new Date(dateString), 'MMM d, yyyy');
    } catch {
      return 'Invalid date';
    }
  };

  // Navigate to deal details
  const navigateToDeal = (dealId: string) => {
    navigate(`/app/deals/${dealId}`);
  };

  return (
    <Card className={className}>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{title}</CardTitle>
        <Icon className="size-4 text-muted-foreground" />
      </CardHeader>
      <CardContent>
        {isLoading
          ? (
              <div className="flex items-center justify-center py-4">
                <Loader2 className="size-5 animate-spin text-muted-foreground" />
              </div>
            )
          : (
              <div className="space-y-3">
                <div>
                  <div className="text-2xl font-bold">{total}</div>
                  <p className="text-xs text-muted-foreground">Total deals</p>
                </div>

                {sortedOutcomes.length > 0
                  ? (
                      <div className="space-y-2">
                        <p className="text-xs font-medium">Outcome Distribution:</p>
                        {sortedOutcomes.map(([outcome, percentage]) => (
                          <div key={outcome} className="border-b border-muted last:border-0">
                            <div
                              className="cursor-pointer space-y-1 py-2"
                              onClick={(e) => setExpandedOutcome(expandedOutcome === outcome ? null : outcome)}
                            >
                              <div className="flex items-center justify-between text-xs">
                                <div className="flex items-center gap-2">
                                  <ChevronRight
                                    className={cn(
                                      'size-3 transition-transform',
                                      expandedOutcome === outcome ? 'rotate-90' : '',
                                    )}
                                  />
                                  <span>{outcome}</span>
                                </div>
                                <span className="font-medium">
                                  {percentage.toFixed(1)}
                                  % (
                                  {outcomeCounts[outcome]}
                                  {' '}
                                  deals)
                                </span>
                              </div>
                              <div className="h-1.5 w-full overflow-hidden rounded-full bg-primary/10">
                                <div
                                  className="h-full rounded-full bg-primary"
                                  style={{ width: `${percentage}%` }}
                                />
                              </div>
                            </div>

                            {expandedOutcome === outcome && outcomes?.[outcome] && (
                              <div className="border-t border-muted bg-muted/30 p-2">
                                {outcomes?.[outcome].length > 0
                                  ? (
                                      <div>
                                        {/* Only show stage metrics for the "No Outcome" category */}
                                        {outcome === 'No Outcome' && (
                                          <div className="mb-3 rounded-md border border-muted bg-card p-2">
                                            <div className="mb-1.5 flex items-center justify-between">
                                              <span className="text-xs font-medium">Stage Distribution:</span>
                                              {activeStageFilters.size > 0 && (
                                                <Button
                                                  variant="ghost"
                                                  size="sm"
                                                  className="h-6 px-2 py-0 text-xs"
                                                  onClick={resetFilters}
                                                >
                                                  Clear Filters
                                                </Button>
                                              )}
                                            </div>
                                            <div className="space-y-1.5">
                                              {(() => {
                                                // Group deals by stage using a simple loop approach
                                                const stageCount: Record<string, number> = {};

                                                for (const deal of outcomes?.[outcome] || []) {
                                                  const stage = deal.deal_stage;
                                                  if (!stageCount[stage])
                                                    stageCount[stage] = 0;
                                                  stageCount[stage]++;
                                                }

                                                // Helper function to determine badge color based on stage
                                                const getStageBadgeClass = (stage: string): string => {
                                                  if (stage.includes('Completed') || stage.includes('Verified')) {
                                                    return 'bg-green-50 text-green-700';
                                                  }
                                                  if (stage.includes('Progress')) {
                                                    return 'bg-blue-50 text-blue-700';
                                                  }
                                                  if (stage.includes('Failed')) {
                                                    return 'bg-red-50 text-red-700';
                                                  }
                                                  return 'bg-gray-50';
                                                };

                                                // Convert to array and sort
                                                return Object.entries(stageCount)
                                                  .sort(([_, countA], [__, countB]) => countB - countA)
                                                  .map(([stage, count]) => {
                                                    const isActive = activeStageFilters.has(stage);
                                                    return (
                                                      <div
                                                        key={stage}
                                                        className={cn(
                                                          'flex items-center justify-between rounded p-1 cursor-pointer',
                                                          isActive ? 'bg-muted/70' : 'hover:bg-muted/30',
                                                        )}
                                                        onClick={(e) => toggleStageFilter(stage, e)}
                                                      >
                                                        <span className="flex items-center gap-1.5 text-xs">
                                                          <div className={cn(
                                                            'w-3 h-3 rounded-full border',
                                                            isActive ? 'bg-primary border-primary' : 'border-muted-foreground',
                                                          )}
                                                          />
                                                          {stage}
                                                        </span>
                                                        <Badge
                                                          variant="outline"
                                                          className={cn(
                                                            'h-5 px-1.5 py-0 text-xs',
                                                            getStageBadgeClass(stage),
                                                          )}
                                                        >
                                                          {count}
                                                          {' '}
                                                          {count === 1 ? 'deal' : 'deals'}
                                                        </Badge>
                                                      </div>
                                                    );
                                                  });
                                              })()}
                                            </div>
                                          </div>
                                        )}

                                        <div className="max-h-60 space-y-2 overflow-y-auto pr-1">
                                          {/* Filter deals by selected stages if this is the No Outcome category and filters are active */}
                                          {(() => {
                                            let dealsToShow = [...outcomes?.[outcome]]
                                              .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

                                            // Apply stage filters if this is the No Outcome category and there are active filters
                                            if (outcome === 'No Outcome' && activeStageFilters.size > 0) {
                                              dealsToShow = dealsToShow.filter((deal) => activeStageFilters.has(deal.deal_stage));
                                            }

                                            if (dealsToShow.length === 0) {
                                              return (
                                                <div className="py-4 text-center text-xs text-muted-foreground">
                                                  No deals match the selected filters
                                                </div>
                                              );
                                            }

                                            return dealsToShow.map((deal) => (
                                              <div
                                                key={deal.id}
                                                className="flex cursor-pointer items-center justify-between rounded-md border border-muted bg-card p-2 hover:border-muted-foreground"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  navigateToDeal(deal.id);
                                                }}
                                              >
                                                <div className="space-y-1">
                                                  <div className="text-xs font-medium">
                                                    {deal.domains.length > 0
                                                      ? deal.domains[0]
                                                      : 'No domain specified'}
                                                    {deal.domains.length > 1 && ` +${deal.domains.length - 1} more`}
                                                  </div>
                                                  <div className="flex items-center gap-3 text-xs text-muted-foreground">
                                                    <span>
                                                      Created:
                                                      {formatDate(deal.created_at)}
                                                    </span>
                                                    <span className="flex items-center gap-1">
                                                      <Badge variant="outline" className="h-5 px-1 py-0 text-xs">
                                                        {deal.deal_stage}
                                                      </Badge>
                                                    </span>
                                                  </div>
                                                </div>
                                                <ExternalLink className="size-3 text-muted-foreground" />
                                              </div>
                                            ));
                                          })()}
                                        </div>
                                      </div>
                                    )
                                  : (
                                      <div className="py-2 text-center text-xs text-muted-foreground">
                                        No deals with this outcome
                                      </div>
                                    )}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )
                  : (
                      <div className="py-2 text-center text-muted-foreground">
                        <p>No outcomes recorded</p>
                      </div>
                    )}
              </div>
            )}
      </CardContent>
    </Card>
  );
}

function PipelineStageList({
  title,
  stages,
  total,
  deals,
  className,
  isLoading = false,
}: {
  title: string;
  stages?: DealStages;
  total?: number;
  deals?: { [stage: string]: Deal[] };
  className?: string;
  isLoading?: boolean;
}) {
  const navigate = useNavigate();
  const [expandedStage, setExpandedStage] = useState<string | null>(null);
  const [showEmptyStages, setShowEmptyStages] = useState(false);

  // Show all stages, not just active ones, but still sort by count
  const allStages = stages
    ? Object.entries(stages)
        .sort(([_, countA], [__, countB]) => countB - countA) // Sort by count (highest first)
    : [];

  // Filter stages based on showEmptyStages toggle
  const filteredStages = showEmptyStages
    ? allStages
    : allStages.filter(([_, count]) => count > 0);

  // Count how many stages have zero deals (for the toggle info)
  const emptyStageCount = allStages.filter(([_, count]) => count === 0).length;

  // Get deals for a specific stage
  const getDealsForStage = (stage: string) => {
    if (!deals || !deals[stage])
      return [];

    // Sort by created_at descending (newest first) before limiting
    return [...deals[stage]]
      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      .slice(0, 5); // Limit to 5 deals to prevent overwhelming the UI
  };

  // Navigate to deal details
  const navigateToDeal = (dealId: string) => {
    navigate(`/app/deals/${dealId}`);
  };

  // Format date for display
  const formatDate = (dateString: string) => {
    try {
      return format(new Date(dateString), 'MMM d, yyyy');
    } catch {
      return 'Invalid date';
    }
  };

  // Count how many stages have at least one deal
  const activeStageCount = stages ? Object.values(stages).filter((count) => count > 0).length : 0;

  return (
    <Card className={className}>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <div>
          <CardTitle className="text-sm font-medium">{title}</CardTitle>
          <CardDescription>
            {isLoading
              ? 'Loading...'
              : `${total || 0} Active Deals`}
          </CardDescription>
        </div>
        <div className="flex items-center gap-2">
          {!isLoading && emptyStageCount > 0 && (
            <Button
              variant="ghost"
              size="sm"
              className="h-7 px-2 text-xs"
              onClick={() => setShowEmptyStages(!showEmptyStages)}
            >
              {showEmptyStages
                ? 'Hide Empty Stages'
                : `Show ${emptyStageCount} Empty Stage${emptyStageCount === 1 ? '' : 's'}`}
            </Button>
          )}
          {!isLoading && (
            <Badge variant="outline" className="bg-blue-50 text-blue-800">
              {activeStageCount}
              {' '}
              Active Stages
            </Badge>
          )}
        </div>
      </CardHeader>
      <CardContent className="px-0">
        {isLoading
          ? (
              <div className="flex items-center justify-center py-8">
                <Loader2 className="size-5 animate-spin text-muted-foreground" />
              </div>
            )
          : (
              <div className="space-y-2">
                {filteredStages.length > 0
                  ? (
                      filteredStages.map(([stage, count]) => (
                        <div key={stage} className="border-b border-muted last:border-0">
                          <div
                            className={cn(
                              'flex cursor-pointer items-center justify-between px-4 py-2 hover:bg-muted/50',
                              count === 0 && 'opacity-70',
                            )}
                            onClick={() => setExpandedStage(expandedStage === stage ? null : stage)}
                          >
                            <div className="flex items-center gap-2">
                              <ChevronRight
                                className={cn(
                                  'size-4 transition-transform',
                                  expandedStage === stage ? 'rotate-90' : '',
                                )}
                              />
                              <span>{stage}</span>
                            </div>
                            <Badge
                              variant="outline"
                              className={cn(
                                'bg-blue-50',
                                count === 0 && 'bg-gray-50 text-gray-500',
                              )}
                            >
                              {count}
                            </Badge>
                          </div>

                          {expandedStage === stage && (
                            <div className="border-t border-muted bg-muted/30 px-4 py-2">
                              {deals && deals[stage] && count > 0
                                ? (
                                    <div className="max-h-80 space-y-2 overflow-y-auto pr-1">
                                      {[...deals[stage]]
                                        .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                                        .map((deal) => (
                                          <div
                                            key={deal.id}
                                            className="flex cursor-pointer items-center justify-between rounded-md border border-muted bg-card p-2 hover:border-muted-foreground"
                                            onClick={() => navigateToDeal(deal.id)}
                                          >
                                            <div className="space-y-1">
                                              <div className="font-medium">
                                                {deal.domains.length > 0
                                                  ? deal.domains[0]
                                                  : 'No domain specified'}
                                                {deal.domains.length > 1 && ` +${deal.domains.length - 1} more`}
                                              </div>
                                              <div className="flex items-center gap-3 text-xs text-muted-foreground">
                                                <span>
                                                  Created:
                                                  {formatDate(deal.created_at)}
                                                </span>
                                                {deal.partner && (
                                                  <span>
                                                    Partner:
                                                    {deal.partner.name}
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                            <ExternalLink className="size-4 text-muted-foreground" />
                                          </div>
                                        ))}
                                    </div>
                                  )
                                : (
                                    <div className="py-2 text-center text-muted-foreground">
                                      No deals in this stage
                                    </div>
                                  )}
                            </div>
                          )}
                        </div>
                      ))
                    )
                  : (
                      <div className="py-4 text-center text-muted-foreground">
                        {allStages.length > 0
                          ? (
                              'No active stages in this pipeline'
                            )
                          : (
                              'No stages defined for this pipeline'
                            )}
                      </div>
                    )}
              </div>
            )}
      </CardContent>
      <CardFooter className="border-t pt-2">
        <Button asChild variant="ghost" className="h-8 w-full text-xs" disabled={isLoading}>
          <Link to="/app/deals">
            View All Deals
          </Link>
        </Button>
      </CardFooter>
    </Card>
  );
}

// Display deal domains for a task
function TaskDealInfo({ dealId }: { dealId: string }) {
  const { data: deal, isLoading } = useDeal(dealId);

  if (isLoading) {
    return <span>Loading deal...</span>;
  }

  if (!deal) {
    return (
      <span>
        Deal:
        {dealId}
      </span>
    );
  }

  return (
    <span className="font-medium">
      {deal.domains.length > 0
        ? deal.domains[0]
        : 'No domain specified'}
      {deal.domains.length > 1 && ` +${deal.domains.length - 1} more`}
    </span>
  );
}

function TasksList({ className }: { className?: string }) {
  const { data: assignedTasks, isLoading: isLoadingTasks } = useAssignedTasks();
  const navigate = useNavigate();
  const [showCompleted, setShowCompleted] = useState(false);

  // Filter tasks to get pending and overdue first, then completed if showCompleted is true
  const filteredTasks = assignedTasks
    ? assignedTasks
      .filter((task) => !task.done || showCompleted)
      .sort((a, b) => {
        // Sort by status (Overdue → Pending → Completed)
        if (!a.done && isPast(new Date(a.due)) && (!b.done || !isPast(new Date(b.due))))
          return -1;
        if ((!a.done && !isPast(new Date(a.due))) && b.done)
          return -1;
        if (a.done && !b.done)
          return 1;

        // Secondary sort by due date (earliest first)
        return new Date(a.due).getTime() - new Date(b.due).getTime();
      })
      .slice(0, 5) as EnrichedTask[] // Cast to EnrichedTask[]
    : [];

  const pendingCount = assignedTasks ? assignedTasks.filter((task) => !task.done).length : 0;
  const overdueCount = assignedTasks ? assignedTasks.filter((task) => !task.done && isPast(new Date(task.due))).length : 0;

  // Function to format date - today, tomorrow, or date format
  const formatDueDate = (dateString: string) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const dueDate = new Date(dateString);
    dueDate.setHours(0, 0, 0, 0);

    if (dueDate.getTime() === today.getTime())
      return 'Today';
    if (dueDate.getTime() === tomorrow.getTime())
      return 'Tomorrow';

    return format(new Date(dateString), 'MMM d, yyyy');
  };

  // Function to determine task status based on done and due date
  const getTaskStatus = (task: EnrichedTask) => {
    if (task.done) {
      return { label: 'Completed', color: 'text-green-600', icon: CheckCircle };
    }

    if (isPast(new Date(task.due))) {
      return { label: 'Overdue', color: 'text-red-600', icon: AlertTriangle };
    }

    return { label: 'Pending', color: 'text-amber-600', icon: Clock };
  };

  // Navigate to the deal associated with the task
  const handleTaskClick = (dealId: string) => {
    navigate(`/app/deals/${dealId}`);
  };

  return (
    <Card className={className}>
      <CardHeader className="px-6 pt-6">
        <div className="flex items-center justify-between">
          <div>
            <CardTitle>Your Tasks</CardTitle>
            <CardDescription>
              Recent and upcoming tasks assigned to you
            </CardDescription>
          </div>
          <div className="flex items-center gap-3">
            {(pendingCount > 0 || overdueCount > 0) && (
              <Badge variant="outline" className="bg-red-50 text-red-800">
                {overdueCount > 0
                  ? `${overdueCount} Overdue`
                  : `${pendingCount} Pending`}
              </Badge>
            )}
            <Button
              variant="ghost"
              size="sm"
              className="h-7 px-2 text-xs"
              onClick={() => setShowCompleted(!showCompleted)}
            >
              {showCompleted ? 'Hide Completed' : 'Show Completed'}
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardContent className="px-6">
        {isLoadingTasks
          ? (
              <div className="flex items-center justify-center py-8">
                <Loader2 className="size-5 animate-spin text-muted-foreground" />
              </div>
            )
          : (
              filteredTasks.length > 0
                ? (
                    <div className="space-y-3">
                      {filteredTasks.map((task) => {
                        const status = getTaskStatus(task);
                        const assignedUsers = task.assigned_to_users || [];

                        return (
                          <div
                            key={task.id}
                            className="flex cursor-pointer items-center justify-between rounded-md border border-muted p-3 hover:border-muted-foreground"
                            onClick={() => handleTaskClick(task.deal_id)}
                          >
                            <div className="flex-1 space-y-1">
                              <div className="flex flex-col gap-1">
                                <div className="font-medium">{task.task}</div>
                                <TaskDealInfo dealId={task.deal_id} />
                              </div>
                              <div className="flex flex-wrap items-center gap-3 text-xs text-muted-foreground">
                                <span className="flex items-center gap-1">
                                  <status.icon className={cn('size-3', status.color)} />
                                  <span className={status.color}>{status.label}</span>
                                </span>
                                <span>
                                  Due:
                                  {' '}
                                  {formatDueDate(task.due)}
                                </span>
                                {assignedUsers.length > 0 && (
                                  <span>
                                    Assigned:
                                    {' '}
                                    {assignedUsers
                                      .filter(Boolean)
                                      .map((user) =>
                                        `${user?.firstName || ''} ${user?.lastName || ''}`.trim())
                                      .join(', ')}
                                  </span>
                                )}
                              </div>
                            </div>
                            <ExternalLink className="size-4 text-muted-foreground" />
                          </div>
                        );
                      })}
                    </div>
                  )
                : (
                    <div className="py-8 text-center text-muted-foreground">
                      {pendingCount === 0 && overdueCount === 0
                        ? (
                            <>
                              <div className="mb-2 flex justify-center">
                                <CheckCircle className="size-8 text-green-600" />
                              </div>
                              <p>You have no pending tasks!</p>
                            </>
                          )
                        : (
                            <p>No tasks match the current filter</p>
                          )}
                    </div>
                  )
            )}
      </CardContent>
      <CardFooter className="border-t px-6 pt-4">
        <Button asChild variant="ghost" className="h-8 w-full text-xs">
          <Link to="/app/tasks" className="flex items-center justify-center gap-2">
            <ClipboardList className="size-4" />
            View All Tasks
          </Link>
        </Button>
      </CardFooter>
    </Card>
  );
}

function Dashboard() {
  const { user, isLoaded } = useUser();
  const [showContent, setShowContent] = useState(false);
  const { data: dashboardData, isLoading: isLoadingStats, isFetching, refetch } = useDashboardStats();

  // Trigger animation after content is loaded
  useEffect(() => {
    if (isLoaded) {
      // Short delay to ensure DOM is ready
      const timer = setTimeout(() => setShowContent(true), 100);
      return () => clearTimeout(timer);
    }
  }, [isLoaded]);

  const stats = dashboardData?.stats;
  const pendingCreateDomains = dashboardData?.pendingCreateDomains;
  const annualVerification = dashboardData?.annualVerification;
  const newBusiness = dashboardData?.newBusiness;
  const pipelines = dashboardData?.pipelines;

  // Check if we have valid stats data (to avoid "Cannot read properties of undefined" errors)
  const hasValidStats = !!(stats?.bank && stats?.insurance && stats?.total);

  // Check if we have any pending domains to show
  const hasBankPending = !!(pendingCreateDomains?.bank?.length);
  const hasInsurancePending = !!(pendingCreateDomains?.insurance?.length);
  const hasPendingDomains = hasBankPending || hasInsurancePending;

  // Function to determine color based on status
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'on track': {
        return 'text-green-600';
      }
      case 'ahead': {
        return 'text-green-600';
      }
      case 'slightly behind': {
        return 'text-amber-600';
      }
      case 'behind': {
        return 'text-orange-600';
      }
      case 'significantly behind': {
        return 'text-red-600';
      }
      default: {
        return 'text-blue-600';
      }
    }
  };

  // Function to determine progress bar color
  const getProgressColor = (ratio: number) => {
    if (ratio >= 1.1)
      return 'bg-green-600'; // Ahead
    if (ratio >= 0.95)
      return 'bg-green-500'; // On track
    if (ratio >= 0.8)
      return 'bg-amber-500'; // Slightly behind
    if (ratio >= 0.5)
      return 'bg-orange-500'; // Behind
    return 'bg-red-500'; // Significantly behind
  };

  return (
    <Container className="pb-12">
      <div className="mx-auto max-w-7xl">
        {/* Greeting section */}
        {isLoaded
          ? (
              <div
                className={cn(
                  'mb-8 transform transition-all duration-700 ease-in-out',
                  showContent
                    ? 'translate-y-0 opacity-100'
                    : 'translate-y-4 opacity-0',
                )}
              >
                <h1 className="mb-2 font-serif text-4xl">
                  {getGreeting()}
                  {user?.firstName ? `, ${user?.firstName}` : ''}
                </h1>
                <p className="text-muted-foreground">
                  Welcome to your dashboard. Here's what's happening today.
                </p>
              </div>
            )
          : (
              <div className="mb-8">
                <h1 className="mb-2 font-serif text-4xl">Loading...</h1>
              </div>
            )}

        {/* Domain statistics section */}
        <div className="mb-6 grid gap-4 md:grid-cols-3">
          <Card
            className={cn(
              'col-span-3 p-6 transition-all duration-700 ease-in-out delay-300',
              showContent
                ? 'translate-y-0 opacity-100'
                : 'translate-y-4 opacity-0',
            )}
          >
            <CardHeader className="px-0 pt-0">
              <div className="flex items-center justify-between">
                <CardTitle>Domain Statistics</CardTitle>
                <div className="flex items-center gap-4">
                  {isFetching && !isLoadingStats && (
                    <div className="flex items-center gap-2 text-sm text-muted-foreground">
                      <Loader2 className="size-4 animate-spin" />
                      <span>Fetching latest stats...</span>
                    </div>
                  )}
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={async () => refetch()}
                    disabled={isFetching}
                    className="h-8 gap-2"
                  >
                    <RotateCcw className="size-4" />
                    Refresh
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardContent className="px-0 pb-0">
              {isLoadingStats
                ? (
                    <p>Loading domain statistics...</p>
                  )
                : (hasValidStats
                    ? (
                        <div className="grid gap-4 md:grid-cols-3">
                          <DomainStatCard
                            title=".Bank Domains"
                            icon={Building2}
                            active={stats.bank.active}
                            inactive={stats.bank.inactive}
                            pendingCreate={stats.bank.pendingCreate}
                            total={stats.bank.active + stats.bank.inactive + stats.bank.pendingCreate}
                          />
                          <DomainStatCard
                            title=".Insurance Domains"
                            icon={Shield}
                            active={stats.insurance.active}
                            inactive={stats.insurance.inactive}
                            pendingCreate={stats.insurance.pendingCreate}
                            total={stats.insurance.active + stats.insurance.inactive + stats.insurance.pendingCreate}
                          />
                          <DomainStatCard
                            title="All Domains"
                            icon={Globe}
                            active={stats.total.active}
                            inactive={stats.total.inactive}
                            pendingCreate={stats.total.pendingCreate}
                            total={stats.total.all}
                            className="bg-muted/50"
                          />
                        </div>
                      )
                    : (
                        <p>Failed to load domain statistics or data is incomplete.</p>
                      ))}
            </CardContent>
          </Card>
        </div>

        {/* Pending domains section - always show even when there are no pending domains */}
        <Card
          className={cn(
            'mb-6 p-6 transition-all duration-700 ease-in-out delay-400',
            showContent
              ? 'translate-y-0 opacity-100'
              : 'translate-y-4 opacity-0',
          )}
        >
          <CardHeader className="px-0 pt-0">
            <div className="flex items-center justify-between">
              <div>
                <CardTitle>Pending Create Domains</CardTitle>
                <CardDescription>
                  Domains that are in the process of being created
                </CardDescription>
              </div>
              {hasPendingDomains && (
                <div className="flex items-center gap-3">
                  <Badge variant="outline" className="bg-blue-50 text-blue-800">
                    {hasBankPending && hasInsurancePending
                      ? `${pendingCreateDomains.bank.length + pendingCreateDomains.insurance.length} Domains`
                      : (hasBankPending
                          ? `${pendingCreateDomains.bank.length} Domains`
                          : `${pendingCreateDomains.insurance.length} Domains`)}
                  </Badge>
                </div>
              )}
            </div>
          </CardHeader>
          <CardContent className="px-0 pb-0">
            <PendingCreateDomainsList
              bankDomains={pendingCreateDomains?.bank}
              insuranceDomains={pendingCreateDomains?.insurance}
            />
          </CardContent>
        </Card>

        {/* Annual Verification Progress section */}
        {annualVerification && (
          <Card
            className={cn(
              'mb-6 p-6 transition-all duration-700 ease-in-out delay-500',
              showContent
                ? 'translate-y-0 opacity-100'
                : 'translate-y-4 opacity-0',
            )}
          >
            <CardHeader className="px-0 pt-0">
              <div className="flex items-center justify-between">
                <div>
                  <CardTitle>Annual Verifications</CardTitle>
                  <CardDescription>
                    Tracking annual verification progress for all domains
                  </CardDescription>
                </div>
                <div className="flex items-center gap-3">
                  <Badge
                    variant="outline"
                    className={cn(
                      'bg-blue-50',
                      getStatusColor(annualVerification.status),
                    )}
                  >
                    <CheckCircle2 className="mr-1 size-3" />
                    {annualVerification.percentComplete.toFixed(1)}
                    % Complete
                  </Badge>
                </div>
              </div>
            </CardHeader>
            <CardContent className="px-0 pb-0">
              <div className="space-y-5">
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <div>
                      Progress:
                      {annualVerification.percentComplete.toFixed(1)}
                      %
                    </div>
                    <div>
                      Year elapsed:
                      {annualVerification.percentOfYearElapsed.toFixed(1)}
                      %
                    </div>
                  </div>
                  <div className="relative">
                    <Progress
                      value={annualVerification.percentComplete}
                      className={getProgressColor(annualVerification.progressRatio)}
                    />
                    <div
                      className="absolute inset-y-0 w-0.5 bg-gray-800"
                      style={{ left: `${annualVerification.percentOfYearElapsed}%` }}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-4">
                  <div className="flex flex-col">
                    <div className="text-2xl font-bold">{annualVerification.initiated}</div>
                    <p className="text-sm text-muted-foreground">Initiated</p>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-2xl font-bold">{annualVerification.remaining}</div>
                    <p className="text-sm text-muted-foreground">Remaining</p>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-2xl font-bold">{annualVerification.needed}</div>
                    <p className="text-sm text-muted-foreground">Total Needed</p>
                  </div>
                  <div className="flex flex-col">
                    <div className={cn(
                      'text-2xl font-bold capitalize',
                      getStatusColor(annualVerification.status),
                    )}
                    >
                      {annualVerification.status}
                    </div>
                    <p className="text-sm text-muted-foreground">Current Status</p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        )}

        {/* New Business Won Percentages - Always visible */}
        <Card
          className={cn(
            'mb-6 p-6 transition-all duration-700 ease-in-out delay-550',
            showContent
              ? 'translate-y-0 opacity-100'
              : 'translate-y-4 opacity-0',
          )}
        >
          <CardHeader className="px-0 pt-0">
            <div className="flex items-center justify-between">
              <div>
                <CardTitle>New Business Metrics</CardTitle>
                <CardDescription>
                  Month-to-date and year-to-date performance
                </CardDescription>
              </div>
              {newBusiness && (
                <Badge variant="outline" className="bg-blue-50 text-blue-800">
                  <TrendingUp className="mr-1 size-3" />
                  {Object.values(newBusiness.ytd)
                    .flat()
                    .length}
                  {' '}
                  Total YTD Deals
                </Badge>
              )}
            </div>
          </CardHeader>
          <CardContent className="px-0 pb-0">
            <div className="grid gap-4 sm:grid-cols-2">
              <BusinessMetricsCard
                title="Month to Date"
                icon={CalendarClock}
                outcomes={newBusiness?.mtd}
                isLoading={isLoadingStats || !newBusiness}
              />
              <BusinessMetricsCard
                title="Year to Date"
                icon={Calendar}
                outcomes={newBusiness?.ytd}
                className="bg-muted/50"
                isLoading={isLoadingStats || !newBusiness}
              />
            </div>
          </CardContent>
        </Card>

        {/* Deal Pipelines - Always visible */}
        <Card
          className={cn(
            'mb-6 p-6 transition-all duration-700 ease-in-out delay-600',
            showContent
              ? 'translate-y-0 opacity-100'
              : 'translate-y-4 opacity-0',
          )}
        >
          <CardHeader className="px-0 pt-0">
            <div className="flex items-center justify-between">
              <div>
                <CardTitle>Active Deal Pipelines</CardTitle>
                <CardDescription>
                  Current deals by pipeline and stage
                </CardDescription>
              </div>
              {pipelines && (
                <Badge variant="outline" className="bg-green-50 text-green-800">
                  <Briefcase className="mr-1 size-3" />
                  {Object.values(pipelines).reduce((sum, pipeline) => sum + pipeline.total, 0)}
                  {' '}
                  Total Deals
                </Badge>
              )}
            </div>
          </CardHeader>
          <CardContent className="px-0 pb-0">
            <div className="grid gap-6 md:grid-cols-2">
              <PipelineStageList
                title="New Business"
                stages={pipelines?.['New Business'].stages}
                total={pipelines?.['New Business'].total}
                deals={pipelines?.['New Business'].deals}
                isLoading={isLoadingStats || !pipelines}
              />
              <PipelineStageList
                title="Annual Verification"
                stages={pipelines?.['Annual Verification'].stages}
                total={pipelines?.['Annual Verification'].total}
                deals={pipelines?.['Annual Verification'].deals}
                isLoading={isLoadingStats || !pipelines}
              />
              <PipelineStageList
                title="Existing Business"
                stages={pipelines?.['Existing Business'].stages}
                total={pipelines?.['Existing Business'].total}
                deals={pipelines?.['Existing Business'].deals}
                isLoading={isLoadingStats || !pipelines}
              />
              <PipelineStageList
                title="Forced Verification"
                stages={pipelines?.['Forced Verification'].stages}
                total={pipelines?.['Forced Verification'].total}
                deals={pipelines?.['Forced Verification'].deals}
                isLoading={isLoadingStats || !pipelines}
              />
            </div>
          </CardContent>
        </Card>

        {/* Tasks Section - Always visible */}
        <Card
          className={cn(
            'mb-6 transition-all duration-700 ease-in-out delay-650',
            showContent
              ? 'translate-y-0 opacity-100'
              : 'translate-y-4 opacity-0',
          )}
        >
          <CardHeader className="px-6 pt-6">
            <div className="flex items-center justify-between">
              <div>
                <CardTitle>Tasks</CardTitle>
                <CardDescription>
                  Manage your upcoming tasks and deadlines
                </CardDescription>
              </div>
            </div>
          </CardHeader>
          <CardContent className="px-6">
            <TasksList />
          </CardContent>
        </Card>
      </div>
    </Container>
  );
}

export default Dashboard;
