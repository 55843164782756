import React, { useId } from 'react';
import type { Control, FieldValues, Path, RegisterOptions } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { cn } from '@/lib/utils';

/**
 * Props for the FormSwitch component.
 * @template TFieldValues - The type of the form values.
 */
export type FormSwitchProps<TFieldValues extends FieldValues> = {
  /**
   * Unique identifier for the switch. If not provided, a unique ID will be generated.
   */
  id?: string;
  /**
   * The name of the field in the form.
   */
  name: Path<TFieldValues>;
  /**
   * Label text for the switch.
   */
  label: string;
  /**
   * Optional helper text to provide additional information.
   */
  helperText?: string;
  /**
   * The control object from react-hook-form.
   */
  control?: Control<TFieldValues>;
  /**
   * Validation rules for the field.
   */
  rules?: Omit<RegisterOptions<TFieldValues, Path<TFieldValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  /**
   * Additional class names for styling the component.
   */
  className?: string;
  /**
   * Whether the switch is disabled. Defaults to false.
   */
  disabled?: boolean;
};

/**
 * A form-aware switch component integrated with react-hook-form and shadcn/ui.
 * Supports validation, error handling, and accessibility features.
 *
 * @template TFieldValues - The type of the form values.
 * @param props - The props for the FormSwitch component.
 * @param ref - Forwarded ref to the switch element.
 * @returns A React element representing the form switch.
 */
const FormSwitch = React.forwardRef(<TFieldValues extends FieldValues>(
  {
    id,
    name,
    label,
    helperText,
    control,
    rules,
    className,
    disabled = false,
  }: FormSwitchProps<TFieldValues>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) => {
  const generatedId = useId();
  const switchId = id || generatedId;
  const helperId = useId();
  const errorId = useId();
  const { field, fieldState } = useController({
    name,
    control,
    rules,
  });
  const describedBy = fieldState.error ? errorId : (helperText ? helperId : undefined);
  return (
    <div className={cn('space-y-2', className)}>
      <div className="flex items-center justify-between">
        <Label htmlFor={switchId} className="text-sm font-medium">
          {label}
        </Label>
        <Switch
          id={switchId}
          ref={ref}
          checked={field.value}
          onCheckedChange={field.onChange}
          disabled={disabled}
          aria-invalid={fieldState.invalid ? 'true' : undefined}
          aria-disabled={disabled ? 'true' : undefined}
          aria-describedby={describedBy}
        />
      </div>
      {fieldState.error
        ? (
          <p id={errorId} className="text-xs text-destructive">
            {fieldState.error.message}
          </p>
        )
        : (helperText
          ? (
            <p id={helperId} className="text-xs text-gray-500">
              {helperText}
            </p>
          )
          : null)}
    </div>
  );
}) as <TFieldValues extends FieldValues>(
  props: FormSwitchProps<TFieldValues> & { ref?: React.ForwardedRef<HTMLButtonElement> }
) => React.ReactElement;

export default FormSwitch;
