import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import type { RawChange } from '@/hooks/use-raw-changes';

type ChangeSheetProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  change?: RawChange;
};

function ChangeSheet({ open, onOpenChange, change }: ChangeSheetProps) {
  if (!change) {
    return null;
  }

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side="right"
        className="w-full max-w-full sm:max-w-lg"
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <SheetHeader>
          <SheetTitle>Contact Change Details</SheetTitle>
        </SheetHeader>
        <div className="h-[calc(100vh-8rem)] space-y-6 overflow-y-auto px-1 pt-8">
          <div className="space-y-3">
            <h3 className="text-lg font-medium">Domain Information</h3>
            <div className="grid grid-cols-2 gap-x-4 gap-y-2 rounded-md border p-4">
              <div className="text-sm font-medium">Domain Name:</div>
              <div className="text-sm">{change.domain}</div>
              <div className="text-sm font-medium">Domain ROID:</div>
              <div className="text-sm">{change.data.domainRoid}</div>
              <div className="text-sm font-medium">Contact ROID:</div>
              <div className="text-sm">{change.data.contactRoid}</div>
              <div className="text-sm font-medium">Created:</div>
              <div className="text-sm">{change.data.domainCreatedOn}</div>
              <div className="text-sm font-medium">Expires:</div>
              <div className="text-sm">{change.data.domainExpireOn}</div>
              <div className="text-sm font-medium">Updated:</div>
              <div className="text-sm">{change.data.domainUpdatedOn}</div>
              <div className="text-sm font-medium">Transaction ID:</div>
              <div className="text-sm">{change.data.serverTransactionId}</div>
            </div>
          </div>

          <div className="space-y-3">
            <h3 className="text-lg font-medium">Registrar Information</h3>
            <div className="grid grid-cols-2 gap-x-4 gap-y-2 rounded-md border p-4">
              <div className="text-sm font-medium">Registrar:</div>
              <div className="text-sm">{change.data.domainSponsorName}</div>
              <div className="text-sm font-medium">IANA ID:</div>
              <div className="text-sm">{change.data.domainSponsorIanaId}</div>
            </div>
          </div>

          <div className="space-y-3">
            <h3 className="text-lg font-medium">Registrant Information</h3>
            <div className="grid grid-cols-2 gap-x-4 gap-y-2 rounded-md border p-4">
              <div className="text-sm font-medium">Name (Int):</div>
              <div className="text-sm">{change.data.registrantIntName}</div>
              {change.data.registrantLocName && (
                <>
                  <div className="text-sm font-medium">Name (Loc):</div>
                  <div className="text-sm">{change.data.registrantLocName}</div>
                </>
              )}
              <div className="text-sm font-medium">Organization (Int):</div>
              <div className="text-sm">{change.data.registrantIntOrganization}</div>
              {change.data.registrantLocOrganization && (
                <>
                  <div className="text-sm font-medium">Organization (Loc):</div>
                  <div className="text-sm">{change.data.registrantLocOrganization}</div>
                </>
              )}
              <div className="text-sm font-medium">Email:</div>
              <div className="text-sm">{change.data.registrantEmail}</div>
              <div className="text-sm font-medium">Phone:</div>
              <div className="text-sm">{change.data.registrantPhone}</div>
              {change.data.registrantPhoneExt && (
                <>
                  <div className="text-sm font-medium">Phone Ext:</div>
                  <div className="text-sm">{change.data.registrantPhoneExt}</div>
                </>
              )}
              {change.data.registrantFax && (
                <>
                  <div className="text-sm font-medium">Fax:</div>
                  <div className="text-sm">{change.data.registrantFax}</div>
                </>
              )}
              {change.data.registrantFaxExt && (
                <>
                  <div className="text-sm font-medium">Fax Ext:</div>
                  <div className="text-sm">{change.data.registrantFaxExt}</div>
                </>
              )}
            </div>
          </div>

          <div className="space-y-3">
            <h3 className="text-lg font-medium">Internationalized Address</h3>
            <div className="grid grid-cols-2 gap-x-4 gap-y-2 rounded-md border p-4">
              <div className="text-sm font-medium">Street 1:</div>
              <div className="text-sm">{change.data.registrantIntAddressStreet1}</div>
              {change.data.registrantIntAddressStreet2 && (
                <>
                  <div className="text-sm font-medium">Street 2:</div>
                  <div className="text-sm">{change.data.registrantIntAddressStreet2}</div>
                </>
              )}
              {change.data.registrantIntAddressStreet3 && (
                <>
                  <div className="text-sm font-medium">Street 3:</div>
                  <div className="text-sm">{change.data.registrantIntAddressStreet3}</div>
                </>
              )}
              <div className="text-sm font-medium">City:</div>
              <div className="text-sm">{change.data.registrantIntCity}</div>
              {change.data.registrantIntState && (
                <>
                  <div className="text-sm font-medium">State/Province:</div>
                  <div className="text-sm">{change.data.registrantIntState}</div>
                </>
              )}
              <div className="text-sm font-medium">Postal Code:</div>
              <div className="text-sm">{change.data.registrantIntPostCode}</div>
              <div className="text-sm font-medium">Country:</div>
              <div className="text-sm">{change.data.registrantIntCountryCode}</div>
            </div>
          </div>

          {change.data.registrantLocAddressStreet1 && (
            <div className="space-y-3">
              <h3 className="text-lg font-medium">Localized Address</h3>
              <div className="grid grid-cols-2 gap-x-4 gap-y-2 rounded-md border p-4">
                <div className="text-sm font-medium">Street 1:</div>
                <div className="text-sm">{change.data.registrantLocAddressStreet1}</div>
                {change.data.registrantLocAddressStreet2 && (
                  <>
                    <div className="text-sm font-medium">Street 2:</div>
                    <div className="text-sm">{change.data.registrantLocAddressStreet2}</div>
                  </>
                )}
                {change.data.registrantLocAddressStreet3 && (
                  <>
                    <div className="text-sm font-medium">Street 3:</div>
                    <div className="text-sm">{change.data.registrantLocAddressStreet3}</div>
                  </>
                )}
                <div className="text-sm font-medium">City:</div>
                <div className="text-sm">{change.data.registrantLocCity}</div>
                {change.data.registrantLocState && (
                  <>
                    <div className="text-sm font-medium">State/Province:</div>
                    <div className="text-sm">{change.data.registrantLocState}</div>
                  </>
                )}
                <div className="text-sm font-medium">Postal Code:</div>
                <div className="text-sm">{change.data.registrantLocPostCode}</div>
                <div className="text-sm font-medium">Country:</div>
                <div className="text-sm">{change.data.registrantLocCountryCode}</div>
              </div>
            </div>
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
}

export default ChangeSheet;
