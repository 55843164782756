import { z } from 'zod';
import type { CreateContactInput, DomainAssociationType } from './contact';

// Domain association schema matching the types
const domainAssociationSchema = z.object({
  domain: z.string().min(1, 'Domain is required'),
  type: z.enum(['registrant', 'verification'] as const satisfies readonly DomainAssociationType[], {
    required_error: 'Contact type is required',
  }),
});

// License schema matching the types
const contactLicenseSchema = z.object({
  authority: z.string().min(1, 'Authority is required'),
  value: z.string().min(1, 'Value is required'),
});

// Schema that matches CreateContactInput type
export const createContactSchema = z.object({
  legal_name: z.string().min(1, 'RDDS Name is required'),
  email: z.string().email('Invalid email address'),
  phone_number: z.string().min(1, 'Phone number is required'),
  alt_phone: z.string().nullable(),
  fax_number: z.string().nullable(),
  address_line_1: z.string().min(1, 'Address is required'),
  address_line_2: z.string().nullable(),
  address_line_3: z.string().nullable(),
  city: z.string().min(1, 'City is required'),
  state_province: z.string().nullable(),
  postal_code: z.string().nullable(),
  country: z.string().min(1, 'Country is required'),
  alt_name: z.string().nullable(),
  alt_email: z.string().nullable(),
  title: z.string().nullable(),
  role_name: z.string().nullable(),
  organization_legal_name: z.string(),
  c_suite: z.boolean().default(false),
  sole_prop: z.boolean().default(false),
  new_legal_name: z.boolean().default(false),
  domain_associations: z.array(domainAssociationSchema).optional(),
  licenses: z.array(contactLicenseSchema).optional(),
}) satisfies z.ZodType<CreateContactInput>;

// Export the form data type derived from our schema
export type ContactFormData = z.infer<typeof createContactSchema>;
