import { format } from 'date-fns';
import { Globe, Calendar, CheckCircle2, XCircle, Server, ChevronDown } from 'lucide-react';
import { toast } from 'sonner';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { useDeal as useDealContext } from '@/contexts/deal-context';
import { useDeal as useDealData } from '@/hooks/use-deals';
import { useDomainInfos } from '@/hooks/use-domain-info';
import type { Contact } from '@/hooks/use-domain-info';

function ContactInfo({ contact, title }: { contact: Contact; title: string }) {
  return (
    <div className="space-y-4">
      <div>
        <div className="text-sm font-medium text-muted-foreground">{title}</div>
        <div className="font-medium">{contact.Name}</div>
        <div className="text-sm text-muted-foreground">{contact.Organisation}</div>
      </div>
      <div>
        <div className="text-sm font-medium text-muted-foreground">Address</div>
        <div className="text-sm">
          {contact.Address.Street.filter(Boolean).join(', ')}
          <br />
          {contact.Address.City}
          ,
          {contact.Address['State/Province']}
          {' '}
          {contact.Address['Postal Code']}
          <br />
          {contact.Address.Country}
        </div>
      </div>
      <div className="grid gap-2 sm:grid-cols-2">
        <div>
          <div className="text-sm font-medium text-muted-foreground">Phone</div>
          <div className="text-sm">{contact.Phone.Number}</div>
        </div>
        {contact.Fax?.Number && (
          <div>
            <div className="text-sm font-medium text-muted-foreground">Fax</div>
            <div className="text-sm">{contact.Fax.Number}</div>
          </div>
        )}
      </div>
      <div>
        <div className="text-sm font-medium text-muted-foreground">Email</div>
        <div className="text-sm">{contact.Email}</div>
      </div>
    </div>
  );
}

export function RDDSPanel() {
  const { dealId } = useDealContext();
  const { data: deal } = useDealData(dealId!);
  const { data: domainInfos = {}, isLoading, error } = useDomainInfos(deal?.domains ?? []);

  if (error) {
    toast.error('Failed to load domain information');
  }

  if (!deal?.domains || isLoading || !domainInfos) {
    return (
      <div className="flex h-full items-center justify-center">
        <div className="text-sm text-muted-foreground">Loading domain information...</div>
      </div>
    );
  }

  return (
    <ScrollArea className="h-full">
      <div className="mx-auto max-w-2xl space-y-4 p-4">
        <Accordion type="single" collapsible className="w-full">
          {[...(deal.domains ?? [])].sort().map((domain, index) => {
            const domainInfo = domainInfos[domain];
            if (!domainInfo) {
              return (
                <AccordionItem key={domain} value={`domain-${index}`}>
                  <AccordionTrigger className="px-4 py-2 hover:no-underline">
                    <div className="flex items-center gap-2">
                      <Globe className="size-4" />
                      <span>{domain}</span>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="space-y-4 px-4 pb-4">
                    <div className="text-sm text-muted-foreground">
                      No RDDS information available for this domain.
                    </div>
                  </AccordionContent>
                </AccordionItem>
              );
            }

            return (
              <AccordionItem key={domain} value={`domain-${index}`}>
                <AccordionTrigger className="px-4 py-2 hover:no-underline">
                  <div className="flex items-center gap-2">
                    <Globe className="size-4" />
                    <span style={domainInfo?.colorCode ? { color: domainInfo.colorCode } : undefined}><b>{domain}</b></span>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="space-y-4 px-4 pb-4">
                  <Accordion type="multiple" className="w-full" defaultValue={['registrant', 'info']}>
                    {/* Registrant Contact */}
                    <AccordionItem value="registrant">
                      <AccordionTrigger className="py-2 hover:no-underline">
                        <div className="flex items-center gap-2">
                          <span>Registrant Contact</span>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className="pb-4">
                        <ContactInfo contact={domainInfo['Registrant Contact']} title="Registrant Contact" />
                      </AccordionContent>
                    </AccordionItem>

                    {/* Domain Info & EPP Status */}
                    <AccordionItem value="info">
                      <AccordionTrigger className="py-2 hover:no-underline">
                        <div className="flex items-center gap-2">
                          <Globe className="size-4" />
                          <span>Domain Information & Status</span>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className="pb-4">
                        <div className="space-y-4">
                          <div className="grid gap-4">
                            <div>
                              <div className="text-sm font-medium text-muted-foreground">Domain Name</div>
                              <div>{domainInfo['Domain Name']['DNS form']}</div>
                            </div>
                            <div>
                              <div className="text-sm font-medium text-muted-foreground">Registry Status</div>
                              <div>{domainInfo['Registry Status']}</div>
                            </div>
                            <div>
                              <div className="text-sm font-medium text-muted-foreground">Sponsoring Registrar</div>
                              <div>{domainInfo['Sponsoring Registrar'] || 'N/A'}</div>
                            </div>
                          </div>
                          <div className="space-y-2">
                            <div className="text-sm font-medium text-muted-foreground">EPP Status Codes</div>
                            {(domainInfo['EPP Statuses'] || []).map((status) => (
                              <div key={status} className="flex items-center gap-2">
                                <CheckCircle2 className="size-4 text-green-500" />
                                <span className="text-sm">{status}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </AccordionContent>
                    </AccordionItem>

                    {/* Important Dates */}
                    <AccordionItem value="dates">
                      <AccordionTrigger className="py-2 hover:no-underline">
                        <div className="flex items-center gap-2">
                          <Calendar className="size-4" />
                          <span>Important Dates</span>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className="pb-4">
                        <div className="grid gap-4">
                          {(['Creation Date', 'Last Updated Date', 'Expiry Date'] as const).map((dateField) => {
                            const dateString = domainInfo[dateField];
                            let formattedDate = 'N/A';

                            if (dateString) {
                              try {
                                const date = new Date(dateString);
                                formattedDate = Number.isNaN(date.getTime())
                                  ? 'Invalid date'
                                  : format(date, 'PPP');
                              } catch {
                                formattedDate = 'Invalid date format';
                              }
                            }

                            return (
                              <div key={dateField}>
                                <div className="text-sm font-medium text-muted-foreground">
                                  {dateField.replaceAll(/([A-Z])/g, ' $1').trim()}
                                </div>
                                <div>{formattedDate}</div>
                              </div>
                            );
                          })}
                        </div>
                      </AccordionContent>
                    </AccordionItem>

                    {/* Name Servers & DNSSEC */}
                    <AccordionItem value="nameservers">
                      <AccordionTrigger className="py-2 hover:no-underline">
                        <div className="flex items-center gap-2">
                          <Server className="size-4" />
                          <span>Name Servers & DNSSEC</span>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className="space-y-4 pb-4">
                        <div>
                          <div className="text-sm font-medium text-muted-foreground">Name Servers</div>
                          <div className="mt-2 space-y-2">
                            {(domainInfo['Name Servers'] || []).map((ns) => (
                              <div key={ns.ROID} className="rounded-md border bg-muted/40 p-2">
                                <div className="font-medium">{ns['Name Server Name']}</div>
                                <div className="mt-1 text-sm">
                                  {ns['IPv4 Addresses']?.length > 0 && (
                                    <div>
                                      IPv4:
                                      {ns['IPv4 Addresses'].join(', ')}
                                    </div>
                                  )}
                                  {ns['IPv6 Addresses']?.length > 0 && (
                                    <div>
                                      IPv6:
                                      {ns['IPv6 Addresses'].join(', ')}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div>
                          <div className="text-sm font-medium text-muted-foreground">DNSSEC</div>
                          {(domainInfo['DNSSEC Information']?.['DS Data items'] || []).map((item) => (
                            <div key={item['DS Data Name']} className="mt-2">
                              {item['DS Data Elements'].map((element, index) => (
                                <div key={index} className="text-sm">
                                  <div>
                                    Algorithm:
                                    {element.Algorithm}
                                  </div>
                                  <div>
                                    Key Tag:
                                    {element['Key Tag']}
                                  </div>
                                  <div>
                                    Digest Type:
                                    {element['Digest Type']}
                                  </div>
                                  <div className="font-mono text-xs">{element.Digest}</div>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </AccordionContent>
                    </AccordionItem>

                    {/* Additional Contacts */}
                    <AccordionItem value="contacts">
                      <AccordionTrigger className="py-2 hover:no-underline">
                        <div className="flex items-center gap-2">
                          <span>Additional Contacts</span>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className="space-y-6 pb-4">
                        {(domainInfo['Technical Contacts'] || []).map((contact, index) => (
                          <div key={contact.ROID}>
                            <ContactInfo contact={contact} title="Technical Contact" />
                            {index < domainInfo['Technical Contacts']?.length - 1 && <Separator className="my-6" />}
                          </div>
                        ))}
                        <Separator />
                        {(domainInfo['Admin Contacts'] || []).map((contact, index) => (
                          <div key={contact.ROID}>
                            <ContactInfo contact={contact} title="Admin Contact" />
                            {index < domainInfo['Admin Contacts']?.length - 1 && <Separator className="my-6" />}
                          </div>
                        ))}
                        <Separator />
                        {(domainInfo['Billing Contacts'] || []).map((contact, index) => (
                          <div key={contact.ROID}>
                            <ContactInfo contact={contact} title="Billing Contact" />
                            {index < domainInfo['Billing Contacts']?.length - 1 && <Separator className="my-6" />}
                          </div>
                        ))}
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    </ScrollArea>
  );
}
