import { useRef, useState } from 'react';
import type { GridApi, RowDoubleClickedEvent } from '@ag-grid-community/core';
import { RotateCcw } from 'lucide-react';
import AGDataGrid from '@/components/ag-data-grid';
import Container from '@/components/container';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useGrid } from '@/contexts/grid-context';
import { useRawChanges, type RawChange } from '@/hooks/use-raw-changes';
import { changeColumnDefs } from './components/change-grid-columns';
import ChangeSheet from './components/change-sheet';

function RawChangesPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedChange, setSelectedChange] = useState<RawChange | null>(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const gridApiRef = useRef<GridApi<RawChange> | null>(null);
  const { registerGridApi } = useGrid();

  const { data: changes, isLoading } = useRawChanges();

  const handleRowDoubleClick = (event: RowDoubleClickedEvent<RawChange>) => {
    if (event.data) {
      setSelectedChange(event.data);
      setIsSheetOpen(true);
    }
  };

  const handleSheetOpenChange = (open: boolean) => {
    setIsSheetOpen(open);
    if (!open) {
      setSelectedChange(null);
    }
  };

  const handleGridReady = (params: { api: GridApi<RawChange> }) => {
    gridApiRef.current = params.api;
    registerGridApi(params.api);
  };

  const clearFilters = () => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel(null);
      setSearchQuery('');
    }
  };

  return (
    <Container maxWidth={false} className="pb-12">
      <div className="mb-3 flex justify-between">
        <div>
          <h1 className="mt-2 text-xl font-bold">Raw Contact Changes</h1>
        </div>
      </div>
      <div className="mb-6 flex gap-4">
        <div className="flex-1">
          <Input
            type="search"
            placeholder="Search contact changes..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <Button
          variant="outline"
          onClick={clearFilters}
          className="gap-2"
        >
          <RotateCcw className="size-4" />
          Clear Filters
        </Button>
      </div>
      <div className={isLoading ? 'opacity-50' : ''}>
        <AGDataGrid
          columnDefs={changeColumnDefs}
          rowData={changes || []}
          quickFilterText={searchQuery}
          rowSelection={{ mode: 'singleRow' }}
          onRowDoubleClicked={handleRowDoubleClick}
          onGridReady={handleGridReady}
          overlayLoadingTemplate={
            isLoading ? '<span class="ag-overlay-loading-center">Loading...</span>' : undefined
          }
          overlayNoRowsTemplate={
            isLoading ? '' : '<span class="ag-overlay-no-rows-center">No contact changes found</span>'
          }
        />
      </div>

      <ChangeSheet
        open={isSheetOpen}
        onOpenChange={handleSheetOpenChange}
        change={selectedChange || undefined}
      />
    </Container>
  );
}

export default RawChangesPage;
