import type { ColDef } from '@ag-grid-community/core';
import type { RawTransaction } from '@/hooks/use-raw-transactions';

export const transactionColumnDefs: ColDef<RawTransaction>[] = [
  {
    field: 'data.transactionDate',
    headerName: 'Transaction Date',
    flex: 0,
    minWidth: 150,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      if (!params.value || Number.isNaN(new Date(params.value).getTime())) {
        return '---';
      }
      return new Date(params.value).toLocaleString();
    },
  },
  {
    field: 'domain',
    headerName: 'Domain',
    flex: 0,
    minWidth: 120,
    sortable: true,
    filter: true,
  },
  {
    field: 'data.operation',
    headerName: 'Operation',
    flex: 0,
    minWidth: 100,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return params.value || '---';
    },
  },
  {
    field: 'data.registrar',
    headerName: 'Registrar',
    flex: 0,
    minWidth: 200,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return params.value || '---';
    },
  },
  {
    field: 'data.cost',
    headerName: 'Cost (USD)',
    flex: 0,
    minWidth: 100,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      if (!params.value || Number.isNaN(params.value)) {
        return '---';
      }
      return `$${params.value.toFixed(2)}`;
    },
  },
  {
    field: 'data.period',
    headerName: 'Period (Years)',
    flex: 0,
    minWidth: 100,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return params.value || '---';
    },
  },
  {
    field: 'data.startDate',
    headerName: 'Start Date',
    flex: 0,
    minWidth: 150,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      if (!params.value || Number.isNaN(new Date(params.value).getTime())) {
        return '---';
      }
      return new Date(params.value).toLocaleString();
    },
  },
  {
    field: 'data.endDate',
    headerName: 'End Date',
    flex: 0,
    minWidth: 150,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      if (!params.value || Number.isNaN(new Date(params.value).getTime())) {
        return '---';
      }
      return new Date(params.value).toLocaleString();
    },
  },
];
