import { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Blockquote from '@tiptap/extension-blockquote';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Code from '@tiptap/extension-code';
import CodeBlock from '@tiptap/extension-code-block';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import History from '@tiptap/extension-history';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import type { Content, Editor } from '@tiptap/react';
import { Copy } from 'lucide-react';
import FormInput from '@/components/form-components/form-input';
import { TiptapEditor } from '@/components/tiptap';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { Switch } from '@/components/ui/switch';
import { Textarea } from '@/components/ui/textarea';
import type { EmailTemplate } from '@/hooks/use-email-templates';
import { useEmailTemplateVariables } from '@/hooks/use-email-templates';

// Custom document extension that doesn't force paragraph wrapping
const CustomDocument = Document.extend({
  content: 'block+',
});

// Custom paragraph extension that allows inline content directly
const CustomParagraph = Paragraph.extend({
  defining: false,
});

const extensions = [
  CustomDocument,
  CustomParagraph.configure({
    HTMLAttributes: {
      class: 'email-paragraph',
    },
  }),
  Text,
  Heading.configure({
    levels: [1, 2, 3, 4],
  }),
  HorizontalRule,
  Bold,
  Italic,
  Strike,
  Code,
  CodeBlock,
  BulletList,
  OrderedList,
  ListItem,
  Blockquote,
  HardBreak,
  History,
  Underline,
  Link.configure({
    openOnClick: false,
  }),
];

const emptyContent: Content = {
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [{ type: 'text', text: '' }],
    },
  ],
};

type FormData = {
  name: string;
  short_description: string;
  long_description: string;
  tld: string;
  template: { body: string };
  to?: string;
  cc?: string;
  subject: string;
};

type TemplateSheetProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  initialData?: EmailTemplate;
  onSubmit: (data: FormData) => Promise<void>;
};

function TemplateSheet({ open, onOpenChange, initialData, onSubmit }: TemplateSheetProps) {
  const variables = useEmailTemplateVariables();
  const form = useForm<FormData>({
    defaultValues: {
      name: '',
      short_description: '',
      long_description: '',
      tld: '',
      to: '',
      cc: '',
      subject: '',
    },
  });

  const [editor, setEditor] = useState<Editor | null>(null);
  const [content, setContent] = useState<Content>(emptyContent);
  const [isHtmlMode, setIsHtmlMode] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const filteredVariables = variables.data?.variables
    ? Object.entries(variables.data.variables).filter(([key, value]) =>
        key.toLowerCase().includes(searchQuery.toLowerCase())
        || value.description.toLowerCase().includes(searchQuery.toLowerCase())
        || value.example.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : [];

  // Sync HTML content when editor content changes
  useEffect(() => {
    if (editor && !isHtmlMode) {
      setHtmlContent(editor.getHTML());
    }
  }, [editor, content, isHtmlMode]);

  // Update editor content when HTML content changes
  const handleHtmlChange = useCallback((html: string) => {
    setHtmlContent(html);
    if (editor) {
      // Preserve raw HTML formatting
      editor.commands.setContent(html, false);
      setContent(editor.getJSON());
    }
  }, [editor]);

  // Clean HTML output and handle line breaks properly
  const getCleanHtml = useCallback(() => {
    if (!editor)
      return '';

    let html = editor.getHTML();

    // First handle empty paragraphs (they should become line breaks)
    html = html.replaceAll(/<p class="email-paragraph">\s*<\/p>/g, '<br>');

    // Then handle paragraphs with content
    html = html.replaceAll(/<p class="email-paragraph">([\s\S]+?)<\/p>/g, (_, content) => {
      const trimmed = content.trim();
      return trimmed ? `${trimmed}<br><br>` : '<br>';
    });

    // Clean up any excessive breaks at the end
    html = html.replace(/<br>(?:<br>)*$/, '');

    return html.trim();
  }, [editor]);

  // Update HTML content with clean format
  useEffect(() => {
    if (editor && !isHtmlMode) {
      setHtmlContent(getCleanHtml());
    }
  }, [editor, content, isHtmlMode, getCleanHtml]);

  // Update form values and editor content when initialData changes
  useEffect(() => {
    if (initialData) {
      form.reset({
        name: initialData.name,
        short_description: initialData.short_description,
        long_description: initialData.long_description,
        tld: initialData.tld,
        to: initialData.to || '',
        cc: initialData.cc || '',
        subject: initialData.subject,
      });

      // Set the raw HTML content
      setHtmlContent(initialData.template.body);
      if (editor) {
        editor.commands.setContent(initialData.template.body, false);
        setContent(editor.getJSON());
      }
    } else {
      form.reset({
        name: '',
        short_description: '',
        long_description: '',
        tld: '',
        to: '',
        cc: '',
        subject: '',
      });
      setHtmlContent('');
      setContent(emptyContent);
      if (editor) {
        editor.commands.setContent('');
      }
    }
  }, [initialData, form.reset, editor, form]);

  const handleFormSubmit = async (formData: FormData) => {
    // Store the clean HTML in the template
    const cleanContent = isHtmlMode ? htmlContent : getCleanHtml();
    const templateData = {
      ...formData,
      template: {
        body: cleanContent,
      },
    };

    await onSubmit(templateData);
    form.reset();
    setContent(emptyContent);
    if (editor) {
      editor.commands.setContent(emptyContent);
    }
    onOpenChange(false);
  };

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side="right"
        className="w-full max-w-full sm:max-w-[1200px]"
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <SheetHeader>
          <SheetTitle>{initialData ? 'Edit Template' : 'Create Template'}</SheetTitle>
        </SheetHeader>
        <div className="h-[calc(100vh-8rem)] pt-8">
          <ResizablePanelGroup direction="horizontal" className="h-full">
            <ResizablePanel defaultSize={25} minSize={20} maxSize={40}>
              <div className="h-full space-y-4 px-4">
                <div className="sticky top-0 z-10 bg-background pb-4">
                  <h3 className="mb-2 text-sm font-medium">Template Variables</h3>
                  <Input
                    type="search"
                    placeholder="Search variables..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full"
                  />
                </div>
                <div className="h-[calc(100%-5rem)] space-y-3 overflow-y-auto">
                  {filteredVariables.length > 0
                    ? (
                        filteredVariables.map(([key, value]) => (
                          <div key={key} className="space-y-1">
                            <div className="flex items-center justify-between gap-2">
                              <code className="rounded bg-muted px-1.5 py-0.5 text-sm">{`{{${key}}}`}</code>
                              <Button
                                variant="ghost"
                                size="icon"
                                className="size-6"
                                type="button"
                                onClick={async (e) => {
                                  e.preventDefault();
                                  try {
                                    await navigator.clipboard.writeText(`{{${key}}}`);
                                  } catch (error) {
                                    console.error('Failed to copy to clipboard:', error);
                                  }
                                }}
                              >
                                <Copy className="size-4" />
                                <span className="sr-only">Copy variable</span>
                              </Button>
                            </div>
                            <p className="text-xs text-muted-foreground">{value.description}</p>
                            <p className="text-xs italic text-muted-foreground">
                              Example:
                              {' '}
                              {value.example}
                            </p>
                          </div>
                        ))
                      )
                    : (
                        <p className="text-sm text-muted-foreground">
                          {variables.data?.variables
                            ? 'No variables found'
                            : 'Loading variables...'}
                        </p>
                      )}
                </div>
              </div>
            </ResizablePanel>
            <ResizableHandle withHandle />
            <ResizablePanel defaultSize={75}>
              <form onSubmit={form.handleSubmit(handleFormSubmit)} className="h-full space-y-6 overflow-y-auto px-6">
                <FormInput
                  control={form.control}
                  name="name"
                  label="Name"
                  required
                  rules={{ required: 'Name is required' }}
                  placeholder="e.g., welcome_email"
                />

                <FormInput
                  control={form.control}
                  name="to"
                  label="To"
                  placeholder="e.g., support@example.com"
                />

                <FormInput
                  control={form.control}
                  name="cc"
                  label="Cc"
                  placeholder="e.g., support@example.com"
                />

                <FormInput
                  control={form.control}
                  name="subject"
                  label="Subject"
                  required
                  rules={{ required: 'Subject is required' }}
                  placeholder="e.g., Welcome to our platform"
                />

                <div className="space-y-2">
                  <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                    Short Description
                  </label>
                  <Textarea
                    {...form.register('short_description', { required: 'Short description is required' })}
                    className="min-h-[60px] resize-y"
                    placeholder="Brief description of the template"
                  />
                  {form.formState.errors.short_description && (
                    <p className="text-sm text-destructive">{form.formState.errors.short_description.message}</p>
                  )}
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                    Long Description
                  </label>
                  <Textarea
                    {...form.register('long_description')}
                    className="min-h-[100px] resize-y"
                    placeholder="Detailed description of the template and its usage"
                  />
                </div>

                <FormInput
                  control={form.control}
                  name="tld"
                  label="TLD"
                  required
                  rules={{ required: 'TLD is required' }}
                  placeholder="BANK/INSURANCE"
                />

                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <label className="text-sm font-medium leading-none">Template Body</label>
                    <div className="flex items-center space-x-2">
                      <label className="text-sm text-muted-foreground">Raw HTML</label>
                      <Switch
                        checked={isHtmlMode}
                        onCheckedChange={setIsHtmlMode}
                      />
                    </div>
                  </div>
                </div>
                <div className="min-h-[300px] rounded-md border">
                  {isHtmlMode
                    ? (
                        <Textarea
                          value={htmlContent}
                          onChange={(e) => handleHtmlChange(e.target.value)}
                          className="min-h-[300px] font-mono text-sm"
                          spellCheck={false}
                        />
                      )
                    : (
                        <TiptapEditor
                          value={content}
                          onChange={setContent}
                          onCreate={({ editor }) => {
                            setEditor(editor);
                            if (initialData) {
                              editor.commands.setContent(initialData.template.body, false);
                            }
                          }}
                          extensions={extensions}
                          className="min-h-[300px]"
                          editorContentClassName="!h-full flex flex-col [&_.ProseMirror]:flex-1 [&_.ProseMirror]:overflow-y-auto [&_.ProseMirror]:pb-36 [&_.ProseMirror]:px-8 [&_.ProseMirror]:pt-4"
                          editorProps={{
                            attributes: {
                              class: 'h-full prose prose-sm prose-stone dark:prose-invert',
                            },
                          }}
                          hideSections={['tasks']}
                        />
                      )}
                </div>

                <div className="pt-4">
                  <Button type="submit" className="w-full" disabled={form.formState.isSubmitting}>
                    {initialData ? 'Update Template' : 'Create Template'}
                  </Button>
                </div>
              </form>
            </ResizablePanel>
          </ResizablePanelGroup>
        </div>
      </SheetContent>
    </Sheet>
  );
}

export default TemplateSheet;
