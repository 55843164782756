import { useState } from 'react';
import { format } from 'date-fns';
import { AlertCircle, X, ChevronDown, ExternalLink, RotateCcw, PenTool } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible';
import { Input } from '@/components/ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import type { FdicCheck, VerificationItem } from '../types';

interface FdicCheckProps {
  item: FdicCheck;
  onVerify: (id: string) => void;
  onSign: (id: string, role: 'creator' | 'reviewer') => void;
  isSearching: boolean;
  items: VerificationItem[];
  setItems: (items: VerificationItem[]) => void;
  userId: string | undefined;
}

interface LocationTableProps {
  locations: Array<{
    ZIP: string;
    ADDRESS: string;
    ADDRESS2: string;
    CITY: string;
    STNAME: string;
    COUNTY: string;
    SERVTYPE_DESC: string;
    OFFNAME: string;
    MAINOFF: number;
  }>;
}

function LocationTable({ locations }: LocationTableProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const filteredLocations = locations.filter((location) =>
    Object.values(location).some(
      (value) =>
        value
        && value.toString().toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  );

  const totalPages = Math.ceil(filteredLocations.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedLocations = filteredLocations.slice(
    startIndex,
    startIndex + itemsPerPage,
  );

  return (
    <div className="space-y-4">
      <Input
        placeholder="Search locations..."
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setCurrentPage(1);
        }}
        className="max-w-sm"
      />
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Office Name</TableHead>
              <TableHead>Address</TableHead>
              <TableHead>Service Type</TableHead>
              <TableHead>Type</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedLocations.map((location, index) => (
              <TableRow key={index}>
                <TableCell>{location.OFFNAME}</TableCell>
                <TableCell>
                  {[
                    location.ADDRESS,
                    location.ADDRESS2,
                    location.CITY,
                    location.STNAME,
                    location.ZIP,
                  ]
                    .filter(Boolean)
                    .join(', ')}
                  <div className="text-sm text-muted-foreground">
                    County:
                    {' '}
                    {location.COUNTY}
                  </div>
                </TableCell>
                <TableCell>{location.SERVTYPE_DESC}</TableCell>
                <TableCell>
                  {location.MAINOFF === 1 ? 'Main Office' : 'Branch'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {totalPages > 1 && (
        <div className="flex items-center justify-end space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => setCurrentPage((p) => Math.max(1, p - 1))}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <div className="text-sm">
            Page
            {' '}
            {currentPage}
            {' '}
            of
            {' '}
            {totalPages}
          </div>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setCurrentPage((p) => Math.min(totalPages, p + 1))}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
}

export function FdicCheckComponent({
  item,
  onVerify,
  isSearching,
  items,
  setItems,
  onSign,
  userId,
}: FdicCheckProps) {
  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        <div className="flex-1 space-y-4">
          <Input
            value={item.entityName}
            onChange={(e) =>
              setItems(
                items.map((i) =>
                  i.id === item.id
                    ? { ...i, entityName: e.target.value }
                    : i,
                ),
              )}
            placeholder="Enter entity name"
          />
          <Input
            value={item.certNumber}
            onChange={(e) =>
              setItems(
                items.map((i) =>
                  i.id === item.id
                    ? { ...i, certNumber: e.target.value }
                    : i,
                ),
              )}
            placeholder="Enter FDIC certificate number"
          />
        </div>
        <Button
          variant={item.verified ? 'outline' : 'default'}
          className="gap-2"
          onClick={() => onVerify(item.id)}
          disabled={!item.certNumber || isSearching}
        >
          {item.verified
            ? (
                <>
                  <RotateCcw className="size-4" />
                  Re-verify
                </>
              )
            : (
                <>
                  <AlertCircle className="size-4" />
                  Verify
                </>
              )}
        </Button>
      </div>
      {item.searchResults && (
        <div className="space-y-4">
          {typeof item.searchResults.results === 'string'
            ? (
                <div className="rounded-lg border bg-muted p-4 text-sm">
                  {item.searchResults.results}
                </div>
              )
            : (
                <div className={`space-y-3 rounded-lg p-4 ${item.searchResults.results.operational.active ? 'border' : 'border-2 border-red-500 bg-red-50'}`}>
                  {/* Basic Info */}
                  <div className="space-y-1">
                    <div className="font-medium">
                      {item.searchResults.results.basic_info.name}
                    </div>
                    {item.searchResults.results.basic_info.prior_names?.length > 0 && (
                      <div className="text-sm text-muted-foreground">
                        Previously known as:
                        {' '}
                        {item.searchResults.results.basic_info.prior_names.join(', ')}
                      </div>
                    )}
                    <div className="space-y-1 text-sm">
                      <div>
                        Website:
                        {' '}
                        {item.searchResults.results.basic_info.website}
                      </div>
                      <a
                        href={`https://banks.data.fdic.gov/bankfind-suite/bankfind/details/${item.searchResults.results.basic_info.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        View on FDIC Website
                        <ExternalLink className="ml-1 inline-block size-3" />
                      </a>
                    </div>
                    <div className="text-sm">
                      Established:
                      {' '}
                      {format(new Date(item.searchResults.results.basic_info.established_date), 'MMM d, yyyy')}
                    </div>
                  </div>

                  {/* Main Location */}
                  <div className="space-y-1">
                    <div className="text-sm font-medium">Main Location:</div>
                    <div className="text-sm">
                      {[
                        item.searchResults.results.location.address,
                        item.searchResults.results.location.address2,
                        item.searchResults.results.location.city,
                        item.searchResults.results.location.state,
                        item.searchResults.results.location.zip,
                      ]
                        .filter(Boolean)
                        .join(', ')}
                    </div>
                    <div className="text-sm text-muted-foreground">
                      County:
                      {' '}
                      {item.searchResults.results.location.county}
                    </div>
                  </div>

                  {/* All Locations */}
                  <Collapsible>
                    <CollapsibleTrigger asChild>
                      <Button variant="outline" className="w-full justify-between">
                        <span>
                          All Locations (
                          {item.searchResults.results.locations?.data?.length ?? 0}
                          )
                        </span>
                        <ChevronDown className="size-4" />
                      </Button>
                    </CollapsibleTrigger>
                    <CollapsibleContent className="pt-4">
                      {item.searchResults.results.locations?.data && (
                        <LocationTable locations={item.searchResults.results.locations.data} />
                      )}
                    </CollapsibleContent>
                  </Collapsible>

                  {/* Operational Status */}
                  <div className="space-y-1">
                    <div className="text-sm font-medium">Status:</div>
                    <div className="text-sm">
                      {item.searchResults.results.operational.active ? 'Active' : 'Inactive'}
                    </div>
                  </div>

                  {/* Additional Info */}
                  {item.searchResults.results.additional_info?.length > 0 && (
                    <div className="space-y-1">
                      <div className="text-sm font-medium">Additional Information:</div>
                      <ul className="list-inside list-disc text-sm text-muted-foreground">
                        {item.searchResults.results.additional_info.map((info: string, index: number) => (
                          <li key={index}>{info}</li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {/* Last Updated */}
                  <div className="space-y-1 border-t pt-2">
                    <div className="text-sm text-muted-foreground">
                      Last updated:
                      {' '}
                      {format(new Date(item.searchResults.results.basic_info.last_updated), 'MMM d, yyyy')}
                    </div>
                  </div>
                </div>
              )}
        </div>
      )}
      {item.verified && (
        <div className="flex flex-wrap items-center gap-3">
          <Button
            variant="outline"
            className="flex-1 gap-2"
            onClick={() => onSign(item.id, 'creator')}
          >
            {item.signatures.some(
              (s) => s.userId === userId && s.role === 'creator',
            )
              ? (
                  <>
                    <X className="size-4" />
                    Remove Creator Signature
                  </>
                )
              : (
                  <>
                    <PenTool className="size-4" />
                    Sign as Creator
                  </>
                )}
          </Button>
          <Button
            variant="outline"
            className="flex-1 gap-2"
            onClick={() => onSign(item.id, 'reviewer')}
          >
            {item.signatures.some(
              (s) => s.userId === userId && s.role === 'reviewer',
            )
              ? (
                  <>
                    <X className="size-4" />
                    Remove Reviewer Signature
                  </>
                )
              : (
                  <>
                    <PenTool className="size-4" />
                    Sign as Reviewer
                  </>
                )}
          </Button>
        </div>
      )}
      <div>
        {item.verifiedBy && (
          <div className="text-sm text-muted-foreground">
            Verified by
            {' '}
            {item.verifiedBy.name}
            {' '}
            on
            {' '}
            {format(new Date(item.verifiedBy.timestamp), 'MMM d, yyyy h:mm a')}
          </div>
        )}
        {item.signatures.length > 0 && (
          <div className="space-y-2">
            <div className="text-sm font-medium">Signatures:</div>
            {item.signatures.map((signature, index) => (
              <div
                key={index}
                className="text-sm text-muted-foreground"
              >
                {signature.name}
                {' '}
                (
                {signature.role}
                ) -
                {' '}
                {format(new Date(signature.timestamp), 'MMM d, yyyy h:mm a')}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
