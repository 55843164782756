import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import type { RawTransaction } from '@/hooks/use-raw-transactions';

type TransactionSheetProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  transaction?: RawTransaction;
};

function TransactionSheet({ open, onOpenChange, transaction }: TransactionSheetProps) {
  if (!transaction) {
    return null;
  }

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side="right"
        className="w-full max-w-full sm:max-w-lg"
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <SheetHeader>
          <SheetTitle>Transaction Details</SheetTitle>
        </SheetHeader>
        <div className="h-[calc(100vh-8rem)] space-y-6 overflow-y-auto px-1 pt-8">
          <div className="space-y-3">
            <h3 className="text-lg font-medium">Domain Information</h3>
            <div className="grid grid-cols-2 gap-x-4 gap-y-2 rounded-md border p-4">
              <div className="text-sm font-medium">Domain Name:</div>
              <div className="text-sm">{transaction.domain}</div>
              <div className="text-sm font-medium">Domain ROID:</div>
              <div className="text-sm">{transaction.data.domainRoid}</div>
            </div>
          </div>

          <div className="space-y-3">
            <h3 className="text-lg font-medium">Transaction Details</h3>
            <div className="grid grid-cols-2 gap-x-4 gap-y-2 rounded-md border p-4">
              <div className="text-sm font-medium">Transaction ID:</div>
              <div className="text-sm">{transaction.data.transactionId}</div>
              <div className="text-sm font-medium">Server Transaction ID:</div>
              <div className="text-sm">{transaction.data.serverTransactionId}</div>
              <div className="text-sm font-medium">Transaction Date:</div>
              <div className="text-sm">{new Date(transaction.data.transactionDate).toLocaleString()}</div>
              <div className="text-sm font-medium">Operation:</div>
              <div className="text-sm">{transaction.data.operation}</div>
              <div className="text-sm font-medium">Category:</div>
              <div className="text-sm">{transaction.data.category}</div>
              <div className="text-sm font-medium">Cost:</div>
              <div className="text-sm">
                $
                {transaction.data.cost.toFixed(2)}
              </div>
            </div>
          </div>

          <div className="space-y-3">
            <h3 className="text-lg font-medium">Registration Details</h3>
            <div className="grid grid-cols-2 gap-x-4 gap-y-2 rounded-md border p-4">
              <div className="text-sm font-medium">Registrar:</div>
              <div className="text-sm">{transaction.data.registrar}</div>
              <div className="text-sm font-medium">IANA ID:</div>
              <div className="text-sm">{transaction.data.ianaId}</div>
              <div className="text-sm font-medium">Period (Years):</div>
              <div className="text-sm">{transaction.data.period}</div>
              <div className="text-sm font-medium">Start Date:</div>
              <div className="text-sm">{new Date(transaction.data.startDate).toLocaleString()}</div>
              <div className="text-sm font-medium">End Date:</div>
              <div className="text-sm">{new Date(transaction.data.endDate).toLocaleString()}</div>
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
}

export default TransactionSheet;
