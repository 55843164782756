import { useEffect, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader2 } from 'lucide-react';
import FormInput from '@/components/form-components/form-input';
import FormSwitch from '@/components/form-components/form-switch';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import type { Contact } from '@/types/contact';
import { createContactSchema, type ContactFormData } from '@/types/contact-schema';
import { DomainAssociations } from './domain-associations';

type ContactSheetProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  initialData?: Contact;
  onSubmit: (data: ContactFormData) => Promise<void>;
  availableDomains?: string[];
};

export default function ContactSheet({ open, onOpenChange, initialData, onSubmit }: ContactSheetProps) {
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<ContactFormData>({
    resolver: zodResolver(createContactSchema),
    defaultValues: {
      legal_name: '',
      email: '',
      phone_number: '',
      alt_phone: null,
      fax_number: null,
      address_line_1: '',
      address_line_2: null,
      address_line_3: null,
      city: '',
      state_province: '',
      postal_code: '',
      country: '',
      alt_name: null,
      alt_email: null,
      title: null,
      role_name: null,
      organization_legal_name: '',
      c_suite: false,
      sole_prop: false,
      new_legal_name: false,
      domain_associations: [],
    },
  });

  // Reset form and error state when sheet is closed
  useEffect(() => {
    if (!open) {
      form.reset();
      setSubmissionError(null);
    }
  }, [open, form]);

  // Update form values when initialData changes or when opening the sheet
  useEffect(() => {
    if (open) {
      if (initialData) {
        // Reset with initialData when editing an existing contact
        const formValues = {
          legal_name: initialData.legal_name,
          email: initialData.email,
          phone_number: initialData.phone_number,
          alt_phone: initialData.alt_phone,
          fax_number: initialData.fax_number,
          address_line_1: initialData.address_line_1,
          address_line_2: initialData.address_line_2,
          address_line_3: initialData.address_line_3,
          city: initialData.city,
          state_province: initialData.state_province,
          postal_code: initialData.postal_code,
          country: initialData.country,
          alt_name: initialData.alt_name,
          alt_email: initialData.alt_email,
          title: initialData.title,
          role_name: initialData.role_name,
          organization_legal_name: initialData.organization_legal_name,
          c_suite: initialData.c_suite,
          sole_prop: initialData.sole_prop,
          new_legal_name: initialData.new_legal_name,
          domain_associations: initialData.domain_contact_junctions?.map((j) => ({
            domain: j.domain,
            type: j.type,
          })) || [],
        };
        form.reset(formValues);
      } else {
        // Reset to empty form when creating a new contact
        form.reset({
          legal_name: '',
          email: '',
          phone_number: '',
          alt_phone: null,
          fax_number: null,
          address_line_1: '',
          address_line_2: null,
          address_line_3: null,
          city: '',
          state_province: '',
          postal_code: '',
          country: '',
          alt_name: null,
          alt_email: null,
          title: null,
          role_name: null,
          organization_legal_name: '',
          c_suite: false,
          sole_prop: false,
          new_legal_name: false,
          domain_associations: [],
        });
      }
    }
  }, [initialData, form, open]);

  const handleFormSubmit = useCallback(async (data: ContactFormData) => {
    setIsSubmitting(true);
    setSubmissionError(null);
    try {
      await onSubmit(data);
      onOpenChange(false);
    } catch (error) {
      // Display error message to the user
      setSubmissionError(
        error instanceof Error ? error.message : 'An unexpected error occurred',
      );
      console.error('Form submission error:', error);
    } finally {
      setIsSubmitting(false);
    }
  }, [onSubmit, onOpenChange]);

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side="right"
        className="w-full max-w-full sm:max-w-lg"
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <SheetHeader>
          <SheetTitle>{initialData ? 'Edit Contact' : 'New Contact'}</SheetTitle>
        </SheetHeader>
        <form onSubmit={form.handleSubmit(handleFormSubmit)} className="h-[calc(100vh-8rem)] space-y-6 overflow-y-auto px-1 pt-8">
          {submissionError && (
            <div className="rounded-md bg-destructive/15 p-3 text-sm text-destructive">
              {submissionError}
            </div>
          )}

          <FormInput
            control={form.control}
            name="legal_name"
            label="RDDS Name"
            required
            helperText="Legal name as it appears in RDDS"
          />

          <FormInput
            control={form.control}
            name="email"
            label="Email"
            required
            type="email"
            helperText="Primary contact email address"
          />

          <FormInput
            control={form.control}
            name="phone_number"
            label="Company Phone"
            required
            type="tel"
            helperText="Main business phone number"
          />

          <FormInput
            control={form.control}
            name="alt_phone"
            label="Direct Phone"
            type="tel"
            helperText="Alternative or direct contact number"
          />

          <FormInput
            control={form.control}
            name="fax_number"
            label="Fax Number"
            type="tel"
          />

          <FormInput
            control={form.control}
            name="address_line_1"
            label="Address Line 1"
            required
            helperText="Street address or P.O. Box"
          />

          <FormInput
            control={form.control}
            name="address_line_2"
            label="Address Line 2"
          />

          <FormInput
            control={form.control}
            name="address_line_3"
            label="Address Line 3"
          />

          <div className="grid grid-cols-2 gap-4">
            <FormInput
              control={form.control}
              name="city"
              label="City"
              required
            />

            <FormInput
              control={form.control}
              name="state_province"
              label="State/Province"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <FormInput
              control={form.control}
              name="postal_code"
              label="Postal Code"
            />

            <FormInput
              control={form.control}
              name="country"
              label="Country"
              required
            />
          </div>

          <FormInput
            control={form.control}
            name="alt_name"
            label="Alternate Name"
            helperText="Alternative contact name if different from RDDS name"
          />

          <FormInput
            control={form.control}
            name="alt_email"
            label="Alternate Email"
            type="email"
          />

          <FormInput
            control={form.control}
            name="title"
            label="Title"
            helperText="Job title or position"
          />

          <FormInput
            control={form.control}
            name="role_name"
            label="Role"
            helperText="Role within the organization"
          />

          <FormInput
            control={form.control}
            name="organization_legal_name"
            label="Organization Legal Name"
            helperText="Full legal name of the organization"
          />

          <div className="space-y-4">
            <FormSwitch
              control={form.control}
              name="c_suite"
              label="C-Suite"
              helperText="Contact has C-level executive position"
            />

            <FormSwitch
              control={form.control}
              name="sole_prop"
              label="Sole Proprietorship"
              helperText="Business is a sole proprietorship"
            />

            <FormSwitch
              control={form.control}
              name="new_legal_name"
              label="New Legal Name"
              helperText="Contact has recently changed legal name"
            />
          </div>

          <DomainAssociations control={form.control} name="domain_associations" />

          <div className="pt-4">
            <Button
              type="submit"
              className="w-full"
              disabled={isSubmitting}
            >
              {isSubmitting
                ? (
                  <>
                    <Loader2 className="mr-2 size-4 animate-spin" />
                    {initialData ? 'Updating...' : 'Creating...'}
                  </>
                )
                : (
                  initialData ? 'Update Contact' : 'Create Contact'
                )}
            </Button>
          </div>
        </form>
      </SheetContent>
    </Sheet>
  );
}
