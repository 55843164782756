import type { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { format } from 'date-fns';
import { Badge } from '@/components/ui/badge';
import type { Domain, MonitorData } from '@/types/domain';

function StatusRenderer(props: { value: string }) {
  if (!props.value)
    return null;

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'live': {
        return 'bg-green-100 text-green-800';
      }
      case 'redirect_out_of_zone': {
        return 'bg-yellow-100 text-yellow-800';
      }
      default: {
        return 'bg-gray-100 text-gray-800';
      }
    }
  };

  return (
    <Badge className={getStatusColor(props.value)}>
      {props.value}
    </Badge>
  );
}

function UseRestrictionRenderer(props: { value: string | null; data: Domain }) {
  if (!props.value)
    return null;

  return (
    <div className="flex flex-col">
      <span>{props.value}</span>
      {props.data.use_restriction_note && (
        <span className="text-xs text-muted-foreground">{props.data.use_restriction_note}</span>
      )}
    </div>
  );
}

export const domainColumnDefs: ColDef<Domain>[] = [
  {
    field: 'name',
    headerName: 'Domain Name',
    flex: 0,
    minWidth: 120,
    sortable: true,
    filter: true,
  },
  {
    headerName: 'Status',
    flex: 0,
    minWidth: 80,
    sortable: true,
    filter: true,
    valueGetter: (params) => params.data?.monitor_data?.at(-1)?.status,
    cellRenderer: StatusRenderer,
  },
  {
    field: 'most_recent_transaction',
    headerName: 'Most Recent Transaction',
    flex: 0,
    minWidth: 150,
    sortable: true,
    filter: true,
  },
  {
    field: 'organization_legal_name',
    headerName: 'Organization',
    flex: 0,
    minWidth: 200,
    sortable: true,
    filter: true,
  },
  {
    field: 'licenses',
    headerName: 'Licenses',
    flex: 0,
    minWidth: 150,
    sortable: true,
    filter: true,
  },
  {
    field: 'most_recent_registrar',
    headerName: 'Registrar',
    flex: 0,
    minWidth: 200,
    sortable: true,
    filter: true,
  },
  {
    field: 'use_restriction',
    headerName: 'Use Restriction',
    flex: 0,
    minWidth: 120,
    sortable: true,
    filter: true,
    cellRenderer: UseRestrictionRenderer,
  },
  {
    field: 'last_verified',
    headerName: 'Last Verified',
    flex: 0,
    minWidth: 100,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return params.value ? format(new Date(params.value), 'MMM d, yyyy') : '';
    },
  },
];
