import { Link, useLocation } from 'react-router-dom';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from '@/components/ui/sidebar';
import { cn } from '@/lib/utils';
import type { LucideIcon } from 'lucide-react';

export function NavMain({
  items,
}: {
  items: {
    title: string;
    url: string;
    icon: LucideIcon;
    isActive?: boolean;
    items?: {
      title: string;
      url: string;
      isActive?: boolean;
    }[];
  }[];
}) {
  const location = useLocation();

  return (
    <SidebarGroup>
      <SidebarMenu>
        {items.map((item) => {
          const hasSubItems = item.items && item.items.length > 0;
          const isSubItemActive = hasSubItems && item.items?.some((subItem) => location.pathname === subItem.url);
          const isActive = location.pathname === item.url || isSubItemActive;

          return (
            <SidebarMenuItem key={item.title}>
              {hasSubItems
                ? (
                    <>
                      <SidebarMenuButton
                        className={cn(
                          'font-medium text-white transition duration-300 ease-in-out hover:bg-ftld-gray-light hover:text-ftld-secondary active:bg-ftld-gray-light active:text-ftld-secondary',
                          isActive && 'bg-ftld-gray-light text-ftld-secondary',
                        )}
                        tooltip={item.title}
                        asChild
                      >
                        <Link to={item.url}>
                          <item.icon className={cn(isActive && 'text-ftld-secondary')} />
                          <span>{item.title}</span>
                        </Link>
                      </SidebarMenuButton>
                      <SidebarMenuSub>
                        {item.items?.map((subItem) => {
                          const isSubItemActive = location.pathname === subItem.url;
                          return (
                            <SidebarMenuSubItem key={subItem.title}>
                              <SidebarMenuSubButton
                                asChild
                                isActive={isSubItemActive}
                                className="text-white transition duration-300 ease-in-out hover:bg-ftld-gray-light hover:text-ftld-secondary active:bg-ftld-gray-light active:text-ftld-secondary"
                              >
                                <Link to={subItem.url}>{subItem.title}</Link>
                              </SidebarMenuSubButton>
                            </SidebarMenuSubItem>
                          );
                        })}
                      </SidebarMenuSub>
                    </>
                  )
                : (
                    <SidebarMenuButton
                      className={cn(
                        'font-medium text-white transition duration-300 ease-in-out hover:bg-ftld-gray-light hover:text-ftld-secondary active:bg-ftld-gray-light active:text-ftld-secondary',
                        isActive && 'bg-ftld-gray-light text-ftld-secondary',
                      )}
                      asChild
                      tooltip={item.title}
                    >
                      <Link to={item.url}>
                        <item.icon className={cn(isActive && 'text-ftld-secondary')} />
                        <span>{item.title}</span>
                      </Link>
                    </SidebarMenuButton>
                  )}
            </SidebarMenuItem>
          );
        })}
      </SidebarMenu>
    </SidebarGroup>
  );
}
